// src/components/Home.jsx
import React from "react";
import { 
  FaMusic, 
  FaBible, 
  FaVideo, 
  FaUsers, 
  FaQuestionCircle, 
  FaComments, 
  FaPray, 
  FaUserCircle, 
  FaHandsHelping,
  FaPodcast,
  FaBlog,
  FaStore,
  FaBroadcastTower,
  FaCalendarAlt,
  FaBookReader,
  FaChalkboardTeacher,
  FaBook,
  FaMedal,
  FaHome, 
  FaTrophy, 
  FaFacebook, 
  FaHeadset, 
  FaUserTie, 
  FaPrayingHands, 
  FaUser, 
} from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Home.css"; // Import specific styles
import backgroundImage from "./assets/banner.jpg"; // Update with actual image path
import SEO from './components/SEO';


function Home() {
  return (


    
    <div className="home-container">
      
    <SEO
    title="Global Christian Community - Home"
    description="Global Christian Community offers Bible study tools, worship resources, podcasts, and more to empower believers worldwide."
    keywords="Bible, Christian, worship, Bible study, Global Christian Community"
    url="https://globalchristiancommunity.com/"
    image="https://globalchristiancommunity.com/images/og-home.jpg"
  />

  {/* Welcome Section */}
  <section className="welcome-section" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <div className="welcome-content">
          <h1>Welcome to Christian Community</h1>
          <p>"For where two or three gather in my name, there am I with them." - Matthew 18:20</p>
          <Link to="https://www.youtube.com/@gcc-worship?sub_confirmation=1" className="cta-button">
            Join Us Today
          </Link>
        </div>
      </section>
 
      {/* Circular Menu Grid */}
      <div className="menu-grid">
        {/* Existing Menu Items */}
        {/* <Link to="/worship" className="menu-item" aria-label="Worship Page">
          <div className="icon-wrapper">
            <FaMusic className="menu-icon" />
          </div>
          <span className="menu-text">Worship</span>
        </Link> */}
        <Link to="/bible" className="menu-item" aria-label="Bible Reader Page">
          <div className="icon-wrapper">
            <FaBible className="menu-icon" />
          </div>
          <span className="menu-text">Bible</span>
        </Link>
        <Link to="/media" className="menu-item" aria-label="Media Page">
          <div className="icon-wrapper">
            <FaVideo className="menu-icon" />
          </div>
          <span className="menu-text">Songs</span>
        </Link>


        <Link to="/karaoke" className="menu-item" aria-label="Karaoke Page">
          <div className="icon-wrapper">
            <FaMusic className="menu-icon" />
          </div>
          <span className="menu-text">Karaoke</span>
        </Link>

        <Link to="/courses" className="menu-item" aria-label="Online Courses Page">
          <div className="icon-wrapper">
            <FaChalkboardTeacher className="menu-icon" />
          </div>
          <span className="menu-text">Messages</span>
        </Link>
        

        <Link to="/biblestudy" className="menu-item" aria-label="Bible Study Tools Page">
          <div className="icon-wrapper">
            <FaBookReader className="menu-icon" />
          </div>
          <span className="menu-text">Bible Study Tools</span>
        </Link>
        


        {/* <Link to="/events" className="menu-item" aria-label="Events Page">
          <div className="icon-wrapper">
            <FaCalendarAlt className="menu-icon" />
          </div>
          <span className="menu-text">Events</span>
        </Link> */}


        {/* <Link to="/help" className="menu-item" aria-label="Help Page">
          <div className="icon-wrapper">
            <FaQuestionCircle className="menu-icon" />
          </div>
          <span className="menu-text">Help</span>
        </Link> 

        <Link to="/resources" className="menu-item" aria-label="Resource Library Page">
          <div className="icon-wrapper">
            <FaBook className="menu-icon" />
          </div>
          <span className="menu-text">Resource Library</span>
        </Link> */}

{/* 

        <Link to="/community" className="menu-item" aria-label="Community Page">
          <div className="icon-wrapper">
            <FaUsers className="menu-icon" />
          </div>
          <span className="menu-text">Community</span>
        </Link>
        <Link to="/help" className="menu-item" aria-label="Help Page">
          <div className="icon-wrapper">
            <FaQuestionCircle className="menu-icon" />
          </div>
          <span className="menu-text">Help</span>
        </Link>
        
         <Link to="/podcasts" className="menu-item" aria-label="Podcasts Page">
          <div className="icon-wrapper">
            <FaPodcast className="menu-icon" />
          </div>
          <span className="menu-text">Podcasts</span>
        </Link>
        <Link to="/blog" className="menu-item" aria-label="Blog Page">
          <div className="icon-wrapper">
            <FaBlog className="menu-icon" />
          </div>
          <span className="menu-text">Blog</span>
        </Link>
        <Link to="/marketplace" className="menu-item" aria-label="Marketplace Page">
          <div className="icon-wrapper">
            <FaStore className="menu-icon" />
          </div>
          <span className="menu-text">Marketplace</span>
        </Link>
        <Link to="/livestream" className="menu-item" aria-label="Live Streaming Page">
          <div className="icon-wrapper">
            <FaBroadcastTower className="menu-icon" />
          </div>
          <span className="menu-text">Live Streaming</span>
        </Link>

        <Link to="/smallgroups" className="menu-item" aria-label="Small Groups Page">
          <div className="icon-wrapper">
            <FaUsers className="menu-icon" />
          </div>
          <span className="menu-text">Small Groups</span>
        </Link>
      
   
        
        <Link to="/gamification" className="menu-item" aria-label="Gamification Page">
          <div className="icon-wrapper">
            <FaMedal className="menu-icon" />
          </div>
          <span className="menu-text">Gamification</span>
        </Link>

        <Link to="/socialmedia" className="menu-item" aria-label="Social Media Page">
          <div className="icon-wrapper">
            <FaFacebook className="menu-icon" />
          </div>
          <span className="menu-text">Social Media</span>
        </Link>

        <Link to="/counseling" className="menu-item" aria-label="Counseling Page">
          <div className="icon-wrapper">
            <FaHeadset className="menu-icon" />
          </div>
          <span className="menu-text">Counseling</span>
        </Link>

        <Link to="/mentorship" className="menu-item" aria-label="Mentorship Page">
          <div className="icon-wrapper">
            <FaUserTie className="menu-icon" />
          </div>
          <span className="menu-text">Mentorship</span>
        </Link>

        <Link to="/volunteer" className="menu-item" aria-label="Volunteer Page">
          <div className="icon-wrapper">
            <FaHandsHelping className="menu-icon" />
          </div>
          <span className="menu-text">Volunteer</span>
        </Link>

        <Link to="/forums" className="menu-item" aria-label="Forums Page">
          <div className="icon-wrapper">
            <FaComments className="menu-icon" />
          </div>
          <span className="menu-text">Forums</span>
        </Link>

        <Link to="/prayerwall" className="menu-item" aria-label="Prayer Wall Page">
          <div className="icon-wrapper">
            <FaPrayingHands className="menu-icon" />
          </div>
          <span className="menu-text">Prayer Wall</span>
        </Link>

        <Link to="/profile" className="menu-item" aria-label="Profile Page">
          <div className="icon-wrapper">
            <FaUser className="menu-icon" />
          </div>
          <span className="menu-text">Profile</span>
        </Link>

        <Link to="/community" className="menu-item" aria-label="Community Page">
          <div className="icon-wrapper">
            <FaUsers className="menu-icon" />
          </div>
          <span className="menu-text">Community</span>
        </Link>

  */}

      </div>

      {/* Features Highlights Section */}
      {/* <section className="features-section">
        <h2>Explore Our Features</h2>
        <div className="features-grid">
         
          <div className="feature-card">
            <FaComments className="feature-icon" />
            <h3>Forums & Discussions</h3>
            <p>Engage in meaningful conversations, share your testimonies, and support one another.</p>
            <Link to="/forums" className="learn-more">
              Learn More
            </Link>
          </div>

        
          <div className="feature-card">
            <FaPray className="feature-icon" />
            <h3>Prayer Wall</h3>
            <p>Submit your prayer requests and pray for others in our supportive community.</p>
            <Link to="/prayerwall" className="learn-more">
              Learn More
            </Link>
          </div>

          
          <div className="feature-card">
            <FaUserCircle className="feature-icon" />
            <h3>Member Profiles</h3>
            <p>Create and customize your personal profile to connect with fellow community members.</p>
            <Link to="/profile" className="learn-more">
              Learn More
            </Link>
          </div>

     
          <div className="feature-card">
            <FaHandsHelping className="feature-icon" />
            <h3>Volunteer Opportunities</h3>
            <p>Get involved by participating in various service and volunteer projects.</p>
            <Link to="/volunteer" className="learn-more">
              Learn More
            </Link>
          </div>
        </div>
      </section> */}

      {/* Testimonials Section (Optional) */}
      {/* <section className="testimonials-section">
        <h2>What Our Members Say</h2>
        <div className="testimonials-grid">
          <div className="testimonial-card">
            <div className="testimonial-icon">
              <FaComments />
            </div>
            <p>"This community has been a beacon of hope and support in my life. I've found friends and spiritual growth here."</p>
            <h4>- Jane</h4>
          </div>
          <div className="testimonial-card">
            <div className="testimonial-icon">
              <FaPray />
            </div>
            <p>"The Prayer Wall has been instrumental in helping me through tough times. Knowing others are praying for me is comforting."</p>
            <h4>- Dan</h4>
          </div>
          <div className="testimonial-card">
            <div className="testimonial-icon">
              <FaUsers />
            </div>
            <p>"Engaging in forums and discussions has deepened my understanding of faith and connected me with like-minded individuals."</p>
            <h4>- Emily</h4>
          </div>
        </div>
      </section> */}

      {/* Inspirational Section */}
      <section className="inspirational-section">
        <h2>Stay Connected and Grow in Faith</h2>
        <p>
          Join our community to deepen your faith, find support, and make lasting connections.
          Together, we can grow spiritually and make a positive impact in the world.
        </p>
        <Link to="https://www.youtube.com/@gcc-worship?sub_confirmation=1" className="cta-button">
          Get Started
        </Link>
      </section>
    </div>
  );
}

export default Home;
