// src/components/Podcasts.jsx
import React, { useState, useEffect } from "react";
import "./Podcasts.css";
import { FaPodcast, FaSearch } from "react-icons/fa";
import SEO from './SEO';


function Podcasts() {
  const [searchTerm, setSearchTerm] = useState("Christian");
  const [podcasts, setPodcasts] = useState([]);
  const [episodes, setEpisodes] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Replace with your actual RSS2JSON API key
  const RSS2JSON_API_KEY = "ocp6dfzr6a3stwjw84cb4195t96br1cnp554zutd";

  // Function to fetch podcasts from iTunes Search API
  const fetchPodcasts = async (term) => {
    setLoading(true);
    setError(null);
    setPodcasts([]);
    setEpisodes({});

    try {
      const response = await fetch(
        `https://itunes.apple.com/search?term=${encodeURIComponent(
          term
        )}&media=podcast&limit=5`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setPodcasts(data.results);

      // Fetch episodes for each podcast
      data.results.forEach(async (podcast) => {
        if (podcast.feedUrl) {
          try {
            const feedResponse = await fetch(
              `https://api.rss2json.com/v1/api.json?rss_url=${encodeURIComponent(
                podcast.feedUrl
              )}&api_key=${RSS2JSON_API_KEY}&count=3`
            );
            if (!feedResponse.ok) {
              throw new Error("Failed to fetch RSS feed");
            }
            const feedData = await feedResponse.json();
            setEpisodes((prev) => ({
              ...prev,
              [podcast.collectionId]: feedData.items,
            }));
          } catch (feedError) {
            console.error(
              `Error fetching episodes for ${podcast.collectionName}:`,
              feedError
            );
            setEpisodes((prev) => ({
              ...prev,
              [podcast.collectionId]: [],
            }));
          }
        }
      });
    } catch (err) {
      setError("Failed to fetch podcasts. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch default podcasts on component mount
  useEffect(() => {
    fetchPodcasts(searchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle form submission for search
  const handleSearch = (e) => {
    e.preventDefault();
    if (searchTerm.trim() === "") return;
    fetchPodcasts(searchTerm);
  };

  return (
    <div className="podcasts-container">
      {/* Header Section */}
      <section className="podcasts-header">
        <h1 className="podcasts-heading">
          <FaPodcast className="podcasts-icon" /> Podcasts
        </h1>
        <p className="podcasts-intro">
          Tune into our latest podcasts to enrich your spiritual journey and connect with our community.
        </p>
      </section>

      {/* Search Form */}
      <form className="search-form" onSubmit={handleSearch}>
        <input
          type="text"
          placeholder="Search podcasts..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
          aria-label="Search podcasts"
        />
        <button type="submit" className="search-button" aria-label="Search">
          <FaSearch />
        </button>
      </form>

      {/* Loading Indicator */}
      {loading && <p className="status-message">Loading podcasts...</p>}

      {/* Error Message */}
      {error && <p className="status-message error">{error}</p>}

      {/* Podcasts List */}
      <div className="podcasts-list">
        {!loading && !error && podcasts.length === 0 && (
          <p className="status-message">No podcasts found.</p>
        )}
        {podcasts.map((podcast) => (
          <div key={podcast.collectionId} className="podcast-card">
            <img
              src={podcast.artworkUrl600}
              alt={`${podcast.collectionName} Thumbnail`}
              className="podcast-thumbnail"
            />
            <div className="podcast-details">
              <h2 className="podcast-title">{podcast.collectionName}</h2>
              <p className="podcast-author">By {podcast.artistName}</p>
              {episodes[podcast.collectionId] && episodes[podcast.collectionId].length > 0 ? (
                <div className="episodes-list">
                  <h3>Latest Episodes:</h3>
                  {episodes[podcast.collectionId].map((episode) => (
                    <div key={episode.guid} className="episode">
                      <p className="episode-title">{episode.title}</p>
                      <audio controls className="podcast-audio">
                        <source src={episode.enclosure.url} type="audio/mpeg" />
                        Your browser does not support the audio element.
                      </audio>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="no-episodes">No episodes available.</p>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Podcasts;
