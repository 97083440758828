// src/components/Help.jsx
import React from "react";
import { FaQuestionCircle, FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Help.css";
import SEO from './SEO';


function Help() {
  return (
    <div className="help-container">
      
      {/* Header Section */}
      <section className="help-header">
        <h1 className="help-heading">
          <FaQuestionCircle className="help-icon" /> Help
        </h1>
        <p className="help-intro">
          Welcome to our Help Center! Here you'll find information on how to navigate and utilize the various features of our website.
        </p>
      </section>
      
      {/* Features Overview */}
      <section className="features-overview">
        <h2>Website Features</h2>
        <div className="features-grid">
          <div className="feature-card">
            <h3>Live Streaming</h3>
            <p>
              Watch live worship sessions, sermons, and events directly on our website through our embedded YouTube channel.
            </p>
          </div>
          <div className="feature-card">
            <h3>Small Groups</h3>
            <p>
              Find and join Christian small groups in your area to foster community and spiritual growth.
            </p>
          </div>
          <div className="feature-card">
            <h3>Resource Library</h3>
            <p>
              Access a wide range of Christian resources, including sermons, study guides, and inspirational materials through our embedded Google Drive.
            </p>
          </div>
          <div className="feature-card">
            <h3>Gamification</h3>
            <p>
              Engage with our community through a points and badges system, encouraging active participation and spiritual growth.
            </p>
          </div>
          <div className="feature-card">
            <h3>Counseling</h3>
            <p>
              Connect with experienced counselors for personal, couples, and family counseling sessions to support your spiritual and personal well-being.
            </p>
          </div>
          <div className="feature-card">
            <h3>Mentorship</h3>
            <p>
              Participate in our mentorship program to grow as a Christian leader or to be guided by experienced mentors.
            </p>
          </div>
          <div className="feature-card">
            <h3>Volunteer</h3>
            <p>
              Contribute to our global Christian community by volunteering your time and talents in various roles.
            </p>
          </div>
          <div className="feature-card">
            <h3>Forums</h3>
            <p>
              Engage in meaningful discussions, ask questions, and share insights within our community forums.
            </p>
          </div>
          <div className="feature-card">
            <h3>Prayer Wall</h3>
            <p>
              Submit your prayer requests and support others by praying for their needs through our Prayer Wall.
            </p>
          </div>
          <div className="feature-card">
            <h3>Profile</h3>
            <p>
              Manage your profile, track your achievements, and enjoy personalized benefits as a member.
            </p>
          </div>
          <div className="feature-card">
            <h3>Community</h3>
            <p>
              Join and participate in various community groups and events to build lasting relationships and grow in faith.
            </p>
          </div>
        </div>
      </section>
      
      {/* Goals */}
      <section className="help-goals">
        <h2>Our Goals</h2>
        <ul>
          <li>To provide a supportive and engaging platform for spiritual growth.</li>
          <li>To connect Christians and non-Christians in a meaningful community.</li>
          <li>To offer resources and tools that strengthen faith and leadership.</li>
          <li>To foster an environment of compassion, support, and shared experiences.</li>
        </ul>
      </section>
      
      {/* Frequently Asked Questions */}
      <section className="faq-section">
        <h2>Frequently Asked Questions</h2>
        <div className="faq-item">
          <h3>How do I join a small group?</h3>
          <p>
            Navigate to the Small Groups page, select your country, state, and region to find groups near you. Click on a group to join or contact the group leader for more information.
          </p>
        </div>
        <div className="faq-item">
          <h3>How can I submit a prayer request?</h3>
          <p>
            Visit the Prayer Wall page and click on the "Submit Prayer Request" button to fill out the form. Your requests will be shared with the community for prayer support.
          </p>
        </div>
        <div className="faq-item">
          <h3>How do I become a mentor?</h3>
          <p>
            Go to the Mentorship page and click on the "Nominate as a Mentor" button to fill out the nomination form. Our team will review your application and match you with a mentee.
          </p>
        </div>
        {/* Add more FAQs as needed */}
      </section>
      
      {/* Contact Support */}
      <section className="contact-support">
        <h2>Need More Help?</h2>
        <p>
          If you have any additional questions or need assistance, feel free to reach out to our support team at <a href="mailto:support@gccworship.com">support@gccworship.com</a>.
        </p>
      </section>
      
      {/* Navigation Link Back */}
      <section className="navigation-link-back">
        <Link to="/" className="cta-button" aria-label="Back to Home">
          <FaArrowLeft className="back-icon" /> Back to Home
        </Link>
      </section>
      
    </div>
  );
}

export default Help;
