// src/components/BibleReader.jsx
import React, { useState, useEffect, useRef } from 'react';
import './BibleReader.css'; // Import your CSS file here
import SEO from './components/SEO';

const booksData = [
  { name: "Genesis", chapters: 50 },
  { name: "Exodus", chapters: 40 },
  { name: "Leviticus", chapters: 27 },
  { name: "Numbers", chapters: 36 },
  { name: "Deuteronomy", chapters: 34 },
  { name: "Joshua", chapters: 24 },
  { name: "Judges", chapters: 21 },
  { name: "Ruth", chapters: 4 },
  { name: "1 Samuel", chapters: 31 },
  { name: "2 Samuel", chapters: 24 },
  { name: "1 Kings", chapters: 22 },
  { name: "2 Kings", chapters: 25 },
  { name: "1 Chronicles", chapters: 29 },
  { name: "2 Chronicles", chapters: 36 },
  { name: "Ezra", chapters: 10 },
  { name: "Nehemiah", chapters: 13 },
  { name: "Esther", chapters: 10 },
  { name: "Job", chapters: 42 },
  { name: "Psalms", chapters: 150 },
  { name: "Proverbs", chapters: 31 },
  { name: "Ecclesiastes", chapters: 12 },
  { name: "Song of Solomon", chapters: 8 },
  { name: "Isaiah", chapters: 66 },
  { name: "Jeremiah", chapters: 52 },
  { name: "Lamentations", chapters: 5 },
  { name: "Ezekiel", chapters: 48 },
  { name: "Daniel", chapters: 12 },
  { name: "Hosea", chapters: 14 },
  { name: "Joel", chapters: 3 },
  { name: "Amos", chapters: 9 },
  { name: "Obadiah", chapters: 1 },
  { name: "Jonah", chapters: 4 },
  { name: "Micah", chapters: 7 },
  { name: "Nahum", chapters: 3 },
  { name: "Habakkuk", chapters: 3 },
  { name: "Zephaniah", chapters: 3 },
  { name: "Haggai", chapters: 2 },
  { name: "Zechariah", chapters: 14 },
  { name: "Malachi", chapters: 4 },
  { name: "Matthew", chapters: 28 },
  { name: "Mark", chapters: 16 },
  { name: "Luke", chapters: 24 },
  { name: "John", chapters: 21 },
  { name: "Acts", chapters: 28 },
  { name: "Romans", chapters: 16 },
  { name: "1 Corinthians", chapters: 16 },
  { name: "2 Corinthians", chapters: 13 },
  { name: "Galatians", chapters: 6 },
  { name: "Ephesians", chapters: 6 },
  { name: "Philippians", chapters: 4 },
  { name: "Colossians", chapters: 4 },
  { name: "1 Thessalonians", chapters: 5 },
  { name: "2 Thessalonians", chapters: 3 },
  { name: "1 Timothy", chapters: 6 },
  { name: "2 Timothy", chapters: 4 },
  { name: "Titus", chapters: 3 },
  { name: "Philemon", chapters: 1 },
  { name: "Hebrews", chapters: 13 },
  { name: "James", chapters: 5 },
  { name: "1 Peter", chapters: 5 },
  { name: "2 Peter", chapters: 3 },
  { name: "1 John", chapters: 5 },
  { name: "2 John", chapters: 1 },
  { name: "3 John", chapters: 1 },
  { name: "Jude", chapters: 1 },
  { name: "Revelation", chapters: 22 }
];

export default function BibleReader() {
  // -----------------------------
  //           State
  // -----------------------------
  const [books] = useState(booksData);
  const [currentBookIndex, setCurrentBookIndex] = useState(0);
  const [currentBook, setCurrentBook] = useState(booksData[0].name);
  const [currentChapter, setCurrentChapter] = useState(1);
  const [currentVerses, setCurrentVerses] = useState([]);
  const [currentVersePage, setCurrentVersePage] = useState(0);
  const [versesPerPage, setVersesPerPage] = useState(3);
  const [currentVersion, setCurrentVersion] = useState('KJV');
  const [currentLanguage, setCurrentLanguage] = useState('en');
  const [isPlaying, setIsPlaying] = useState(false);
  const [wordsPerMinute, setWordsPerMinute] = useState(170);

  const [searchQuery, setSearchQuery] = useState('');
  const [autocompleteItems, setAutocompleteItems] = useState([]);
  const [controlsHidden, setControlsHidden] = useState(true);

  // For the help modal
  const [showHelp, setShowHelp] = useState(false);

  // For share tooltip
  const [showShareTooltip, setShowShareTooltip] = useState(false);

  // Timeout reference for auto-advance
  const autoAdvanceRef = useRef(null);

  // Audio ref (if you actually implement audio)
  const audioRef = useRef(null);


  // -----------------------------
  //        Effects
  // -----------------------------

  /**
   * On component mount, we initialize from URL params if present,
   * otherwise fetch the default (Genesis 1).
   */
  useEffect(() => {
    handleURLParameters();
    // Add keydown listener
    const handleKeyDown = (event) => {
      if (
        document.activeElement &&
        ['INPUT', 'TEXTAREA', 'SELECT'].includes(document.activeElement.tagName)
      ) {
        // Do not process global shortcuts if user is typing in an input
        return;
      }
      if (event.ctrlKey ) {
        event.preventDefault();
        toggleControlsVisibility();
      }
      switch (event.key) {
        case 'control':
          event.preventDefault();
          toggleControlsVisibility();
          break;
        case ' ':
          event.preventDefault();
          togglePlayPause();
          break;
        case 'ArrowRight':
          handleNext();
          break;
        case 'PageDown':
          handleNext();
          break;
        case 'ArrowLeft':
          handlePrev();  
          break;
        case 'PageUp':
          handlePrev();
          break;
        case 'ArrowUp':
          moveToNextChapter();
          break;
        case 'ArrowDown':
          moveToPrevChapter();
          break;
        case '>':
        case '.': // Shift + '.' is '>', but we catch it here anyway
          increaseWPM();
          break;
        case '<':
        case ',': // Shift + ',' is '<'
          decreaseWPM();
          break;
        case '+':
        case '=': // For shift+ or normal '+'
          event.preventDefault(); // Prevent browser zoom
          increaseVersesPerPage();
          break;
        case '-':
        case '_':
          event.preventDefault(); // Prevent browser zoom
          decreaseVersesPerPage();
          break;
        case '?':
          setShowHelp(true);
          break;
        default:
          // If digit, jump to verse
          if (!isNaN(event.key)) {
            jumpToVerse(parseInt(event.key, 10));
          }
          // If letter, cycle through books
          if (/^[a-zA-Z]$/.test(event.key)) {
            handleBookSelectionByLetter(event.key.toLowerCase());
          }
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Clean up on unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Whenever currentBook/currentChapter/currentVersion/currentLanguage changes,
   * fetch the verses from the API.
   */
  useEffect(() => {
    fetchVerses(currentBook, currentChapter, currentVersion, currentLanguage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBook, currentChapter, currentVersion, currentLanguage]);

  /**
   * Whenever we update the verses, re-display from the current page.
   */
  useEffect(() => {
    // reset verse page if new verses
    setCurrentVersePage(0);
  }, [currentVerses]);

  /**
   * Each time something changes that affects the shareable state,
   * we can update the URL (like your original code).
   */
  useEffect(() => {
    updateURL();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentBookIndex,
    currentChapter,
    currentVersion,
    currentLanguage,
    versesPerPage,
    wordsPerMinute,
    currentVersePage,
  ]);

  // -----------------------------
  //     Main Fetch Function
  // -----------------------------
  async function fetchVerses(book, chapter, version, language) {
    try {
      // In your example, the API is https://bible-api.com/<book+chapter>?translation=<version>
      // This might not support languages. Adjust accordingly if needed.
      const apiUrl = `https://bible-api.com/${encodeURIComponent(book)}+${chapter}?translation=${version}`;
      const res = await fetch(apiUrl);
      const data = await res.json();

      if (data.error) {
        setCurrentVerses([]);
        return;
      }
      setCurrentVerses(data.verses); // array of verse objects
    } catch (err) {
      console.error('Error fetching verses:', err);
      setCurrentVerses([]);
    }
  }

  // -----------------------------
  //       Rendering Logic
  // -----------------------------
  function getDisplayedVerses() {
    const start = currentVersePage * versesPerPage;
    const end = start + versesPerPage;
    return currentVerses.slice(start, end);
  }

  function renderVerses() {
    const versesToShow = getDisplayedVerses();
    if (versesToShow.length === 0) {
      return <div className="br-no-verses">No verses available.</div>;
    }

    // Concatenate the verses into one slide
    const concatenated = versesToShow
      .map((verse) => {
        return (
          `<span class="br-verse-number">${verse.verse}.</span> ${verse.text}`
        );
      })
      .join(' ');

    return (
      <div className="br-verse" dangerouslySetInnerHTML={{ __html: concatenated }} />
    );
  }

  // -----------------------------
  //       Navigation
  // -----------------------------
  function handleNext() {
    // If there's another “page” of verses in the current chapter, go to it
    if ((currentVersePage + 1) * versesPerPage < currentVerses.length) {
      setCurrentVersePage((prev) => prev + 1);
    } else {
      // Otherwise, go to next chapter
      moveToNextChapter();
    }
  }

  function handlePrev() {
    if (currentVersePage > 0) {
      setCurrentVersePage((prev) => prev - 1);
    } else {
      // Move to previous chapter
      moveToPrevChapter();
    }
  }

  function moveToNextChapter() {
    const totalChapters = books[currentBookIndex].chapters;
    if (currentChapter < totalChapters) {
      setCurrentChapter((c) => c + 1);
    } else if (currentBookIndex < books.length - 1) {
      // Move to next book, chapter 1
      const newBookIndex = currentBookIndex + 1;
      setCurrentBookIndex(newBookIndex);
      setCurrentBook(books[newBookIndex].name);
      setCurrentChapter(1);
    } else {
      alert('Reached the end of the Bible.');
    }
  }

  function moveToPrevChapter() {
    if (currentChapter > 1) {
      setCurrentChapter((c) => c - 1);
    } else if (currentBookIndex > 0) {
      // Move to previous book's last chapter
      const newBookIndex = currentBookIndex - 1;
      setCurrentBookIndex(newBookIndex);
      setCurrentBook(books[newBookIndex].name);
      setCurrentChapter(books[newBookIndex].chapters);
    } else {
      alert('You are at the beginning of the Bible.');
    }
  }

  // -----------------------------
  //    Play/Pause / Auto-Advance
  // -----------------------------
  function togglePlayPause() {
    if (isPlaying) {
      pauseAutoAdvance();
    } else {
      startAutoAdvance();
    }
  }

  function startAutoAdvance() {
    // Do nothing if we have no verses or if we're already playing
    if (!currentVerses || currentVerses.length === 0) return;
    setIsPlaying(true);
  
    // Get the verses for the "current page" (depends on versesPerPage)
    const versesToShow = getDisplayedVerses(); 
    let totalWords = 0;
    let punctuationCount = 0;
  
    // Count words and punctuation across all verses in this slide
    versesToShow.forEach((verse) => {
      const text = verse.text.trim();
      const wordArray = text.split(/\s+/); 
      totalWords += wordArray.length;
  
      // Count punctuation: e.g., commas, periods, question marks, etc.
      const matches = text.match(/[.,!?;:]/g);
      if (matches) {
        punctuationCount += matches.length;
      }
    });
  
    // 1) Base time from total words:
    // wordsPerMinute -> ms per word = 60,000 / WPM
    // totalWords * ms_per_word = total reading time
    let timeMs = Math.ceil((totalWords / wordsPerMinute) * 60_000);
  
    // 2) Overhead for punctuation: e.g. 300ms each
    //    so that punctuation or breaks get extra pause
    const punctuationOverhead = punctuationCount * 300;
  
    // 3) Overhead per verse: e.g. 1 second per verse
    //    helps to not rush transitions between verses
    const verseOverhead = versesToShow.length * 1000;
  
    // Combine them
    timeMs += punctuationOverhead + verseOverhead;
  
    // OPTIONAL: If you want a universal padding, e.g. +1 second:
    // timeMs += 1000;
  
    // Schedule move to next slide after computed time
    autoAdvanceRef.current = setTimeout(() => {
      // Check if there's another page of verses in the current chapter
      if ((currentVersePage + 1) * versesPerPage < currentVerses.length) {
        // Move to next page
        setCurrentVersePage((prev) => prev + 1);
        // Recursively schedule the next slide's timing
        startAutoAdvance();
      } else {
        // Otherwise, move to next chapter or stop
        moveToNextChapter();
        setIsPlaying(false);
      }
    }, timeMs);
  }


  function pauseAutoAdvance() {
    setIsPlaying(false);
    if (autoAdvanceRef.current) {
      clearTimeout(autoAdvanceRef.current);
    }
  }

  // -----------------------------
  //       WPM Adjustments
  // -----------------------------
  function increaseWPM() {
    setWordsPerMinute((wpm) => wpm + 1);
  }

  function decreaseWPM() {
    setWordsPerMinute((wpm) => (wpm > 1 ? wpm - 1 : 1));
  }

  // -----------------------------
  //        Verses/Slider
  // -----------------------------
  function increaseVersesPerPage() {
    if (versesPerPage < 20) {
      setVersesPerPage((v) => v + 1);
      setCurrentVersePage(0);
    }
  }

  function decreaseVersesPerPage() {
    if (versesPerPage > 1) {
      setVersesPerPage((v) => v - 1);
      setCurrentVersePage(0);
    }
  }

  function onSliderChange(e) {
    const val = parseInt(e.target.value, 10);
    setVersesPerPage(val);
    setCurrentVersePage(0);
  }

  // -----------------------------
  //   Book, Chapter Selectors
  // -----------------------------
  function handleBookChange(e) {
    const index = parseInt(e.target.value, 10);
    setCurrentBookIndex(index);
    setCurrentBook(books[index].name);
    setCurrentChapter(1);
  }

  function handleChapterChange(e) {
    const ch = parseInt(e.target.value, 10);
    setCurrentChapter(ch);
  }

  function handleVersionChange(e) {
    setCurrentVersion(e.target.value);
  }

  function handleLanguageChange(e) {
    setCurrentLanguage(e.target.value);
  }

  // -----------------------------
  //         Searching
  // -----------------------------
  function handleSearchInput(e) {
    const value = e.target.value;
    setSearchQuery(value);

    if (!value) {
      setAutocompleteItems([]);
      return;
    }

    // Naive startsWith matching
    const matches = books.filter((book) =>
      book.name.toLowerCase().startsWith(value.toLowerCase())
    );
    setAutocompleteItems(matches);
  }

  function applyAutocompleteItem(bookName) {
    setSearchQuery(bookName);
    setAutocompleteItems([]);
  }

  function closeAutocomplete() {
    setAutocompleteItems([]);
  }

  function handleSearchKeyPress(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (!searchQuery) return;
      parseAndExecuteSearch(searchQuery);
    }
  }

  function parseAndExecuteSearch(query) {
    /*
      Expected formats:
      1. Chapter only: "1"
      2. Book and Chapter: "Genesis 1"
      3. Book, Chapter, Start Verse: "Genesis 1 1"
      4. Book, Chapter, Start Verse - End Verse: "Genesis 1 1-5"
    */

    const parts = query.split(/\s+/);
    if (parts.length === 0) return;

    let foundBookIndex = -1;
    let bookName = '';
    let chapterNumber = null;
    let verseStart = null;
    let verseEnd = null;

    // Try matching the longest possible book name from the start
    for (let i = parts.length; i > 0; i--) {
      const potentialBook = parts.slice(0, i).join(' ');
      const idx = books.findIndex(
        (b) => b.name.toLowerCase() === potentialBook.toLowerCase()
      );
      if (idx !== -1) {
        foundBookIndex = idx;
        bookName = books[idx].name;
        break;
      }
    }

    // If no valid book found, assume current book
    if (foundBookIndex === -1) {
      foundBookIndex = currentBookIndex;
      bookName = currentBook;
    }

    const remainingParts = parts.slice(bookName.split(' ').length);

    // Chapter
    if (remainingParts.length >= 1) {
      const c = parseInt(remainingParts[0], 10);
      if (!isNaN(c) && c >= 1 && c <= books[foundBookIndex].chapters) {
        chapterNumber = c;
      } else {
        alert('Invalid chapter number.');
        return;
      }
    }

    // Verses
    if (remainingParts.length >= 2) {
      const versePart = remainingParts[1];
      const verseRange = versePart.split('-');
      verseStart = parseInt(verseRange[0], 10);
      if (isNaN(verseStart) || verseStart < 1) {
        alert('Invalid start verse.');
        return;
      }
      if (verseRange.length === 2) {
        verseEnd = parseInt(verseRange[1], 10);
        if (isNaN(verseEnd) || verseEnd < verseStart) {
          alert('Invalid end verse.');
          return;
        }
      }
    }

    // Update state
    setCurrentBookIndex(foundBookIndex);
    setCurrentBook(books[foundBookIndex].name);

    if (chapterNumber !== null) {
      setCurrentChapter(chapterNumber);
    } else {
      setCurrentChapter(1);
    }

    // After we fetch verses, we’ll jump to them
    // We'll do it in a setTimeout to wait for the fetch to complete
    setTimeout(() => {
      if (verseStart !== null) {
        jumpToVerse(verseStart, verseEnd);
      }
    }, 800);

    setAutocompleteItems([]);
  }

  // Jump to verse or verse range
  function jumpToVerse(verseStart, verseEnd = null) {
    if (!currentVerses || currentVerses.length === 0) return;
    const startIndex = currentVerses.findIndex((v) => v.verse === verseStart);
    if (startIndex === -1) {
      alert('Starting verse not found.');
      return;
    }

    if (verseEnd !== null) {
      const endIndex = currentVerses.findIndex((v) => v.verse === verseEnd);
      if (endIndex === -1) {
        alert('Ending verse not found.');
        return;
      }
      // We'll temporarily set versesPerPage to show that entire range
      const rangeSize = endIndex - startIndex + 1;
      setVersesPerPage(rangeSize);
      setCurrentVersePage(Math.floor(startIndex / rangeSize));
    } else {
      // Just jump to the page that contains verseStart
      setCurrentVersePage(Math.floor(startIndex / versesPerPage));
    }
  }

  // Book selection by letter
  const bookSelectionState = useRef({
    lastLetter: '',
    pressCount: 0
  });

  function handleBookSelectionByLetter(letter) {
    const matching = books.filter((b) =>
      b.name.toLowerCase().startsWith(letter)
    );
    if (matching.length === 0) return;

    if (bookSelectionState.current.lastLetter !== letter) {
      bookSelectionState.current.lastLetter = letter;
      bookSelectionState.current.pressCount = 0;
    } else {
      bookSelectionState.current.pressCount =
        (bookSelectionState.current.pressCount + 1) % matching.length;
    }

    const selectedBook = matching[bookSelectionState.current.pressCount];
    const idx = books.findIndex((b) => b.name === selectedBook.name);
    if (idx !== -1) {
      setCurrentBookIndex(idx);
      setCurrentBook(selectedBook.name);
      setCurrentChapter(1);
    }
  }

  // -----------------------------
  //        Help Modal
  // -----------------------------
  function openHelpModal() {
    setShowHelp(true);
  }

  function closeHelpModal() {
    setShowHelp(false);
  }

  // -----------------------------
  //      URL/Sharing
  // -----------------------------
  function generateShareURL() {
    const params = new URLSearchParams();
    params.set('book', currentBookIndex);
    params.set('chapter', currentChapter);
    params.set('version', currentVersion);
    params.set('language', currentLanguage);
    params.set('verses', getVerseInputValue());
    params.set('page', currentVersePage);
    params.set('lines', versesPerPage);
    params.set('wpm', wordsPerMinute);

    const base = window.location.origin + window.location.pathname;
    return base + '?' + params.toString();
  }

  function updateURL() {
    const newUrl = generateShareURL();
    window.history.replaceState({}, '', newUrl);
  }

  function copyToClipboard(text) {
    navigator.clipboard.writeText(text).catch((err) => {
      console.error('Failed to copy: ', err);
    });
  }

  function handleShare() {
    const url = generateShareURL();
    copyToClipboard(url);
    setShowShareTooltip(true);

    // Hide tooltip after 2 seconds
    setTimeout(() => {
      setShowShareTooltip(false);
    }, 2000);
  }

  // -----------------------------
  //    Handling URL Params
  // -----------------------------
  function handleURLParameters() {
    const params = new URLSearchParams(window.location.search);

    let bookIndex = params.get('book');
    let chapterParam = params.get('chapter');
    let versionParam = params.get('version');
    let languageParam = params.get('language');
    let linesParam = params.get('lines');
    let wpmParam = params.get('wpm');
    let pageParam = params.get('page');

    if (bookIndex !== null) {
      bookIndex = parseInt(bookIndex, 10);
      if (!isNaN(bookIndex) && bookIndex >= 0 && bookIndex < books.length) {
        setCurrentBookIndex(bookIndex);
        setCurrentBook(books[bookIndex].name);
      }
    }

    if (chapterParam !== null) {
      const c = parseInt(chapterParam, 10);
      if (!isNaN(c)) {
        setCurrentChapter(c);
      }
    }

    if (versionParam !== null) {
      setCurrentVersion(versionParam);
    }

    if (languageParam !== null) {
      setCurrentLanguage(languageParam);
    }

    if (linesParam !== null) {
      const l = parseInt(linesParam, 10);
      if (!isNaN(l) && l >= 1 && l <= 20) {
        setVersesPerPage(l);
      }
    }

    if (wpmParam !== null) {
      const w = parseInt(wpmParam, 10);
      if (!isNaN(w) && w >= 1) {
        setWordsPerMinute(w);
      }
    }

    if (pageParam !== null) {
      const p = parseInt(pageParam, 10);
      if (!isNaN(p) && p >= 0) {
        setCurrentVersePage(p);
      }
    }
  }

  // -----------------------------
  //   Controls Visibility
  // -----------------------------
  function toggleControlsVisibility() {
    setControlsHidden((prev) => !prev);
  }
  
  // -----------------------------
  //    Verse Input Utilities
  // -----------------------------
  function getVerseInputValue() {
    if (!currentVerses || currentVerses.length === 0) return '';
    const startIndex = currentVersePage * versesPerPage;
    const endIndex = Math.min(startIndex + versesPerPage, currentVerses.length);

    const startVerse = currentVerses[startIndex]?.verse;
    const endVerse = currentVerses[endIndex - 1]?.verse;

    if (!startVerse) return '';
    if (startVerse === endVerse) return String(startVerse);
    return `${startVerse}-${endVerse}`;
  }

  // -----------------------------
  //         Render
  // -----------------------------
  return (
    <div
      className="br-bible-reader-root" // Updated className
      // Disable context menu (like your original code)
      onContextMenu={(e) => {
        e.preventDefault();
        return false;
      }}
    >
      <div id="br-bible-container"> {/* Updated id */}

      <SEO
        title="Bible Reader - Global Christian Community"
        description="Read and listen to the Bible with our interactive Bible Reader. Choose your translation, language, and more."
        keywords="Bible reader, Bible study, Bible, Christian, Global Christian Community"
        url="https://globalchristiancommunity.com/bible"
        image="https://globalchristiancommunity.com/images/og-bible-reader.jpg"
      />
        <button id="br-toggle-controls-btn" onClick={toggleControlsVisibility}>
          {controlsHidden ? '👁️' : '👁️‍🗨️'}
        </button>

        {/* Title */}
        <div id="br-title"> {/* Updated id */}
          {currentBook} {currentChapter},{" "}
          {currentVerses.length > 0 ? (
            <>
              Verses {getVerseInputValue() || "1"}
            </>
          ) : (
            "Loading..."
          )}
        </div>

        {/* Controls */}
        <div id="br-controls" className={controlsHidden ? 'br-hidden' : ''}> {/* Updated id and className */}
          <div className="br-controls-group"> {/* Updated className */}
            {/* Search Input with Autocomplete */}
            <div style={{ position: 'relative', width: '200px' }}>
              <input
                type="text"
                id="br-search-input" // Updated id
                placeholder="Search Book Chapter Verse"
                autoComplete="off"
                value={searchQuery}
                onChange={handleSearchInput}
                onKeyPress={handleSearchKeyPress}
              />
              {/* Autocomplete dropdown */}
              {autocompleteItems.length > 0 && (
                <div id="br-autocomplete-list" className="br-autocomplete-items"> {/* Updated id and className */}
                  {autocompleteItems.map((item, idx) => (
                    <div
                      key={idx}
                      className="br-autocomplete-item" // Updated className
                      onClick={() => applyAutocompleteItem(item.name)}
                    >
                      <strong>
                        {item.name.substring(
                          0,
                          searchQuery.length
                        )}
                      </strong>
                      {item.name.substring(searchQuery.length)}
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Language Selector */}
            <select
              id="br-language-selector" // Updated id
              value={currentLanguage}
              onChange={handleLanguageChange}
            >
              <option value="en">English</option>
              <option value="ta">Tamil</option>
              <option value="te">Telugu</option>
              <option value="hi">Hindi</option>
              <option value="ml">Malayalam</option>
            </select>

            {/* Version Selector */}
            <select
              id="br-version-selector" // Updated id
              value={currentVersion}
              onChange={handleVersionChange}
            >
              <option value="KJV">KJV</option>
              <option value="NIV">NIV</option>
              <option value="ESV">ESV</option>
              <option value="WEB">World Bible (WEB)</option>
            </select>

            {/* Book Selector */}
            <select
              id="br-book-selector" // Updated id
              value={currentBookIndex}
              onChange={handleBookChange}
            >
              {books.map((b, i) => (
                <option key={b.name} value={i}>
                  {b.name}
                </option>
              ))}
            </select>

            {/* Chapter Selector */}
            <select
              id="br-chapter-selector" // Updated id
              value={currentChapter}
              onChange={handleChapterChange}
            >
              {Array.from(
                { length: books[currentBookIndex].chapters },
                (_, i) => i + 1
              ).map((ch) => (
                <option key={ch} value={ch}>
                  {ch}
                </option>
              ))}
            </select>

            {/* Display Mode Selector (Currently only "Slide Mode") */}
            <select id="br-display-mode-selector" defaultValue="slide"> {/* Updated id */}
              <option value="slide">Slide Mode</option>
            </select>

            {/* Share Button with Tooltip */}
            <div className="br-tooltip"> {/* Updated className */}
              <button id="br-share-btn" onClick={handleShare}> {/* Updated id */}
                Share
              </button>
              <span
                className="br-tooltiptext" // Updated className
                id="br-share-tooltip" // Updated id
                style={{
                  visibility: showShareTooltip ? 'visible' : 'hidden',
                  opacity: showShareTooltip ? '1' : '0',
                }}
              >
                URL Copied!
              </span>
            </div>

            {/* Help Button */}
            <button id="br-help-btn" onClick={openHelpModal}> {/* Updated id */}
              ?
            </button>
          </div>

          <div className="br-controls-group"> {/* Updated className */}
            <input
              type="text"
              id="br-verse-input" // Updated id
              placeholder="Verse(s) (e.g., 1)"
              value={getVerseInputValue()}
              readOnly
              style={{ cursor: 'default' }}
            />

            <button id="br-play-pause-btn" onClick={togglePlayPause}> {/* Updated id */}
              {isPlaying ? '⏸' : '▶'}
            </button>

            <span id="br-playback-rate-display">{wordsPerMinute} WPM</span> {/* Updated id */}

            {/* Slider for # of Verses per Slide */}
            <div id="br-slider-container"> {/* Updated id */}
              <label htmlFor="br-lines-slider">Lines per Slide:</label> {/* Updated htmlFor */}
              <input
                type="range"
                id="br-lines-slider" // Updated id
                min="1"
                max="20"
                value={versesPerPage}
                onChange={onSliderChange}
              />
              <span id="br-slider-value">{versesPerPage}</span> {/* Updated id */}
            </div>
          </div>
        </div>

        {/* Content Box */}
        <div id="br-content-box"> {/* Updated id */}
          <div id="br-verses-display" className="br-responsive-text"> {/* Updated id and className */}
            {currentVerses.length === 0 ? 'Loading...' : renderVerses()}
          </div>
        </div>

        {/* Navigation Buttons */}
        <button id="br-prev-btn" className="br-nav-button" onClick={handlePrev}> {/* Updated id and className */}
          ◀
        </button>
        <button id="br-next-btn" className="br-nav-button" onClick={handleNext}> {/* Updated id and className */}
          ▶
        </button>

        {/* Help Modal */}
        {showHelp && (
          <div id="br-help-modal" className="br-help-modal"> {/* Updated id and className */}
            <div id="br-help-modal-content" className="br-help-modal-content"> {/* Updated id and className */}
              <span id="br-close-help" className="br-close-help" onClick={closeHelpModal}> {/* Updated id and className */}
                &times;
              </span>
              <h2>Help &amp; Keyboard Shortcuts</h2>
              <p>
                Welcome to the Bible Reader application! Below are the keyboard
                shortcuts and instructions on how to use each feature:
              </p>
              <h3>Keyboard Shortcuts:</h3>
              <ul>
                <li>
                  <strong>Control (Ctrl) Key:</strong> Toggle visibility of
                  controls.
                </li>
                <li>
                  <strong>Space Bar:</strong> Play/Pause the auto-advance
                  feature.
                </li>
                <li>
                  <strong>&gt; Key:</strong> Increase WPM by 1.
                </li>
                <li>
                  <strong>&lt; Key:</strong> Decrease WPM by 1.
                </li>
                <li>
                  <strong>+</strong> Key: Increase the number of verses per
                  slide by 1.
                </li>
                <li>
                  <strong>-</strong> Key: Decrease the number of verses per
                  slide by 1.
                </li>
                <li>
                  <strong>ArrowRight:</strong> Next slide.
                </li>
                <li>
                  <strong>ArrowLeft:</strong> Previous slide.
                </li>
                <li>
                  <strong>ArrowUp:</strong> Next chapter.
                </li>
                <li>
                  <strong>ArrowDown:</strong> Previous chapter.
                </li>
                <li>
                  <strong>Number Keys (0-9):</strong> Jump to specific verses.
                </li>
                <li>
                  <strong>Letter Keys (A-Z):</strong> Cycle through books that
                  start with that letter.
                </li>
                <li>
                  <strong>? Key:</strong> Open/Close this Help modal.
                </li>
              </ul>
              <h3>How to Use:</h3>
              <ul>
                <li>
                  <strong>Search:</strong> Use the search box to find specific
                  passages (e.g., “Genesis 1 1-5”).
                </li>
                <li>
                  <strong>Play/Pause:</strong> Click the play button or press
                  space bar to auto-advance verses.
                </li>
                <li>
                  <strong>Share:</strong> Click “Share” to copy a URL containing
                  your current reading state.
                </li>
                <li>
                  <strong>Toggle Controls:</strong> Press Ctrl to show/hide the
                  top controls.
                </li>
              </ul>
              <p>Enjoy your reading!</p>
            </div>
          </div>
        )}

        {/* Hidden Audio Player (if you add real audio sources) */}
        <audio id="br-audio-player" ref={audioRef}></audio> {/* Updated id */}
      </div>
    </div>
  );
}
