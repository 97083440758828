import React, { useState, useEffect, useRef } from 'react';
import './BibleReader2.css'; // Make sure your CSS file is correct
import SEO from './SEO';

const booksData = [
  { name: "Genesis", chapters: 50 },
  { name: "Exodus", chapters: 40 },
  { name: "Leviticus", chapters: 27 },
  { name: "Numbers", chapters: 36 },
  { name: "Deuteronomy", chapters: 34 },
  { name: "Joshua", chapters: 24 },
  { name: "Judges", chapters: 21 },
  { name: "Ruth", chapters: 4 },
  { name: "1 Samuel", chapters: 31 },
  { name: "2 Samuel", chapters: 24 },
  { name: "1 Kings", chapters: 22 },
  { name: "2 Kings", chapters: 25 },
  { name: "1 Chronicles", chapters: 29 },
  { name: "2 Chronicles", chapters: 36 },
  { name: "Ezra", chapters: 10 },
  { name: "Nehemiah", chapters: 13 },
  { name: "Esther", chapters: 10 },
  { name: "Job", chapters: 42 },
  { name: "Psalms", chapters: 150 },
  { name: "Proverbs", chapters: 31 },
  { name: "Ecclesiastes", chapters: 12 },
  { name: "Song of Solomon", chapters: 8 },
  { name: "Isaiah", chapters: 66 },
  { name: "Jeremiah", chapters: 52 },
  { name: "Lamentations", chapters: 5 },
  { name: "Ezekiel", chapters: 48 },
  { name: "Daniel", chapters: 12 },
  { name: "Hosea", chapters: 14 },
  { name: "Joel", chapters: 3 },
  { name: "Amos", chapters: 9 },
  { name: "Obadiah", chapters: 1 },
  { name: "Jonah", chapters: 4 },
  { name: "Micah", chapters: 7 },
  { name: "Nahum", chapters: 3 },
  { name: "Habakkuk", chapters: 3 },
  { name: "Zephaniah", chapters: 3 },
  { name: "Haggai", chapters: 2 },
  { name: "Zechariah", chapters: 14 },
  { name: "Malachi", chapters: 4 },
  { name: "Matthew", chapters: 28 },
  { name: "Mark", chapters: 16 },
  { name: "Luke", chapters: 24 },
  { name: "John", chapters: 21 },
  { name: "Acts", chapters: 28 },
  { name: "Romans", chapters: 16 },
  { name: "1 Corinthians", chapters: 16 },
  { name: "2 Corinthians", chapters: 13 },
  { name: "Galatians", chapters: 6 },
  { name: "Ephesians", chapters: 6 },
  { name: "Philippians", chapters: 4 },
  { name: "Colossians", chapters: 4 },
  { name: "1 Thessalonians", chapters: 5 },
  { name: "2 Thessalonians", chapters: 3 },
  { name: "1 Timothy", chapters: 6 },
  { name: "2 Timothy", chapters: 4 },
  { name: "Titus", chapters: 3 },
  { name: "Philemon", chapters: 1 },
  { name: "Hebrews", chapters: 13 },
  { name: "James", chapters: 5 },
  { name: "1 Peter", chapters: 5 },
  { name: "2 Peter", chapters: 3 },
  { name: "1 John", chapters: 5 },
  { name: "2 John", chapters: 1 },
  { name: "3 John", chapters: 1 },
  { name: "Jude", chapters: 1 },
  { name: "Revelation", chapters: 22 }
];

export default function BibleReader() {
  // -----------------------------
  // State
  // -----------------------------
  const [books] = useState(booksData);
  const [currentBookIndex, setCurrentBookIndex] = useState(0);
  const [currentBook, setCurrentBook] = useState(booksData[0].name);
  const [currentChapter, setCurrentChapter] = useState(1);
  const [currentVerses, setCurrentVerses] = useState([]);
  const [currentVersePage, setCurrentVersePage] = useState(0);
  const [versesPerPage, setVersesPerPage] = useState(3);
  const [currentVersion, setCurrentVersion] = useState('KJV');
  const [currentLanguage, setCurrentLanguage] = useState('en');
  const [isPlaying, setIsPlaying] = useState(false);
  const [wordsPerMinute, setWordsPerMinute] = useState(150);

  // New controls for voice and background music.
  const [selectedVoice, setSelectedVoice] = useState("None");
  const [availableVoices, setAvailableVoices] = useState([]);
  const [backgroundMusic, setBackgroundMusic] = useState("None");

  const [searchQuery, setSearchQuery] = useState('');
  const [autocompleteItems, setAutocompleteItems] = useState([]);
  const [controlsHidden, setControlsHidden] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [showShareTooltip, setShowShareTooltip] = useState(false);

  // Token highlighting.
  const [slideTokens, setSlideTokens] = useState([]);
  const [currentTokenIndex, setCurrentTokenIndex] = useState(0);

  // Refs for timeouts, utterance, background music, and play state.
  const autoAdvanceRef = useRef(null);
  const utteranceRef = useRef(null);
  const bgMusicRef = useRef(null);
  const isPlayingRef = useRef(false);
  const audioRef = useRef(null); // For hidden audio if needed

  // -----------------------------
  // Effects
  // -----------------------------
  useEffect(() => {
    function loadVoices() {
      const voices = window.speechSynthesis.getVoices();
      setAvailableVoices(voices);
      const googleVoice = voices.find(v => v.name === "Google US English");
      if (googleVoice) {
        setSelectedVoice("Google US English");
      }
    }
    loadVoices();
    window.speechSynthesis.onvoiceschanged = loadVoices;

    const handleKeyDown = (event) => {
      if (
        document.activeElement &&
        ['INPUT', 'TEXTAREA', 'SELECT'].includes(document.activeElement.tagName)
      ) return;
      switch (event.key) {
        case 'Control': toggleControlsVisibility(); break;
        case ' ':
          event.preventDefault();
          togglePlayPause();
          break;
        case 'ArrowRight':
        case 'PageDown': handleNext(); break;
        case 'ArrowLeft':
        case 'PageUp': handlePrev(); break;
        case 'ArrowUp': moveToNextChapter(); break;
        case 'ArrowDown': moveToPrevChapter(); break;
        case '>':
        case '.': increaseWPM(); break;
        case '<':
        case ',': decreaseWPM(); break;
        case '+':
        case '=':
          event.preventDefault();
          increaseVersesPerPage();
          break;
        case '-':
        case '_':
          event.preventDefault();
          decreaseVersesPerPage();
          break;
        case '?': setShowHelp(true); break;
        default:
          if (!isNaN(event.key)) jumpToVerse(parseInt(event.key, 10));
          if (/^[a-zA-Z]$/.test(event.key)) handleBookSelectionByLetter(event.key.toLowerCase());
          break;
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  useEffect(() => {
    fetchVerses(currentBook, currentChapter, currentVersion, currentLanguage);
  }, [currentBook, currentChapter, currentVersion, currentLanguage]);

  useEffect(() => {
    setCurrentVersePage(0);
  }, [currentVerses]);

  useEffect(() => {
    updateURL();
  }, [currentBookIndex, currentChapter, currentVersion, currentLanguage, versesPerPage, wordsPerMinute, currentVersePage]);

  useEffect(() => {
    prepareSlideTokens();
  }, [currentVersePage, currentVerses]);

  // -----------------------------
  // Main Fetch Function
  // -----------------------------
  async function fetchVerses(book, chapter, version, language) {
    try {
      const apiUrl = `https://bible-api.com/${encodeURIComponent(book)}+${chapter}?translation=${version}`;
      const res = await fetch(apiUrl);
      const data = await res.json();
      if (data.error) {
        setCurrentVerses([]);
        return;
      }
      setCurrentVerses(data.verses);
    } catch (err) {
      console.error('Error fetching verses:', err);
      setCurrentVerses([]);
    }
  }

  // -----------------------------
  // Prepare Tokens (for Highlighting)
  // -----------------------------
  function prepareSlideTokens() {
    const versesToShow = getDisplayedVerses();
    // For highlighting, we use the full combined text (including verse numbers)
    const combinedText = versesToShow.map(v => `${v.verse}. ${v.text}`).join(' ');
    const tokens = combinedText.split(/(\s+)/);
    setSlideTokens(tokens);
    setCurrentTokenIndex(0);
  }

  function getDisplayedVerses() {
    const start = currentVersePage * versesPerPage;
    const end = start + versesPerPage;
    return currentVerses.slice(start, end);
  }

  function getVerseInputValue() {
    if (!currentVerses || currentVerses.length === 0) return '';
    const startIndex = currentVersePage * versesPerPage;
    const endIndex = Math.min(startIndex + versesPerPage, currentVerses.length);
    const startVerse = currentVerses[startIndex]?.verse;
    const endVerse = currentVerses[endIndex - 1]?.verse;
    if (!startVerse) return '';
    return startVerse === endVerse ? String(startVerse) : `${startVerse}-${endVerse}`;
  }

  // -----------------------------
  // Rendering: Highlighted Slide
  // -----------------------------
  function renderHighlightedSlide() {
    if (slideTokens.length === 0) return <div>No verses available.</div>;
    return (
      <div className="verse">
        {slideTokens.map((token, idx) => (
          <span key={idx} className={idx === currentTokenIndex ? 'word-highlight' : ''}>
            {token}
          </span>
        ))}
      </div>
    );
  }

  // -----------------------------
  // TTS & Token-by-Token Highlighting
  // -----------------------------
  // For TTS, we speak only the verse text (omitting verse numbers).
  function getCurrentSlideTTS() {
    const versesToShow = getDisplayedVerses();
    return versesToShow.map(v => v.text).join(' ');
  }

  function speakCurrentSlide() {
    if (utteranceRef.current) {
      window.speechSynthesis.cancel();
      utteranceRef.current = null;
    }
    const text = getCurrentSlideTTS();
    if (!text.trim()) return;
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.rate = wordsPerMinute / 150;
    if (selectedVoice !== "None") {
      const voice = availableVoices.find(v => v.name === selectedVoice);
      if (voice) utterance.voice = voice;
    }
    // Compute cumulative indices for token highlighting (based on full text tokens).
    let cumulativeIndices = [];
    let cum = 0;
    slideTokens.forEach(token => {
      cumulativeIndices.push(cum);
      cum += token.length;
    });
    utterance.onboundary = (event) => {
      if (event.name === 'word') {
        const charIndex = event.charIndex;
        const tokenIdx = cumulativeIndices.findIndex((ci, i) => {
          if (i === cumulativeIndices.length - 1) return true;
          return charIndex < cumulativeIndices[i + 1];
        });
        if (tokenIdx !== -1) setCurrentTokenIndex(tokenIdx);
      }
    };
    utterance.onend = () => {
      // When the slide finishes, advance to the next slide.
      setTimeout(() => {
        moveToNextSlideAndContinue();
      }, 300);
    };
    utteranceRef.current = utterance;
    window.speechSynthesis.speak(utterance);
  }

  function highlightNextToken(idx) {
    if (!isPlayingRef.current) return;
    if (idx >= slideTokens.length) {
      moveToNextSlideAndContinue();
      return;
    }
    const token = slideTokens[idx].trim();
    if (token === '' || !isNaN(token)) {
      highlightNextToken(idx + 1);
      return;
    }
    setCurrentTokenIndex(idx);
    const delayMs = Math.ceil(1000 / (wordsPerMinute / 60)) + (/[.,!?;:]/.test(token) ? 300 : 0);
    autoAdvanceRef.current = setTimeout(() => highlightNextToken(idx + 1), delayMs);
  }

  function moveToNextSlideAndContinue() {
    if (!isPlayingRef.current) return;
    if ((currentVersePage + 1) * versesPerPage < currentVerses.length) {
      // Advance to next slide.
      setCurrentVersePage(prev => prev + 1);
      // Allow state update, then start TTS or timer-based highlighting.
      setTimeout(() => {
        if (isPlayingRef.current) {
          if (selectedVoice !== "None") {
            speakCurrentSlide();
          } else {
            highlightNextToken(0);
          }
        }
      }, 300);
    } else {
      // No more slides: advance to next chapter.
      moveToNextChapter();
      setIsPlaying(false);
      isPlayingRef.current = false;
    }
  }

  function startAutoAdvance() {
    if (!slideTokens || slideTokens.length === 0) return;
    setIsPlaying(true);
    isPlayingRef.current = true;
    if (backgroundMusic !== "None") playBackgroundMusic();
    if (selectedVoice !== "None") {
      speakCurrentSlide();
    } else {
      highlightNextToken(0);
    }
  }

  function pauseAutoAdvance() {
    setIsPlaying(false);
    isPlayingRef.current = false;
    if (autoAdvanceRef.current) clearTimeout(autoAdvanceRef.current);
    window.speechSynthesis.cancel();
    stopBackgroundMusic();
  }

  function togglePlayPause() {
    if (isPlaying) {
      pauseAutoAdvance();
    } else {
      startAutoAdvance();
    }
  }

  // -----------------------------
  // Background Music Functions
  // -----------------------------
  const musicURLs = {
    "Calm": "https://www.bensound.com/bensound-music/bensound-slowmotion.mp3",
    "Uplifting": "https://www.bensound.com/bensound-music/bensound-sunny.mp3",
    "Ambient": "https://www.bensound.com/bensound-music/bensound-relaxing.mp3",
    "Peaceful": "https://www.bensound.com/bensound-music/bensound-tenderness.mp3",
    "Serene": "https://www.bensound.com/bensound-music/bensound-slowmotion.mp3",
    "Chill": "https://www.bensound.com/bensound-music/bensound-slowmotion.mp3",
    "Focus": "https://www.bensound.com/bensound-music/bensound-dubstep.mp3",
    "Relax": "https://www.bensound.com/bensound-music/bensound-relaxing.mp3",
    "Meditative": "https://www.bensound.com/bensound-music/bensound-tenderness.mp3",
    "Dreamy": "https://www.bensound.com/bensound-music/bensound-dreams.mp3",
    "Acoustic": "https://www.bensound.com/bensound-music/bensound-acousticbreeze.mp3",
    "Jazz": "https://www.bensound.com/bensound-music/bensound-thejazzpiano.mp3",
    "Nature": "https://www.bensound.com/bensound-music/bensound-betterdays.mp3",
    "Piano": "https://www.bensound.com/bensound-music/bensound-sweet.mp3",
    "Lo-Fi": "https://www.bensound.com/bensound-music/bensound-slowmotion.mp3",
    "Electronic": "https://www.bensound.com/bensound-music/bensound-dubstep.mp3",
    "Upbeat": "https://www.bensound.com/bensound-music/bensound-ukulele.mp3",
    "Epic": "https://www.bensound.com/bensound-music/bensound-epic.mp3",
    "Cinematic": "https://www.bensound.com/bensound-music/bensound-cinematic.mp3",
    "Smooth": "https://www.bensound.com/bensound-music/bensound-smoothjazz.mp3",
    "Soft": "https://www.bensound.com/bensound-music/bensound-slowmotion.mp3"
  };

  function playBackgroundMusic() {
    if (backgroundMusic === "None") return;
    const url = musicURLs[backgroundMusic];
    if (url) {
      const audio = new Audio(url);
      audio.loop = true;
      audio.volume = 0.3;
      bgMusicRef.current = audio;
      audio.play().catch(err => console.error("BG music play error:", err));
    }
  }

  function stopBackgroundMusic() {
    if (bgMusicRef.current) {
      bgMusicRef.current.pause();
      bgMusicRef.current = null;
    }
  }

  // -----------------------------
  // Navigation Functions
  // -----------------------------
  function handleNext() {
    pauseAutoAdvance();
    if ((currentVersePage + 1) * versesPerPage < currentVerses.length) {
      setCurrentVersePage(prev => prev + 1);
    } else {
      moveToNextChapter();
    }
  }

  function handlePrev() {
    pauseAutoAdvance();
    if (currentVersePage > 0) {
      setCurrentVersePage(prev => prev - 1);
    } else {
      moveToPrevChapter();
    }
  }

  function moveToNextChapter() {
    pauseAutoAdvance();
    const totalChapters = books[currentBookIndex].chapters;
    if (currentChapter < totalChapters) {
      setCurrentChapter(c => c + 1);
    } else if (currentBookIndex < books.length - 1) {
      setCurrentBookIndex(currentBookIndex + 1);
      setCurrentBook(books[currentBookIndex + 1].name);
      setCurrentChapter(1);
    } else {
      alert('Reached the end of the Bible.');
    }
  }

  function moveToPrevChapter() {
    pauseAutoAdvance();
    if (currentChapter > 1) {
      setCurrentChapter(c => c - 1);
    } else if (currentBookIndex > 0) {
      setCurrentBookIndex(currentBookIndex - 1);
      setCurrentBook(books[currentBookIndex - 1].name);
      setCurrentChapter(books[currentBookIndex - 1].chapters);
    } else {
      alert('You are at the beginning of the Bible.');
    }
  }

  // -----------------------------
  // Adjustments: WPM & Verses per Slide
  // -----------------------------
  function increaseWPM() {
    setWordsPerMinute(wpm => wpm + 1);
  }

  function decreaseWPM() {
    setWordsPerMinute(wpm => (wpm > 1 ? wpm - 1 : 1));
  }

  function increaseVersesPerPage() {
    if (versesPerPage < 20) {
      setVersesPerPage(v => v + 1);
      setCurrentVersePage(0);
    }
  }

  function decreaseVersesPerPage() {
    if (versesPerPage > 1) {
      setVersesPerPage(v => v - 1);
      setCurrentVersePage(0);
    }
  }

  function onSliderChange(e) {
    const val = parseInt(e.target.value, 10);
    setVersesPerPage(val);
    setCurrentVersePage(0);
  }

  // -----------------------------
  // Selectors: Book, Chapter, Version, Language
  // -----------------------------
  function handleBookChange(e) {
    pauseAutoAdvance();
    const index = parseInt(e.target.value, 10);
    setCurrentBookIndex(index);
    setCurrentBook(books[index].name);
    setCurrentChapter(1);
  }

  function handleChapterChange(e) {
    pauseAutoAdvance();
    const ch = parseInt(e.target.value, 10);
    setCurrentChapter(ch);
  }

  function handleVersionChange(e) {
    pauseAutoAdvance();
    setCurrentVersion(e.target.value);
  }

  function handleLanguageChange(e) {
    pauseAutoAdvance();
    setCurrentLanguage(e.target.value);
  }

  // -----------------------------
  // Searching Functions
  // -----------------------------
  function handleSearchInput(e) {
    const value = e.target.value;
    setSearchQuery(value);
    if (!value) {
      setAutocompleteItems([]);
      return;
    }
    const matches = books.filter(book =>
      book.name.toLowerCase().startsWith(value.toLowerCase())
    );
    setAutocompleteItems(matches);
  }

  function applyAutocompleteItem(bookName) {
    setSearchQuery(bookName);
    setAutocompleteItems([]);
  }

  function handleSearchKeyPress(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (!searchQuery) return;
      parseAndExecuteSearch(searchQuery);
    }
  }

  function parseAndExecuteSearch(query) {
    const parts = query.split(/\s+/);
    if (parts.length === 0) return;
    let foundBookIndex = -1;
    let bookName = '';
    let chapterNumber = null;
    let verseStart = null;
    let verseEnd = null;
    for (let i = parts.length; i > 0; i--) {
      const potentialBook = parts.slice(0, i).join(' ');
      const idx = books.findIndex(b => b.name.toLowerCase() === potentialBook.toLowerCase());
      if (idx !== -1) {
        foundBookIndex = idx;
        bookName = books[idx].name;
        break;
      }
    }
    if (foundBookIndex === -1) {
      foundBookIndex = currentBookIndex;
      bookName = currentBook;
    }
    const remainingParts = parts.slice(bookName.split(' ').length);
    if (remainingParts.length >= 1) {
      const c = parseInt(remainingParts[0], 10);
      if (!isNaN(c) && c >= 1 && c <= books[foundBookIndex].chapters) {
        chapterNumber = c;
      } else {
        alert('Invalid chapter number.');
        return;
      }
    }
    if (remainingParts.length >= 2) {
      const versePart = remainingParts[1];
      const verseRange = versePart.split('-');
      verseStart = parseInt(verseRange[0], 10);
      if (isNaN(verseStart) || verseStart < 1) {
        alert('Invalid start verse.');
        return;
      }
      if (verseRange.length === 2) {
        verseEnd = parseInt(verseRange[1], 10);
        if (isNaN(verseEnd) || verseEnd < verseStart) {
          alert('Invalid end verse.');
          return;
        }
      }
    }
    pauseAutoAdvance();
    setCurrentBookIndex(foundBookIndex);
    setCurrentBook(books[foundBookIndex].name);
    setCurrentChapter(chapterNumber !== null ? chapterNumber : 1);
    setTimeout(() => {
      if (verseStart !== null) {
        jumpToVerse(verseStart, verseEnd);
      }
    }, 800);
    setAutocompleteItems([]);
  }

  function jumpToVerse(verseStart, verseEnd = null) {
    if (!currentVerses || currentVerses.length === 0) return;
    const startIndex = currentVerses.findIndex(v => v.verse === verseStart);
    if (startIndex === -1) {
      alert('Starting verse not found.');
      return;
    }
    if (verseEnd !== null) {
      const endIndex = currentVerses.findIndex(v => v.verse === verseEnd);
      if (endIndex === -1) {
        alert('Ending verse not found.');
        return;
      }
      const rangeSize = endIndex - startIndex + 1;
      setVersesPerPage(rangeSize);
      setCurrentVersePage(Math.floor(startIndex / rangeSize));
    } else {
      setCurrentVersePage(Math.floor(startIndex / versesPerPage));
    }
  }

  const bookSelectionState = useRef({ lastLetter: '', pressCount: 0 });
  function handleBookSelectionByLetter(letter) {
    const matching = books.filter(b => b.name.toLowerCase().startsWith(letter));
    if (matching.length === 0) return;
    if (bookSelectionState.current.lastLetter !== letter) {
      bookSelectionState.current.lastLetter = letter;
      bookSelectionState.current.pressCount = 0;
    } else {
      bookSelectionState.current.pressCount = (bookSelectionState.current.pressCount + 1) % matching.length;
    }
    const selectedBook = matching[bookSelectionState.current.pressCount];
    const idx = books.findIndex(b => b.name === selectedBook.name);
    if (idx !== -1) {
      pauseAutoAdvance();
      setCurrentBookIndex(idx);
      setCurrentBook(selectedBook.name);
      setCurrentChapter(1);
    }
  }

  // -----------------------------
  // Help Modal & URL Sharing
  // -----------------------------
  function openHelpModal() { setShowHelp(true); }
  function closeHelpModal() { setShowHelp(false); }
  function generateShareURL() {
    const params = new URLSearchParams();
    params.set('book', currentBookIndex);
    params.set('chapter', currentChapter);
    params.set('version', currentVersion);
    params.set('language', currentLanguage);
    params.set('verses', getVerseInputValue());
    params.set('page', currentVersePage);
    params.set('lines', versesPerPage);
    params.set('wpm', wordsPerMinute);
    const base = window.location.origin + window.location.pathname;
    return base + '?' + params.toString();
  }
  function updateURL() {
    const newUrl = generateShareURL();
    window.history.replaceState({}, '', newUrl);
  }
  function copyToClipboard(text) {
    navigator.clipboard.writeText(text).catch(err => console.error('Failed to copy: ', err));
  }
  function handleShare() {
    const url = generateShareURL();
    copyToClipboard(url);
    setShowShareTooltip(true);
    setTimeout(() => setShowShareTooltip(false), 2000);
  }
  function handleURLParameters() {
    const params = new URLSearchParams(window.location.search);
    let bookIndex = params.get('book');
    let chapterParam = params.get('chapter');
    let versionParam = params.get('version');
    let languageParam = params.get('language');
    let linesParam = params.get('lines');
    let wpmParam = params.get('wpm');
    let pageParam = params.get('page');
    if (bookIndex !== null) {
      bookIndex = parseInt(bookIndex, 10);
      if (!isNaN(bookIndex) && bookIndex >= 0 && bookIndex < books.length) {
        setCurrentBookIndex(bookIndex);
        setCurrentBook(books[bookIndex].name);
      }
    }
    if (chapterParam !== null) {
      const c = parseInt(chapterParam, 10);
      if (!isNaN(c)) setCurrentChapter(c);
    }
    if (versionParam !== null) setCurrentVersion(versionParam);
    if (languageParam !== null) setCurrentLanguage(languageParam);
    if (linesParam !== null) {
      const l = parseInt(linesParam, 10);
      if (!isNaN(l) && l >= 1 && l <= 20) setVersesPerPage(l);
    }
    if (wpmParam !== null) {
      const w = parseInt(wpmParam, 10);
      if (!isNaN(w) && w >= 1) setWordsPerMinute(w);
    }
    if (pageParam !== null) {
      const p = parseInt(pageParam, 10);
      if (!isNaN(p) && p >= 0) setCurrentVersePage(p);
    }
  }

  // -----------------------------
  // Controls Visibility
  // -----------------------------
  function toggleControlsVisibility() {
    setControlsHidden(h => !h);
  }

  // -----------------------------
  // Render
  // -----------------------------
  return (
    <div className="bible-reader-root" onContextMenu={(e) => { e.preventDefault(); return false; }}>
      <div id="bible-container">

      <SEO
        title="Bible Reader - Global Christian Community"
        description="Read and listen to the Bible with our interactive Bible Reader. Choose your translation, language, and more."
        keywords="Bible reader, Bible study, Bible, Christian, Global Christian Community"
        url="https://globalchristiancommunity.com/bible"
        image="https://globalchristiancommunity.com/images/og-bible-reader.jpg"
      />
        {/* Title */}
        <div id="title">
          {currentBook} {currentChapter},{" "}
          {currentVerses.length > 0 ? <>Verses {getVerseInputValue() || "1"}</> : "Loading..."}
        </div>

        {/* Controls */}
        <div id="controls" className={controlsHidden ? 'hidden' : ''}>
          <div className="controls-group">
            {/* Search Input with Autocomplete */}
            <div style={{ position: 'relative', width: '200px' }}>
              <input
                type="text"
                id="search-input"
                placeholder="Search Book Chapter Verse"
                autoComplete="off"
                value={searchQuery}
                onChange={handleSearchInput}
                onKeyPress={handleSearchKeyPress}
              />
              {autocompleteItems.length > 0 && (
                <div id="autocomplete-list" className="autocomplete-items">
                  {autocompleteItems.map((item, idx) => (
                    <div
                      key={idx}
                      className="autocomplete-item"
                      onClick={() => applyAutocompleteItem(item.name)}
                    >
                      <strong>{item.name.substring(0, searchQuery.length)}</strong>
                      {item.name.substring(searchQuery.length)}
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Language Selector */}
            <select id="language-selector" value={currentLanguage} onChange={handleLanguageChange}>
              <option value="en">English</option>
              <option value="ta">Tamil</option>
              <option value="te">Telugu</option>
              <option value="hi">Hindi</option>
              <option value="ml">Malayalam</option>
            </select>

            {/* Version Selector */}
            <select id="version-selector" value={currentVersion} onChange={handleVersionChange}>
              <option value="KJV">KJV</option>
              <option value="NIV">NIV</option>
              <option value="ESV">ESV</option>
              <option value="WEB">World Bible (WEB)</option>
            </select>

            {/* Book Selector */}
            <select id="book-selector" value={currentBookIndex} onChange={handleBookChange}>
              {books.map((b, i) => (
                <option key={b.name} value={i}>{b.name}</option>
              ))}
            </select>

            {/* Chapter Selector */}
            <select id="chapter-selector" value={currentChapter} onChange={handleChapterChange}>
              {Array.from({ length: books[currentBookIndex].chapters }, (_, i) => i + 1)
                .map(ch => <option key={ch} value={ch}>{ch}</option>)}
            </select>

            {/* Display Mode Selector */}
            <select id="display-mode-selector" defaultValue="slide">
              <option value="slide">Slide Mode</option>
            </select>

            {/* Voice Selection Dropdown */}
            <select id="voice-selector" value={selectedVoice} onChange={(e) => setSelectedVoice(e.target.value)}>
              <option value="None">Voice: None (TTS Off)</option>
              {availableVoices.map((voice, idx) => (
                <option key={idx} value={voice.name}>{voice.name} ({voice.lang})</option>
              ))}
            </select>

            {/* Background Music Dropdown */}
            <select id="bg-music-selector" value={backgroundMusic} onChange={(e) => setBackgroundMusic(e.target.value)}>
              <option value="None">Background Music: None</option>
              <option value="Calm">Calm</option>
              <option value="Uplifting">Uplifting</option>
              <option value="Ambient">Ambient</option>
              <option value="Peaceful">Peaceful</option>
              <option value="Serene">Serene</option>
              <option value="Chill">Chill</option>
              <option value="Focus">Focus</option>
              <option value="Relax">Relax</option>
              <option value="Meditative">Meditative</option>
              <option value="Dreamy">Dreamy</option>
              <option value="Acoustic">Acoustic</option>
              <option value="Jazz">Jazz</option>
              <option value="Nature">Nature</option>
              <option value="Piano">Piano</option>
              <option value="Lo-Fi">Lo-Fi</option>
              <option value="Electronic">Electronic</option>
              <option value="Upbeat">Upbeat</option>
              <option value="Epic">Epic</option>
              <option value="Cinematic">Cinematic</option>
              <option value="Smooth">Smooth</option>
              <option value="Soft">Soft</option>
            </select>

            {/* Share Button with Tooltip */}
            <div className="tooltip">
              <button id="share-btn" onClick={handleShare}>Share</button>
              <span className="tooltiptext" id="share-tooltip" style={{ visibility: showShareTooltip ? 'visible' : 'hidden', opacity: showShareTooltip ? '1' : '0' }}>
                URL Copied!
              </span>
            </div>

            {/* Help Button */}
            <button id="help-btn" onClick={openHelpModal}>?</button>
          </div>

          <div className="controls-group">
            <input
              type="text"
              id="verse-input"
              placeholder="Verse(s) (e.g., 1)"
              value={getVerseInputValue()}
              readOnly
              style={{ cursor: 'default' }}
            />

            <button id="play-pause-btn" onClick={togglePlayPause}>
              {isPlaying ? '⏸' : '▶'}
            </button>

            <span id="playback-rate-display">{wordsPerMinute} WPM</span>

            {/* Slider for Verses per Slide */}
            <div id="slider-container">
              <label htmlFor="lines-slider">Lines per Slide:</label>
              <input type="range" id="lines-slider" min="1" max="20" value={versesPerPage} onChange={onSliderChange} />
              <span id="slider-value">{versesPerPage}</span>
            </div>
          </div>
        </div>

        {/* Content Box */}
        <div id="content-box">
          <div id="verses-display" className="responsive-text">
            {slideTokens.length === 0 ? 'Loading...' : renderHighlightedSlide()}
          </div>
        </div>

        {/* Navigation Buttons */}
        <button id="prev-btn" className="nav-button" onClick={handlePrev}>◀</button>
        <button id="next-btn" className="nav-button" onClick={handleNext}>▶</button>

        {/* Help Modal */}
        {showHelp && (
          <div id="help-modal" style={{ display: 'block' }}>
            <div id="help-modal-content">
              <span id="close-help" onClick={closeHelpModal}>&times;</span>
              <h2>Help &amp; Keyboard Shortcuts</h2>
              <p>
                Welcome to the Bible Reader application! Use the following shortcuts:
              </p>
              <ul>
                <li><strong>Ctrl:</strong> Toggle controls</li>
                <li><strong>Space:</strong> Play/Pause</li>
                <li><strong>Arrow keys/PageUp/Down:</strong> Navigate slides/chapters</li>
                <li><strong>+/-:</strong> Adjust verses per slide</li>
                <li><strong>Numbers/Letters:</strong> Jump to verses or cycle through books</li>
                <li><strong>?:</strong> Show/Hide this help</li>
              </ul>
              <p>Enjoy your reading!</p>
            </div>
          </div>
        )}

        {/* Hidden Audio Player */}
        <audio id="audio-player" ref={audioRef}></audio>
      </div>
    </div>
  );
}
