// src/components/Forums.jsx
import React from "react";
import { FaComments, FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Forums.css";
import SEO from './SEO';


function Forums() {
  return (
    <div className="forums-container">
      
      {/* Header Section */}
      <section className="forums-header">
        <h1 className="forums-heading">
          <FaComments className="forums-icon" /> Forums
        </h1>
        <p className="forums-intro">
          Engage with fellow community members, share your thoughts, ask questions, and grow together in faith.
        </p>
      </section>
      
      {/* Embedded Forum */}
      <section className="forum-embed">
        <iframe
          src="https://YOUR_FORUM_URL/embed"
          width="100%"
          height="600"
          frameBorder="0"
          aria-label="Embedded Community Forum"
        ></iframe>
      </section>
      
      {/* Navigation Link Back */}
      <section className="navigation-link-back">
        <Link to="/" className="cta-button" aria-label="Back to Home">
          <FaArrowLeft className="back-icon" /> Back to Home
        </Link>
      </section>
      
    </div>
  );
}

export default Forums;
