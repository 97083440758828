import React, { useState, useEffect, useRef, useCallback } from "react";
import './Worship.css'; // Import your CSS file here
import SEO from './components/SEO';


function LyricsSlideshow() {
  /**
   * 1) All React States
   */
  const [songs, setSongs] = useState([]);
  const [filteredSongs, setFilteredSongs] = useState([]);
  const [currentSong, setCurrentSong] = useState(null);

  // Blogger feed, categories, searching
  const [selectedDomain, setSelectedDomain] = useState("");
  const [organizations, setOrganizations] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categories, setCategories] = useState([]);

  // Current song indices & slides
  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [paragraphSlides, setParagraphSlides] = useState([]);
  const [lineSlides, setLineSlides] = useState([]);
  const [activeSlides, setActiveSlides] = useState([]);
  const [linesPerSlide, setLinesPerSlide] = useState(0);

  // YouTube / Musescore
  const [youtubeLinks, setYoutubeLinks] = useState([]);
  const [selectedYouTubeLink, setSelectedYouTubeLink] = useState(null);
  const [musescoreLink, setMusescoreLink] = useState(null);
  const [isYouTubePlaying, setIsYouTubePlaying] = useState(false);

  // Display & playback
  const [displayMode, setDisplayMode] = useState("slide");
  const [globalFontSize, setGlobalFontSize] = useState(80);
  const [currentPlaybackRate, setCurrentPlaybackRate] = useState(1);
  const playbackRates = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];

  // Controls & tile lighting
  const [controlsVisible, setControlsVisible] = useState(true);
  const [tileLightModeActive, setTileLightModeActive] = useState(false);

  // Auto-advance
  const [autoAdvanceInterval, setAutoAdvanceInterval] = useState(0);
  const autoAdvanceTimerRef = useRef(null);

  // Keep track of “tried” songs to avoid infinite skipping
  const attemptedSongIndicesRef = useRef(new Set());

  // YouTube players (hidden audio and large video)
  const hiddenAudioPlayerRef = useRef(null);
  const videoModePlayerRef = useRef(null);

  // New: track if the user explicitly paused the player.
  const userPausedRef = useRef(false);

  // New: for tracking audio playback progress.
  const [audioProgress, setAudioProgress] = useState(0);
  const [audioDuration, setAudioDuration] = useState(0);
  const audioTimeRef = useRef(0);
  const audioTimerRef = useRef(null);

  // For tile lighting
  const tileLightIntervalRef = useRef(null);

  // Help modal
  const [helpModalOpen, setHelpModalOpen] = useState(false);

  /**
   * 3) Arrow functions and helpers
   */

  // nextSong: load the next song.
  const nextSong = useCallback(() => {
    if (!filteredSongs.length) return;
    if (attemptedSongIndicesRef.current.size >= filteredSongs.length) {
      attemptedSongIndicesRef.current = new Set();
      console.warn("No playable songs found. Stopping playback.");
      setIsYouTubePlaying(false);
      return;
    }
    let nextIndex = currentSongIndex + 1;
    if (nextIndex >= filteredSongs.length) nextIndex = 0;
    if (attemptedSongIndicesRef.current.has(nextIndex)) {
      setCurrentSongIndex(nextIndex);
      nextSong();
    } else {
      loadSong(nextIndex);
    }
  }, [filteredSongs, currentSongIndex]);

  // prevSong: load the previous song.
  const prevSong = useCallback(() => {
    if (!filteredSongs.length) return;
    let idx = currentSongIndex - 1;
    if (idx < 0) idx = filteredSongs.length - 1;
    loadSong(idx);
  }, [filteredSongs, currentSongIndex]);

  // toggleControls: show/hide controls.
  const toggleControls = useCallback(() => {
    setControlsVisible((prev) => !prev);
  }, []);

  // createVideoPlayer: initialize or reload the large YouTube player.
  const createVideoPlayer = useCallback(
    (videoID, startSeconds = 0) => {
      if (!window.YT) return;
      if (!videoModePlayerRef.current) {
        videoModePlayerRef.current = new window.YT.Player("video-mode-player", {
          height: "100%",
          width: "100%",
          videoId: videoID,
          events: {
            onReady: () => setIsYouTubePlaying(true),
            onStateChange: (e) => {
              if (e.data === window.YT.PlayerState.PLAYING) {
                setIsYouTubePlaying(true);
              } else if (e.data === window.YT.PlayerState.ENDED) {
                if (userPausedRef.current) {
                  userPausedRef.current = false;
                  setIsYouTubePlaying(false);
                } else {
                  nextSong();
                }
              } else {
                setIsYouTubePlaying(false);
              }
            },
            onError: (err) => {
              console.error("Video player error:", err.data);
              nextSong();
            },
          },
          playerVars: {
            autoplay: 1,
            controls: 1,
            modestbranding: 1,
            rel: 0,
            start: startSeconds,
          },
        });
      } else {
        videoModePlayerRef.current.loadVideoById({ videoId: videoID, startSeconds });
      }
      if (videoModePlayerRef.current && typeof videoModePlayerRef.current.setPlaybackRate === "function") {
        videoModePlayerRef.current.setPlaybackRate(currentPlaybackRate);
      }
    },
    [currentPlaybackRate, nextSong]
  );

  // filterSongs: apply category and search filters.
  const filterSongs = useCallback(() => {
    if (!songs.length) {
      setFilteredSongs([]);
      return;
    }
    let subset = (!selectedCategory || selectedCategory === "All Categories")
      ? songs
      : songs.filter((s) => s.categories.includes(selectedCategory));
    const kw = searchKeyword.toLowerCase();
    const result = subset.filter((song) => {
      const inTitle = song.title.toLowerCase().includes(kw);
      const inLyrics = song.lyrics.some((p) => p.toLowerCase().includes(kw));
      return inTitle || inLyrics;
    });
    setFilteredSongs(result);
  }, [songs, selectedCategory, searchKeyword]);

  // loadSong: stop previous playback and load a new song.
  const loadSong = (index) => {
    if (index < 0 || index >= filteredSongs.length) return;

    // Save whether something was playing.
    const wasPlaying = isYouTubePlaying;

    // Stop any current playback.
    if (hiddenAudioPlayerRef.current) hiddenAudioPlayerRef.current.stopVideo();
    if (videoModePlayerRef.current) videoModePlayerRef.current.stopVideo();

    // Clear audio timer.
    if (audioTimerRef.current) {
      clearInterval(audioTimerRef.current);
      audioTimerRef.current = null;
    }

    setCurrentSongIndex(index);
    setCurrentSlideIndex(0);
    attemptedSongIndicesRef.current = new Set([index]);

    const song = filteredSongs[index];
    setCurrentSong(song);

    const newLink = (song.youtubeUrls && song.youtubeUrls.length > 0)
      ? song.youtubeUrls[0]
      : null;
    setSelectedYouTubeLink(newLink);
    setYoutubeLinks(song.youtubeUrls);
    setMusescoreLink(song.musescoreUrl);
    if (song.youtubeUrls.length > 0) {
      setSelectedYouTubeLink(song.youtubeUrls[0]);
    } else {
      setSelectedYouTubeLink(null);
    }

    // Build slides for lyrics.
    const pSlides = buildParagraphSlides(song.title, song.lyrics);
    const lSlides = buildLineSlides(song.title, song.lyrics, linesPerSlide);
    setParagraphSlides(pSlides);
    setLineSlides(lSlides);
    setActiveSlides(linesPerSlide > 0 ? lSlides : pSlides);

    // For non-video modes, always start new audio; for video mode, only if it was playing.
    if (displayMode === "video") {
      if (wasPlaying) {
        const newVid = extractYouTubeID(song.youtubeUrls[0]);
        if (newVid) createVideoPlayer(newVid);
      }
    } else {
      playAudioLink(newLink);
    }
  };

  // playAudioLink: load and play the hidden audio player.
  const playAudioLink = (link) => {
    if (!link || !hiddenAudioPlayerRef.current) return;
    const vid = extractYouTubeID(link);
    if (vid) {
      hiddenAudioPlayerRef.current.loadVideoById(vid);
      hiddenAudioPlayerRef.current.setPlaybackRate(currentPlaybackRate);
      hiddenAudioPlayerRef.current.playVideo();
      setIsYouTubePlaying(true);
      userPausedRef.current = false;
      // After a delay, set audio duration.
      setTimeout(() => {
        if (hiddenAudioPlayerRef.current && typeof hiddenAudioPlayerRef.current.getDuration === "function") {
          setAudioDuration(hiddenAudioPlayerRef.current.getDuration());
        }
      }, 1000);
    }
  };

  // buildParagraphSlides: create an array with title + paragraphs.
  const buildParagraphSlides = (title, paragraphs) => [title, ...paragraphs];

  // buildLineSlides: create slides with a fixed number of lines.
  const buildLineSlides = (title, paragraphs, linesCount) => {
    if (!linesCount) return [];
    let all = [];
    paragraphs.forEach((p) => {
      if (p.toLowerCase().includes("<img")) {
        all.push(p);
      } else {
        const splitted = p.split("\n").map((s) => s.trim()).filter(Boolean);
        all.push(...splitted);
      }
    });
    const slides = [title];
    for (let i = 0; i < all.length; i += linesCount) {
      slides.push(all.slice(i, i + linesCount).join("\n"));
    }
    return slides;
  };

  // getParagraphsFromContent: extract text and images.
  const getParagraphsFromContent = (el) => {
    let paragraphs = [];
    let current = "";
    function traverse(n) {
      if (n.nodeType === Node.TEXT_NODE) {
        if (n.textContent.trim()) current += n.textContent;
      } else if (n.nodeType === Node.ELEMENT_NODE) {
        if (n.tagName === "BR") {
          current += "\n";
        } else if (n.tagName === "P" || n.tagName === "DIV") {
          if (current.trim()) {
            paragraphs.push(current.trim());
            current = "";
          }
          n.childNodes.forEach(traverse);
          if (current.trim()) {
            paragraphs.push(current.trim());
            current = "";
          }
        } else if (n.tagName === "IMG") {
          const s = n.getAttribute("src");
          if (s)
            paragraphs.push(`<img src="${s}" style="max-width:100%;height:auto;"/>`);
        } else {
          n.childNodes.forEach(traverse);
        }
      }
    }
    el.childNodes.forEach(traverse);
    if (current.trim()) paragraphs.push(current.trim());
    return paragraphs;
  };

  // getCurrentTextOffset: calculate text offset (for slides).
  const getCurrentTextOffset = () => {
    if (!currentSong || currentSlideIndex === 0) return 0;
    if (linesPerSlide > 0 && activeSlides === lineSlides) {
      return (currentSlideIndex - 1) * linesPerSlide;
    } else {
      let off = 0;
      for (let i = 1; i < currentSlideIndex; i++) {
        const sl = paragraphSlides[i];
        if (sl.toLowerCase().includes("<img")) {
          off++;
        } else {
          off += sl.split("\n").filter(Boolean).length || 1;
        }
      }
      return off;
    }
  };

  // offsetToSlideIndex: convert offset to slide index.
  const offsetToSlideIndex = (offset, newLinesPerSlide, pSlides) => {
    if (offset <= 0) return 0;
    if (newLinesPerSlide > 0) {
      return Math.floor(offset / newLinesPerSlide) + 1;
    } else {
      const paragraphs = pSlides.slice(1);
      let sum = 0, idx = 0;
      for (let p of paragraphs) {
        let lineCount = 1;
        if (!p.toLowerCase().includes("<img")) {
          lineCount = p.split("\n").filter(Boolean).length || 1;
        }
        if (sum + lineCount > offset) {
          return 1 + idx;
        }
        sum += lineCount;
        idx++;
      }
      return pSlides.length - 1;
    }
  };

  // rebuildActiveSlidesInPlace: re-calculate slides.
  const rebuildActiveSlidesInPlace = () => {
    if (!currentSong) return;
    const oldOffset = getCurrentTextOffset();
    const pSlides = buildParagraphSlides(currentSong.title, currentSong.lyrics);
    const lSlides = buildLineSlides(currentSong.title, currentSong.lyrics, linesPerSlide);
    setParagraphSlides(pSlides);
    setLineSlides(lSlides);
    const newActive = linesPerSlide > 0 ? lSlides : pSlides;
    setActiveSlides(newActive);
    const newIndex = offsetToSlideIndex(oldOffset, linesPerSlide, pSlides);
    const safe = newIndex >= newActive.length ? newActive.length - 1 : newIndex;
    setCurrentSlideIndex(safe < 0 ? 0 : safe);
  };

  // nextContent: advance slide or go to next song.
  const nextContent = useCallback(() => {
    if (autoAdvanceTimerRef.current) {
      clearInterval(autoAdvanceTimerRef.current);
      autoAdvanceTimerRef.current = null;
    }
    if (displayMode === "slide") {
      if (currentSlideIndex < activeSlides.length - 1) {
        setCurrentSlideIndex((p) => p + 1);
      } else {
        nextSong();
      }
      if (autoAdvanceInterval > 0) startAutoAdvance(autoAdvanceInterval);
    } else if (displayMode === "music") {
      if (currentSongIndex + 2 < filteredSongs.length) {
        loadSong(currentSongIndex + 2);
      }
    } else if (displayMode === "presenter") {
      if (currentSlideIndex < activeSlides.length - 1) {
        setCurrentSlideIndex((p) => p + 1);
      } else {
        nextSong();
      }
      if (autoAdvanceInterval > 0) startAutoAdvance(autoAdvanceInterval);
    } else {
      nextSong();
    }
  }, [displayMode, currentSlideIndex, activeSlides, nextSong, autoAdvanceInterval, currentSongIndex, filteredSongs]);

  // prevContent: go back one slide or previous song.
  const prevContent = useCallback(() => {
    if (displayMode === "slide") {
      if (currentSlideIndex > 0) {
        setCurrentSlideIndex((p) => p - 1);
      } else {
        prevSong();
      }
    } else if (displayMode === "music") {
      if (currentSongIndex - 2 >= 0) {
        loadSong(currentSongIndex - 2);
      }
    } else if (displayMode === "presenter") {
      if (currentSlideIndex > 0) {
        setCurrentSlideIndex((p) => p - 1);
      } else {
        prevSong();
      }
    } else {
      prevSong();
    }
  }, [displayMode, currentSlideIndex, activeSlides, prevSong, currentSongIndex, filteredSongs]);

  // startAutoAdvance: start auto-advance timer.
  const startAutoAdvance = (sec) => {
    if (autoAdvanceTimerRef.current) clearInterval(autoAdvanceTimerRef.current);
    autoAdvanceTimerRef.current = setInterval(() => {
      nextContent();
    }, sec * 1000);
  };

  // stopAutoAdvance: clear auto-advance timer.
  const stopAutoAdvance = () => {
    if (autoAdvanceTimerRef.current) {
      clearInterval(autoAdvanceTimerRef.current);
      autoAdvanceTimerRef.current = null;
    }
  };

  // changePlaybackRate: adjust playback speed.
  const changePlaybackRate = (dir) => {
    let idx = playbackRates.indexOf(currentPlaybackRate);
    if (idx < 0) {
      setCurrentPlaybackRate(1);
      idx = playbackRates.indexOf(1);
    }
    const newI = idx + dir;
    if (newI >= 0 && newI < playbackRates.length) {
      setCurrentPlaybackRate(playbackRates[newI]);
    }
  };

  // onAudioPlayerStateChange: update audio progress for hidden audio.
  function onAudioPlayerStateChange(e) {
    if (e.data === window.YT.PlayerState.PLAYING) {
      setIsYouTubePlaying(true);
      if (!audioTimerRef.current && hiddenAudioPlayerRef.current) {
        audioTimerRef.current = setInterval(() => {
          const currentTime = hiddenAudioPlayerRef.current.getCurrentTime();
          audioTimeRef.current = currentTime;
          setAudioProgress(currentTime);
        }, 500);
      }
    } else if (e.data === window.YT.PlayerState.ENDED) {
      clearInterval(audioTimerRef.current);
      audioTimerRef.current = null;
      if (userPausedRef.current) {
        userPausedRef.current = false;
        setIsYouTubePlaying(false);
      } else {
        nextSong();
      }
    } else {
      clearInterval(audioTimerRef.current);
      audioTimerRef.current = null;
      setIsYouTubePlaying(false);
    }
  }
  function onAudioPlayerError() {
    console.error("Audio error");
    nextSong();
  }

  // extractYouTubeID: extract the 11-char ID from a URL.
  const extractYouTubeID = (url) => {
    if (!url) return null;
    let id = null;
    if (url.includes("youtu.be/")) {
      id = url.split("youtu.be/")[1].split(/[?&]/)[0];
    } else if (url.includes("youtube.com/watch?v=")) {
      id = url.split("watch?v=")[1].split("&")[0];
    } else if (url.includes("youtube.com/embed/")) {
      id = url.split("embed/")[1].split(/[?&]/)[0];
    } else if (url.includes("youtube.com/v/")) {
      id = url.split("v/")[1].split(/[?&]/)[0];
    }
    return id && id.length === 11 ? id : null;
  };

  // extractMusescoreEmbedURL: return an embed URL.
  const extractMusescoreEmbedURL = (link) => {
    if (!link) return null;
    try {
      const u = new URL(link);
      return u.origin + u.pathname + "/embed";
    } catch (e) {
      console.warn("Invalid musescore link:", link);
      return null;
    }
  };

  // playYouTubeAudio: play hidden audio.
  const lastLoadedAudioRef = useRef(null);
  const playYouTubeAudio = () => {
    userPausedRef.current = false;
    if (!selectedYouTubeLink) {
      console.warn("No YT link available.");
      nextSong();
      return;
    }
    const vid = extractYouTubeID(selectedYouTubeLink);
    if (!vid || !hiddenAudioPlayerRef.current) {
      console.warn("Invalid YT ID or missing hidden player.");
      nextSong();
      return;
    }
    if (lastLoadedAudioRef.current !== vid) {
      hiddenAudioPlayerRef.current.loadVideoById(vid);
      lastLoadedAudioRef.current = vid;
    }
    hiddenAudioPlayerRef.current.setPlaybackRate(currentPlaybackRate);
    hiddenAudioPlayerRef.current.playVideo();
    setIsYouTubePlaying(true);
  };

  // pauseYouTubeAudio: pause hidden audio.
  const pauseYouTubeAudio = () => {
    if (hiddenAudioPlayerRef.current) {
      hiddenAudioPlayerRef.current.pauseVideo();
      userPausedRef.current = true;
    }
  };

  // pauseVideoModePlayer: pause large video.
  const pauseVideoModePlayer = () => {
    if (videoModePlayerRef.current) {
      videoModePlayerRef.current.pauseVideo();
      userPausedRef.current = true;
    }
  };

  // togglePlayPause: toggle play/pause.
  const togglePlayPause = () => {
    if (displayMode === "video") {
      if (isYouTubePlaying) {
        pauseVideoModePlayer();
      } else if (selectedYouTubeLink) {
        const vid = extractYouTubeID(selectedYouTubeLink);
        if (vid && videoModePlayerRef.current) {
          userPausedRef.current = false;
          videoModePlayerRef.current.playVideo();
        }
      }
    } else {
      if (isYouTubePlaying) pauseYouTubeAudio();
      else playYouTubeAudio();
    }
  };

  // showPresenterModeCurrentNext: get current and next slide texts.
  const showPresenterModeCurrentNext = () => {
    const c = activeSlides[currentSlideIndex] || "";
    const nI = currentSlideIndex + 1;
    const n = nI < activeSlides.length ? activeSlides[nI] : "<i>No next</i>";
    return { current: c, next: n };
  };

  // handleAudioSeek: seek when dragging progress bar.
  const handleAudioSeek = (e) => {
    const newTime = parseFloat(e.target.value);
    if (hiddenAudioPlayerRef.current && typeof hiddenAudioPlayerRef.current.seekTo === "function") {
      hiddenAudioPlayerRef.current.seekTo(newTime, true);
      audioTimeRef.current = newTime;
      setAudioProgress(newTime);
    }
  };

  // formatTime: format seconds to mm:ss.
  const formatTime = (sec) => {
    const minutes = Math.floor(sec / 60);
    const seconds = Math.floor(sec % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  /**
   * 4) useEffects
   */

  // Insert YouTube Iframe API and create hidden audio player.
  useEffect(() => {
    if (!document.getElementById("youtube-iframe-script")) {
      const tag = document.createElement("script");
      tag.id = "youtube-iframe-script";
      tag.src = "https://www.youtube.com/iframe_api";
      document.body.appendChild(tag);
    }
    window.onYouTubeIframeAPIReady = () => {
      hiddenAudioPlayerRef.current = new window.YT.Player("hidden-audio-player", {
        height: "0",
        width: "0",
        videoId: "",
        playerVars: {
          autoplay: 0,
          controls: 0,
          mute: 0,
          showinfo: 0,
          rel: 0,
        },
        events: {
          onReady: () => {
            if (hiddenAudioPlayerRef.current && typeof hiddenAudioPlayerRef.current.getDuration === "function") {
              setAudioDuration(hiddenAudioPlayerRef.current.getDuration());
            }
          },
          onStateChange: onAudioPlayerStateChange,
          onError: onAudioPlayerError,
        },
      });
    };
  }, []);

  // Load organizations feed.
  useEffect(() => {
    window.populateBlogspotSelector = (data) => {
      if (!data.feed || !data.feed.entry) {
        setOrganizations([]);
        return;
      }
      const entries = data.feed.entry;
      const orgs = entries.map((entry) => {
        const title = entry.title.$t;
        const domain = title.toLowerCase().replace(/\s+/g, "-") + ".blogspot.com";
        return { title, domain };
      });
      setOrganizations(orgs);
      if (orgs.length > 0) {
        setSelectedDomain(orgs[0].domain);
      }
    };
    const scriptId = "gcc-organizations-feed";
    let old = document.getElementById(scriptId);
    if (old) document.body.removeChild(old);
    const sc = document.createElement("script");
    sc.id = scriptId;
    sc.src = `https://gcc-organizations.blogspot.com/feeds/posts/default?alt=json-in-script&max-results=500&callback=populateBlogspotSelector`;
    document.body.appendChild(sc);
    return () => {
      window.populateBlogspotSelector = null;
      const s = document.getElementById(scriptId);
      if (s) document.body.removeChild(s);
    };
  }, []);

  // Load selected blog feed (songs).
  useEffect(() => {
    if (!selectedDomain) return;
    setSongs([]);
    setFilteredSongs([]);
    setCurrentSong(null);
    setCurrentSongIndex(0);
    setCurrentSlideIndex(0);
    window.handleResponse = (data) => {
      if (!data.feed || !data.feed.entry) {
        setSongs([]);
        return;
      }
      const catSet = new Set();
      const loaded = data.feed.entry.map((entry) => {
        const title = entry.title.$t;
        const content = entry.content.$t;
        const categories = entry.category ? entry.category.map((c) => c.term) : [];
        categories.forEach((c) => catSet.add(c));
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = content;
        let yts = [];
        [...tempDiv.getElementsByTagName("a")].forEach((lnk) => {
          if (lnk.href.includes("youtube") || lnk.href.includes("youtu.be"))
            yts.push(lnk.href);
        });
        [...tempDiv.getElementsByTagName("iframe")].forEach((fr) => {
          if (fr.src.includes("youtube") || fr.src.includes("youtu.be"))
            yts.push(fr.src);
        });
        const ytre = /(https?:\/\/(?:www\.)?(?:youtube\.com|youtu\.be)\/[^\s"'<>]+)/gi;
        let mch;
        while ((mch = ytre.exec(content)) !== null) {
          yts.push(mch[0]);
        }
        yts = [...new Set(yts)];
        let musescoreUrl = null;
        [...tempDiv.getElementsByTagName("a")].forEach((a) => {
          if (a.href.includes("musescore.com")) musescoreUrl = a.href;
        });
        if (!musescoreUrl) {
          const msr = /(https?:\/\/(?:www\.)?musescore\.com\/[^\s"'<>]+)/gi;
          const arr = content.match(msr);
          if (arr && arr.length > 0) musescoreUrl = arr[0];
        }
        const paragraphs = getParagraphsFromContent(tempDiv);
        return {
          title,
          categories,
          isEnglish: categories.includes("English"),
          youtubeUrls: yts,
          musescoreUrl,
          lyrics: paragraphs,
        };
      });
      setSongs(loaded);
      setCategories(["All Categories", ...Array.from(catSet)]);
      setSearchKeyword("");
      setSelectedCategory("");
    };
    const scriptId = "blogspot-feed-script";
    let oldScript = document.getElementById(scriptId);
    if (oldScript) document.body.removeChild(oldScript);
    const s = document.createElement("script");
    s.id = scriptId;
    s.src = `https://${selectedDomain}/feeds/posts/default?alt=json-in-script&max-results=500&callback=handleResponse`;
    document.body.appendChild(s);
    return () => {
      window.handleResponse = null;
      const sc = document.getElementById(scriptId);
      if (sc) document.body.removeChild(sc);
    };
  }, [selectedDomain]);

  // Filter songs whenever songs/category/search change.
  useEffect(() => {
    filterSongs();
  }, [songs, selectedCategory, searchKeyword, filterSongs]);

  // Keyboard shortcuts (including "G" for tiles).
  useEffect(() => {
    const handleKey = (e) => {
      const ae = document.activeElement;
      if (ae && (ae.tagName === "INPUT" || ae.tagName === "TEXTAREA")) return;
      if (e.key === "ArrowRight" || e.key === "PageDown") {
        e.preventDefault();
        nextContent();
      } else if (e.key === "ArrowLeft" || e.key === "PageUp") {
        e.preventDefault();
        prevContent();
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        prevSong();
      } else if (e.key === "ArrowDown") {
        e.preventDefault();
        nextSong();
      } else if (e.key === " ") {
        e.preventDefault();
        togglePlayPause();
      } else if (e.key.toLowerCase() === "g") {
        // "G" shows tiles (back to catalog)
        setCurrentSong(null);
        setParagraphSlides([]);
        setLineSlides([]);
        setActiveSlides([]);
      } else if (e.key.toLowerCase() === "control" || e.key.toLowerCase() === "h") {
        toggleControls();
      } else if (e.key === ">" || e.key === ".") {
        changePlaybackRate(1);
      } else if (e.key === "<" || e.key === ",") {
        changePlaybackRate(-1);
      } else if (e.key >= "1" && e.key <= "9") {
        const n = parseInt(e.key) - 1;
        if (youtubeLinks.length > 0) {
          const safe = Math.min(n, youtubeLinks.length - 1);
          setSelectedYouTubeLink(youtubeLinks[safe]);
          if (isYouTubePlaying) {
            playYouTubeAudio();
          } else if (displayMode === "video") {
            const vid = extractYouTubeID(youtubeLinks[safe]);
            if (vid) createVideoPlayer(vid);
          }
        }
      } else if (e.key.toLowerCase() === "s") {
        setDisplayMode("slide");
      } else if (e.key.toLowerCase() === "f") {
        setDisplayMode("entire");
      } else if (e.key.toLowerCase() === "v") {
        setDisplayMode("video");
      } else if (e.key.toLowerCase() === "m") {
        setDisplayMode("music");
      } else if (e.key.toLowerCase() === "p") {
        setDisplayMode("presenter");
      } else if (e.key === "+") {
        setGlobalFontSize((p) => Math.min(p + 1, 150));
      } else if (e.key === "-") {
        setGlobalFontSize((p) => Math.max(p - 1, 10));
      } else if (e.key === "]") {
        setLinesPerSlide((p) => Math.min(p + 1, 25));
      } else if (e.key === "[") {
        setLinesPerSlide((p) => Math.max(p - 1, 0));
      } else if (e.key === "*") {
        setAutoAdvanceInterval((p) => Math.min(p + 1, 120));
      } else if (e.key === "/") {
        setAutoAdvanceInterval((p) => Math.max(p - 1, 0));
      } else if (e.key.toLowerCase() === "t") {
        document.getElementById("display-mode-selector")?.focus();
      } else if (e.key.toLowerCase() === "o") {
        document.getElementById("blogspot-selector")?.focus();
      } else if (e.key.toLowerCase() === "b") {
        document.getElementById("category-selector")?.focus();
      } else if (e.key.toLowerCase() === "i") {
        document.getElementById("song-selector")?.focus();
      } else if (e.key.toLowerCase() === "e") {
        document.getElementById("search-input")?.focus();
      } else if (e.key.toLowerCase() === "d") {
        document.getElementById("lyrics")?.focus();
      }
    };
    window.addEventListener("keydown", handleKey);
    return () => window.removeEventListener("keydown", handleKey);
  }, [
    nextContent,
    prevContent,
    prevSong,
    nextSong,
    togglePlayPause,
    toggleControls,
    changePlaybackRate,
    youtubeLinks,
    isYouTubePlaying,
    displayMode,
    createVideoPlayer,
  ]);

  // Rebuild slides if linesPerSlide or currentSong changes.
  useEffect(() => {
    rebuildActiveSlidesInPlace();
  }, [linesPerSlide, currentSong]);

  // Auto-advance timer.
  useEffect(() => {
    if (autoAdvanceInterval > 0) {
      startAutoAdvance(autoAdvanceInterval);
    } else {
      stopAutoAdvance();
    }
    return () => stopAutoAdvance();
  }, [autoAdvanceInterval, nextContent]);

  // When switching display modes, transfer playback time.
  useEffect(() => {
    if (displayMode === "video") {
      const startSeconds = audioTimeRef.current || 0;
      if (isYouTubePlaying && hiddenAudioPlayerRef.current) {
        pauseYouTubeAudio();
      }
      const videoID = extractYouTubeID(selectedYouTubeLink);
      if (selectedYouTubeLink && videoID) {
        createVideoPlayer(videoID, startSeconds);
      }
    } else {
      if (
        displayMode !== "video" &&
        videoModePlayerRef.current &&
        isYouTubePlaying
      ) {
        const time = videoModePlayerRef.current.getCurrentTime() || 0;
        pauseVideoModePlayer();
        const data = videoModePlayerRef.current.getVideoData();
        const vid = data && data.video_id;
        if (vid && hiddenAudioPlayerRef.current) {
          hiddenAudioPlayerRef.current.loadVideoById({ videoId: vid, startSeconds: time });
          hiddenAudioPlayerRef.current.setPlaybackRate(currentPlaybackRate);
          hiddenAudioPlayerRef.current.playVideo();
          setIsYouTubePlaying(true);
        }
      } else {
        pauseVideoModePlayer();
      }
    }
  }, [displayMode, selectedYouTubeLink, createVideoPlayer]);

  // Update playback rate for both players.
  useEffect(() => {
    if (displayMode === "video" && videoModePlayerRef.current) {
      if (typeof videoModePlayerRef.current.setPlaybackRate === "function") {
        videoModePlayerRef.current.setPlaybackRate(currentPlaybackRate);
      } else {
        console.warn("videoModePlayerRef is not a valid YT.Player yet.");
      }
    }
    if (hiddenAudioPlayerRef.current) {
      if (typeof hiddenAudioPlayerRef.current.setPlaybackRate === "function") {
        hiddenAudioPlayerRef.current.setPlaybackRate(currentPlaybackRate);
      } else {
        console.warn("hiddenAudioPlayerRef is not a valid YT.Player yet.");
      }
    }
  }, [currentPlaybackRate, displayMode]);

  // On window resize, adjust font size.
  const getMaxFontSize = () => {
    const w = window.innerWidth;
    if (w <= 400) return 30;
    if (w <= 600) return 40;
    if (w <= 1024) return 60;
    return 80;
  };
  useEffect(() => {
    const handleResize = () => {
      const maxF = getMaxFontSize();
      if (globalFontSize > maxF) setGlobalFontSize(maxF);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [globalFontSize]);

  /**
   * 5) Tile Light Mode (unchanged)
   */
  const vibrantColors = [
    "#FF5733",
    "#33FF57",
    "#3357FF",
    "#F1C40F",
    "#8E44AD",
    "#E67E22",
    "#1ABC9C",
    "#E74C3C",
    "#2ECC71",
    "#3498DB",
  ];
  const getRandomInt = (mn, mx) => Math.floor(Math.random() * (mx - mn + 1)) + mn;
  const changeTileColor = (tile) => {
    const oldBG = tile.style.backgroundColor || "white";
    const oldTX = tile.style.color || "black";
    const newC = vibrantColors[getRandomInt(0, vibrantColors.length - 1)];
    tile.style.backgroundColor = newC;
    tile.style.color = "white";
    setTimeout(() => {
      tile.style.backgroundColor = oldBG;
      tile.style.color = oldTX;
    }, 1000);
  };
  const randomizeTileColors = () => {
    const tiles = document.querySelectorAll(".tile");
    if (!tiles.length) return;
    const num = getRandomInt(1, Math.min(tiles.length, 5));
    for (let i = 0; i < num; i++) {
      const rnd = getRandomInt(0, tiles.length - 1);
      changeTileColor(tiles[rnd]);
    }
  };
  const initTileLighting = () => {
    if (tileLightModeActive) return;
    setTileLightModeActive(true);
    tileLightIntervalRef.current = setInterval(randomizeTileColors, 1000);
  };
  const deactivateTileLightMode = () => {
    if (!tileLightModeActive) return;
    clearInterval(tileLightIntervalRef.current);
    tileLightIntervalRef.current = null;
    setTileLightModeActive(false);
  };
  const handleToggleTileLight = () => {
    if (!tileLightModeActive) initTileLighting();
    else deactivateTileLightMode();
  };

  /**
   * 6) Render
   */

  // getSlideContent: return current slide content.
  const getSlideContent = () => {
    if (!activeSlides || !activeSlides.length) return "";
    if (currentSlideIndex < 0) return "";
    if (currentSlideIndex >= activeSlides.length) {
      nextSong();
      return "";
    }
    return activeSlides[currentSlideIndex];
  };

  const isTitleSlide = () => currentSlideIndex === 0;
  const currentSongIsEnglish = currentSong?.isEnglish;
  const presenterMode = showPresenterModeCurrentNext();

  // Show tiles if no song is selected.
  const showTiles = !currentSong;
  // Always show lyrics box (so Back button is visible) when a song is selected.
  const showLyricsBox = currentSong;

  return (
    <div style={{ width: "100%", height: "100%", position: "relative" }} onContextMenu={(e) => e.preventDefault()}>

<SEO
        title="Worship - Global Christian Community"
        description="Join our worship sessions and explore a rich library of Bible songs, spiritual worship, and Christian melodies."
        keywords="worship, Bible songs, Christian music, Global Christian Community"
        url="https://globalchristiancommunity.com/worship"
        image="https://globalchristiancommunity.com/images/og-worship.jpg"
      />
      {/* Hide/Show Controls Button */}
      <button id="hide-controls-btn" title="Hide/Show Controls" onClick={toggleControls}>
        👁️
      </button>

      <div id="lyrics-container">
        {controlsVisible && (
          <div id="controls" style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
            {/* Top row */}
            <div className="controls-row top-row" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", marginBottom: 5 }}>
              <input
                type="text"
                id="search-input"
                placeholder="Search.."
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
              />
              <select
                id="song-selector"
                title="Song"
                value={currentSongIndex >= 0 ? currentSongIndex : ""}
                onChange={(e) => {
                  const val = parseInt(e.target.value, 10);
                  if (val >= 0) loadSong(val);
                }}
              >
                <option value="">Select a song</option>
                {filteredSongs.map((s, idx) => (
                  <option key={s.title + idx} value={idx}>
                    {s.title}
                  </option>
                ))}
              </select>
              <select id="category-selector" value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
                {categories.map((cat) => (
                  <option key={cat} value={cat}>
                    {cat}
                  </option>
                ))}
              </select>
              <select id="blogspot-selector" title="Catalog" value={selectedDomain} onChange={(e) => setSelectedDomain(e.target.value)}>
                <option value="">Choose Catalog</option>
                {organizations.map((org) => (
                  <option key={org.domain} value={org.domain}>
                    {org.title}
                  </option>
                ))}
              </select>
              <label id="display-mode-label">
                <select id="display-mode-selector" value={displayMode} onChange={(e) => setDisplayMode(e.target.value)}>
                  <option value="slide">Slide</option>
                  <option value="video">Video</option>
                  <option value="music">Music</option>
                  <option value="presenter">Present</option>
                  <option value="entire">Full Content</option>
                </select>
              </label>
              <button
                id="help-btn"
                title="Keyboard Shortcuts"
                style={{ fontSize: 16, padding: "3px 8px", marginLeft: 10, cursor: "pointer" }}
                onClick={() => setHelpModalOpen(true)}
              >
                ?
              </button>
            </div>

            {/* Bottom row */}
            <div className="controls-row bottom-row" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
              <button
                id="back-to-songs-btn"
                style={{ display: currentSong ? "block" : "none" }}
                onClick={() => {
                  setCurrentSong(null);
                  setParagraphSlides([]);
                  setLineSlides([]);
                  setActiveSlides([]);
                }}
              >
                ← Back
              </button>
              <select
                id="youtube-selector"
                disabled={youtubeLinks.length === 0}
                onChange={(e) => {
                  const idx = parseInt(e.target.value, 10);
                  if (idx >= 0 && idx < youtubeLinks.length) {
                    const link = youtubeLinks[idx];
                    setSelectedYouTubeLink(link);
                    if (displayMode === "video") {
                      const vid = extractYouTubeID(youtubeLinks[idx]);
                      if (vid) createVideoPlayer(vid);
                    } else if (isYouTubePlaying) {
                      playAudioLink(link);
                    }
                  }
                }}
              >
                {youtubeLinks.length === 0 ? (
                  <option value="-1">No YT links</option>
                ) : (
                  youtubeLinks.map((lk, i) => (
                    <option key={lk} value={i}>
                      {i + 1}
                    </option>
                  ))
                )}
              </select>
              <button
                id="slide-play-pause-btn"
                style={{ display: selectedYouTubeLink ? "block" : "none" }}
                onClick={togglePlayPause}
              >
                {isYouTubePlaying ? "⏸" : "▶"}
              </button>
              <span id="playback-rate-display">{currentPlaybackRate.toFixed(2)}x Speed</span>
              <label style={{ marginLeft: 5, color: "#ccc" }}>
                L
                <input
                  type="range"
                  id="lines-per-slide-range"
                  min="0"
                  max="25"
                  value={linesPerSlide}
                  style={{ verticalAlign: "middle", width: "200px" }}
                  onChange={(e) => setLinesPerSlide(parseInt(e.target.value, 10))}
                />
                <span id="lines-per-slide-value">{linesPerSlide}</span>
              </label>
              <label style={{ marginLeft: 5, color: "#ccc" }}>
                F
                <input
                  type="range"
                  id="font-range"
                  min="10"
                  max="150"
                  value={globalFontSize}
                  style={{ verticalAlign: "middle", width: "200px" }}
                  onChange={(e) => setGlobalFontSize(parseInt(e.target.value, 10))}
                />
                <span id="font-size-value">{globalFontSize}</span>
              </label>
              <label style={{ color: "#ccc", marginLeft: 10 }}>
                A
                <input
                  type="range"
                  id="auto-advance-range"
                  min="0"
                  max="120"
                  value={autoAdvanceInterval}
                  style={{ verticalAlign: "middle", width: "200px" }}
                  onChange={(e) => setAutoAdvanceInterval(parseInt(e.target.value, 10))}
                />
                <span id="auto-advance-value">{autoAdvanceInterval}</span> s
              </label>
              <button
                id="toggle-light-mode-btn"
                title="Toggle Tile Light Mode"
                style={{ fontSize: 16, padding: 0, marginLeft: 1, cursor: "pointer" }}
                onClick={handleToggleTileLight}
              >
                ✨
              </button>
            </div>
          </div>
        )}

        {/* Audio Progress Bar (only for non-video mode, when a song is selected, and controls are visible) */}
        {controlsVisible && displayMode !== "video" && currentSong && (
          <div id="audio-progress-bar">
            <input
              type="range"
              id="audio-progress-input"
              min="0"
              max={audioDuration}
              value={audioProgress}
              onChange={handleAudioSeek}
              step="0.1"
            />
            <span className="audio-time-display">
              {formatTime(audioProgress)} / {formatTime(audioDuration)}
            </span>
          </div>
        )}

        {/* Tiles container */}
        <div id="tiles-container" style={{ display: showTiles ? "flex" : "none" }}>
          {filteredSongs.map((song, idx) => (
            <div key={song.title + idx} className="tile" onClick={() => loadSong(idx)}>
              {song.title}
            </div>
          ))}
        </div>

        {/* Lyrics Box */}
        <div id="lyrics-box" style={{ display: showLyricsBox ? "flex" : "none" }}>
          {displayMode === "slide" && (
            <div
              id="lyrics"
              tabIndex={0}
              className={
                isTitleSlide()
                  ? "title-slide"
                  : currentSongIsEnglish
                  ? "english-lyrics"
                  : "non-english-lyrics"
              }
              style={{ fontSize: globalFontSize, display: "flex", flexDirection: "column" }}
              dangerouslySetInnerHTML={{
                __html: isTitleSlide()
                  ? getSlideContent()
                  : getSlideContent().includes("<img")
                  ? getSlideContent()
                  : getSlideContent().replace(/\n/g, "<br>"),
              }}
            />
          )}
          {displayMode === "entire" && currentSong && (
            <div id="lyrics" tabIndex={0} className="entire-song" style={{ fontSize: globalFontSize }}>
              {currentSong.lyrics.map((p, i) =>
                p.toLowerCase().includes("<img") ? (
                  <div key={i} dangerouslySetInnerHTML={{ __html: p }} />
                ) : (
                  <div key={i} dangerouslySetInnerHTML={{ __html: p.replace(/\n/g, "<br>") }} />
                )
              )}
            </div>
          )}
          <div id="watermark"></div>
        </div>

        {/* Presenter Mode */}
        <div
          id="presenter-mode-container"
          style={{
            display: displayMode === "presenter" && currentSong ? "flex" : "none",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "98%",
            height: "98%",
          }}
        >
          <div
            id="presenter-current"
            style={{
              flex: 0.6,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              whiteSpace: "pre-wrap",
              padding: 10,
              boxSizing: "border-box",
              fontSize: globalFontSize,
            }}
          >
            {presenterMode.current.toLowerCase().includes("<img") ? (
              <div dangerouslySetInnerHTML={{ __html: presenterMode.current }} />
            ) : (
              <div dangerouslySetInnerHTML={{ __html: presenterMode.current.replace(/\n/g, "<br>") }} />
            )}
          </div>
          <div
            id="presenter-next"
            style={{
              flex: 0.4,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "grey",
              whiteSpace: "pre-wrap",
              padding: 10,
              boxSizing: "border-box",
              fontSize: Math.floor(globalFontSize * 0.5),
            }}
          >
            {presenterMode.next.toLowerCase().includes("<img") ? (
              <div dangerouslySetInnerHTML={{ __html: presenterMode.next }} />
            ) : (
              <div dangerouslySetInnerHTML={{ __html: presenterMode.next.replace(/\n/g, "<br>") }} />
            )}
          </div>
        </div>

        <div id="hidden-audio-player"></div>

        {/* Video Mode */}
        <div id="video-container" style={{ display: displayMode === "video" && currentSong ? "block" : "none" }}>
          {(() => {
            const vidID = extractYouTubeID(selectedYouTubeLink);
            if (selectedYouTubeLink && vidID) {
              return <div id="video-mode-player"></div>;
            } else {
              return (
                <div style={{ color: "white", textAlign: "center", padding: 20 }}>
                  <h2>No YouTube link found for this song.</h2>
                  <p>Select another mode or a different song.</p>
                </div>
              );
            }
          })()}
        </div>

        {/* Music Mode */}
        <div id="music-container" style={{ display: displayMode === "music" && currentSong ? "flex" : "none" }}>
          <div id="music-embed-left">
            {musescoreLink ? (
              <iframe
                className="music-embed"
                src={extractMusescoreEmbedURL(musescoreLink) || ""}
                frameBorder="0"
                allowFullScreen
                allow="autoplay; fullscreen"
                title="Musescore Left"
              />
            ) : (
              <p>No valid Musescore content found.</p>
            )}
          </div>
          <div id="music-content-right" style={{ fontSize: globalFontSize }}>
            {currentSongIndex + 1 < filteredSongs.length ? (
              <>
                <h3>{filteredSongs[currentSongIndex + 1].title}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: filteredSongs[currentSongIndex + 1].lyrics.join("<br>"),
                  }}
                />
              </>
            ) : (
              <p>No additional content available.</p>
            )}
          </div>
        </div>

        {/* Unused Music Navigation Buttons */}
        <button id="music-prev-btn" className="music-nav-button" style={{ display: "none" }}>
          ◀
        </button>
        <button id="music-next-btn" className="music-nav-button" style={{ display: "none" }}>
          ▶
        </button>

        {/* Slide Navigation Buttons */}
        {(controlsVisible || window.innerWidth <= 768) && (
          <>
            <button
              id="slide-prev-btn"
              className="slide-nav-button mobile-always-visible"
              style={{ display: ["slide", "presenter", "entire", "music"].includes(displayMode) ? "block" : "none" }}
              onClick={prevContent}
            >
              ⬅
            </button>
            <button
              id="slide-next-btn"
              className="slide-nav-button mobile-always-visible"
              style={{ display: ["slide", "presenter", "entire", "music"].includes(displayMode) ? "block" : "none" }}
              onClick={nextContent}
            >
              ➡
            </button>
            <button
              id="slide-next-song-btn"
              className="slide-nav-button mobile-always-visible"
              style={{ display: ["slide", "presenter", "entire", "music"].includes(displayMode) ? "block" : "none" }}
              onClick={nextSong}
            >
              ⏭
            </button>
            <button
              id="slide-prev-song-btn"
              className="slide-nav-button mobile-always-visible"
              style={{ display: ["slide", "presenter", "entire", "music"].includes(displayMode) ? "block" : "none" }}
              onClick={prevSong}
            >
              ⏮
            </button>
          </>
        )}

        {/* Blue Bottom Play Button */}
        {(controlsVisible || window.innerWidth <= 768) &&
          (selectedYouTubeLink || youtubeLinks.length === 0) && (
            <button
              id="slide-bottom-play-btn"
              className="mobile-always-visible"
              style={{ display: ["slide", "presenter", "entire", "music", "video"].includes(displayMode) ? "block" : "none" }}
              onClick={togglePlayPause}
            >
              {isYouTubePlaying ? "⏸" : "▶"}
            </button>
          )}
      </div>

      {/* Help Modal */}
      <div id="help-modal" className="modal" style={{ display: helpModalOpen ? "block" : "none" }}>
        <div className="modal-content">
          <span className="close" onClick={() => setHelpModalOpen(false)}>
            &times;
          </span>
          <h2>Keyboard Shortcuts</h2>
          <ul className="shortcuts-list">
            <li><span className="shortcut-key">Arrow Right / Page Down</span>: Next Content</li>
            <li><span className="shortcut-key">Arrow Left / Page Up</span>: Previous Content</li>
            <li><span className="shortcut-key">Arrow Up</span>: Previous Song</li>
            <li><span className="shortcut-key">Arrow Down</span>: Next Song</li>
            <li><span className="shortcut-key">Space</span>: Play/Pause</li>
            <li><span className="shortcut-key">G</span>: Show All Tiles (Back)</li>
            <li><span className="shortcut-key">Control / h</span>: Hide/Show Controls</li>
            <li><span className="shortcut-key">&gt; / .</span>: Increase Playback Rate</li>
            <li><span className="shortcut-key">&lt; / ,</span>: Decrease Playback Rate</li>
            <li><span className="shortcut-key">1-9</span>: Select YouTube Link</li>
            <li><span className="shortcut-key">S</span>: Slide Display Mode</li>
            <li><span className="shortcut-key">F</span>: Entire/Full Display Mode</li>
            <li><span className="shortcut-key">V</span>: Video Display Mode</li>
            <li><span className="shortcut-key">M</span>: Music Display Mode</li>
            <li><span className="shortcut-key">P</span>: Presenter Display Mode</li>
            <li><span className="shortcut-key">+</span>: Increase Font Size</li>
            <li><span className="shortcut-key">-</span>: Decrease Font Size</li>
            <li><span className="shortcut-key">]</span>: Increase Lines Per Slide</li>
            <li><span className="shortcut-key">[</span>: Decrease Lines Per Slide</li>
            <li><span className="shortcut-key">*</span>: Increase Auto-Advance Seconds</li>
            <li><span className="shortcut-key">/</span>: Decrease Auto-Advance Seconds</li>
            <li><span className="shortcut-key">T</span>: Focus on Display Mode Dropdown</li>
            <li><span className="shortcut-key">O</span>: Focus on Catalog Dropdown</li>
            <li><span className="shortcut-key">B</span>: Focus on Category Dropdown</li>
            <li><span className="shortcut-key">I</span>: Focus on Song Dropdown</li>
            <li><span className="shortcut-key">E</span>: Focus on Search Box</li>
            <li><span className="shortcut-key">D</span>: Focus on Slide Content</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default LyricsSlideshow;