// src/components/Gamification.jsx
import React, { useState } from "react";
import { FaTrophy, FaStar, FaMedal, FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Gamification.css";
import SEO from './SEO';


function Gamification() {
  const [points, setPoints] = useState(0);
  const [badges, setBadges] = useState([]);

  const earnPoints = (earned) => {
    setPoints(points + earned);
    checkBadges(points + earned);
  };

  const checkBadges = (totalPoints) => {
    const newBadges = [];
    if (totalPoints >= 50 && !badges.includes("Starter")) {
      newBadges.push("Starter");
    }
    if (totalPoints >= 100 && !badges.includes("Achiever")) {
      newBadges.push("Achiever");
    }
    if (totalPoints >= 200 && !badges.includes("Champion")) {
      newBadges.push("Champion");
    }
    if (newBadges.length > 0) {
      setBadges([...badges, ...newBadges]);
      alert(`Congratulations! You've earned the following badge(s): ${newBadges.join(", ")}`);
    }
  };

  return (
    <div className="gamification-container">
      
      {/* Header Section */}
      <section className="gamification-header">
        <h1 className="gamification-heading">
          <FaStar className="gamification-icon" /> Gamification
        </h1>
        <p className="gamification-intro">
          Earn points and badges by participating in our community activities. Showcase your achievements and inspire others!
        </p>
      </section>
      
      {/* Points and Badges Display */}
      <section className="gamification-dashboard">
        <div className="points-section">
          <h2>Your Points</h2>
          <p className="points">{points} XP</p>
        </div>
        <div className="badges-section">
          <h2>Your Badges</h2>
          <div className="badges-container">
            {badges.length > 0 ? (
              badges.map((badge) => (
                <div key={badge} className="badge">
                  {badge === "Starter" && <FaStar className="badge-icon starter" />}
                  {badge === "Achiever" && <FaMedal className="badge-icon achiever" />}
                  {badge === "Champion" && <FaTrophy className="badge-icon champion" />}
                  <span className="badge-text">{badge}</span>
                </div>
              ))
            ) : (
              <p>No badges earned yet. Start participating to earn badges!</p>
            )}
          </div>
        </div>
      </section>
      
      {/* Activities to Earn Points */}
      <section className="gamification-activities">
        <h2>How to Earn Points</h2>
        <div className="activities-grid">
          <div className="activity-card">
            <FaStar className="activity-icon" />
            <h3>Attend Events</h3>
            <p>Earn 10 XP for each event you attend.</p>
            <button className="earn-button" onClick={() => earnPoints(10)}>Attend Event</button>
          </div>
          <div className="activity-card">
            <FaStar className="activity-icon" />
            <h3>Join Small Groups</h3>
            <p>Earn 20 XP for joining a small group.</p>
            <button className="earn-button" onClick={() => earnPoints(20)}>Join Group</button>
          </div>
          <div className="activity-card">
            <FaStar className="activity-icon" />
            <h3>Volunteer</h3>
            <p>Earn 30 XP for volunteering your time.</p>
            <button className="earn-button" onClick={() => earnPoints(30)}>Volunteer</button>
          </div>
          <div className="activity-card">
            <FaStar className="activity-icon" />
            <h3>Complete Challenges</h3>
            <p>Earn variable XP by completing faith-based challenges.</p>
            <button className="earn-button" onClick={() => earnPoints(15)}>Complete Challenge</button>
          </div>
        </div>
      </section>
      
      {/* Navigation Link Back */}
      <section className="navigation-link-back">
        <Link to="/" className="cta-button" aria-label="Back to Home">
          <FaArrowLeft className="back-icon" /> Back to Home
        </Link>
      </section>
      
    </div>
  );
}

export default Gamification;
