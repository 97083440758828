// src/components/Community.jsx
import React from "react";
import { FaUsers, FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Community.css";
import SEO from './SEO';


function Community() {
  return (
    <div className="community-container">
      
      {/* Header Section */}
      <section className="community-header">
        <h1 className="community-heading">
          <FaUsers className="community-icon" /> Community
        </h1>
        <p className="community-intro">
          Connect with fellow community members, join groups, and participate in events to grow together in faith.
        </p>
      </section>
      
      {/* Embedded Community Platform */}
      <section className="community-embed">
        <iframe
          src="https://www.meetup.com/your-meetup-group/embed/"
          width="100%"
          height="600"
          frameBorder="0"
          aria-label="Embedded Community Meetup Groups"
        ></iframe>
      </section>
      
      {/* Navigation Link Back */}
      <section className="navigation-link-back">
        <Link to="/" className="cta-button" aria-label="Back to Home">
          <FaArrowLeft className="back-icon" /> Back to Home
        </Link>
      </section>
      
    </div>
  );
}

export default Community;