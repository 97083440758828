// src/components/PrayerWall.jsx
import React from "react";
import { FaPrayingHands, FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./PrayerWall.css";
import SEO from './SEO';


function PrayerWall() {
  return (
    <div className="prayer-wall-container">
      
      {/* Header Section */}
      <section className="prayer-wall-header">
        <h1 className="prayer-wall-heading">
          <FaPrayingHands className="prayer-wall-icon" /> Prayer Wall
        </h1>
        <p className="prayer-wall-intro">
          Share your prayer requests and support others in their spiritual journey.
        </p>
      </section>
      
      {/* Prayer Request Form */}
      <section className="prayer-request-form">
        <h2>Submit a Prayer Request</h2>
        <p>
          Please fill out the form below to submit your prayer request. Rest assured, your privacy is respected.
        </p>
        <button className="submit-button" onClick={() => window.open('https://forms.gle/your-prayer-form-link', '_blank')}>
          Submit Prayer Request
        </button>
      </section>
      
      {/* Embedded Prayer Requests (Optional) */}
      {/* 
      <section className="prayer-requests">
        <h2>Recent Prayer Requests</h2>
        <iframe
          src="https://docs.google.com/spreadsheets/d/e/YOUR_SPREADSHEET_ID/pubhtml?widget=true&amp;headers=false"
          width="100%"
          height="400"
          frameBorder="0"
          aria-label="Embedded Prayer Requests"
        ></iframe>
      </section>
      */}
      
      {/* Navigation Link Back */}
      <section className="navigation-link-back">
        <Link to="/" className="cta-button" aria-label="Back to Home">
          <FaArrowLeft className="back-icon" /> Back to Home
        </Link>
      </section>
      
    </div>
  );
}

export default PrayerWall;
