import React, { useState, useEffect } from 'react';
import './Media.css';
import { FaPlayCircle , FaVideo} from 'react-icons/fa';
import SEO from './components/SEO';


export default function Media() {
  const [playlists] = useState([
    {
      title: "Tamil Songs",
      id: "PLlfpe0DDwzp065euqYHzQM7S1X222WsmZ",
      description: "Enjoy the best Tamil songs."
    },
    {
      title: "English Songs",
      id: "PLlfpe0DDwzp2X5o3br_eDTp-rU_yFhGP2",
      description: "Top trending English songs."
    },
    {
      title: "Malayalam Songs",
      id: "PLlfpe0DDwzp2Lcv9lxQywvfim0HYTkGps",
      description: "A collection of popular Malayalam songs."
    }
    ,
    {
      title: "Bible Melodies",
      id: "PLlfpe0DDwzp2sPVR-C3TnlANlB3FhqqwU",
      description: "Bible Melodies - Worship & Learn."
    }

    ,
    {
      title: "Bible Songs Jukebox",
      id: "PLlfpe0DDwzp0ldn0ZnLBal-M_S8GVZdP6",
      description: "Bible Songs Jukebox: Worship & Learn"
    }
 
   ]);

  const [currentPlaylistIndex, setCurrentPlaylistIndex] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);

  useEffect(() => {
    if (currentPlaylistIndex !== null) {
      setVideoUrl(`https://www.youtube.com/embed/videoseries?list=${playlists[currentPlaylistIndex].id}&autoplay=1&mute=0`);
    }
  }, [currentPlaylistIndex, playlists]);

  return (
    <div className="media-container">
      <div className="media-header">
        <h1 className="media-heading">
          <FaVideo className="media-icon" /> Songs
        </h1>
        <p className="media-intro">Listen to your favorite music playlists!</p>
      </div>
      {currentPlaylistIndex === null ? (
        <div className="playlist-grid">
          {playlists.map((playlist, index) => (
            <div
              key={index}
              className="playlist-item"
              onClick={() => setCurrentPlaylistIndex(index)}
            >
              <span className="icon"><FaPlayCircle /></span>
              <h3>{playlist.title}</h3>
              <p>{playlist.description}</p>
            </div>
          ))}
        </div>
      ) : (
        <div className="video-container">
          <iframe
            src={videoUrl}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
          ></iframe>
          <button className="back-button" onClick={() => setCurrentPlaylistIndex(null)}>
            Back to Playlists
          </button>
        </div>
      )}
    </div>
  );
}