
// src/components/HamburgerMenu.jsx
import React, { useState } from "react";
import { Link } from "react-router-dom";
import SEO from './components/SEO';

import { 
  FaBars, 
  FaTimes, 
  FaHome, 
  FaMusic, 

  FaVideo,
  FaPodcast, 
  FaBlog, 
  FaStore, 
  FaCalendarAlt, 
  FaUsers, 
  FaBroadcastTower, 
  FaBookReader, 
  FaChalkboardTeacher, 
  FaBook, 
  FaMedal, 
  FaTrophy, 
  FaShareAlt, 
  FaUserMd, 
  FaUserTie, 
  FaHandsHelping, 
  FaComments, 
  FaPray ,
  FaUserCircle
} from "react-icons/fa";
import "./HamburgerMenu.css"; // Import specific styles

function HamburgerMenu() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="hamburger-menu">
        <button className="menu-button" onClick={toggleMenu} aria-label="Toggle Menu">
        {isOpen ? <FaBars /> : "†" }

          {/* {isOpen ? <FaTimes /> :   : <FaBars />} */}
        </button>
      </div>
      <nav className={`nav-links ${isOpen ? "open" : ""}`}>
        <Link to="/" onClick={toggleMenu} aria-label="Home">
          <FaHome className="nav-icon" />
          Home
        </Link>
        {/* <Link to="/worship" onClick={toggleMenu} aria-label="Worship">
          <FaPodcast className="nav-icon" />
          Worship
        </Link> */}
        <Link to="/bible" onClick={toggleMenu} aria-label="Bible Reader">
          <FaBookReader className="nav-icon" />
          Bible
        </Link>
        <Link to="/media" onClick={toggleMenu} aria-label="Media">
          <FaVideo className="nav-icon" />
          Songs
        </Link>
        <Link to="/karaoke" onClick={toggleMenu} aria-label="Karaoke">
          <FaMusic className="nav-icon" />
          Karaoke
        </Link>

        <Link to="/biblestudy" onClick={toggleMenu} aria-label="Bible Study Tools">
          <FaBookReader className="nav-icon" />
          Bible Study
        </Link>


        <Link to="/courses" onClick={toggleMenu} aria-label="Online Courses">
          <FaChalkboardTeacher className="nav-icon" />
          Messages
        </Link>

        <Link to="/events" onClick={toggleMenu} aria-label="Events">
          <FaCalendarAlt className="nav-icon" />
          Events
        </Link>

        {/* <Link to="/help" onClick={toggleMenu} aria-label="Help">
          <FaBroadcastTower className="nav-icondev" />
          Help
        </Link>  */}

{/* 

        <Link to="/livestreaming" onClick={toggleMenu} aria-label="Live Streaming">
          <FaBroadcastTower className="nav-icondev" />
          Live Streaming
        </Link>

        <Link to="/podcasts" onClick={toggleMenu} aria-label="Podcasts">
          <FaPodcast className="nav-icondev" />
          Podcasts
        </Link>
        <Link to="/blog" onClick={toggleMenu} aria-label="Blog">
          <FaBlog className="nav-icondev" />
          Blog
        </Link>
        <Link to="/marketplace" onClick={toggleMenu} aria-label="Marketplace">
          <FaStore className="nav-icondev" />
          Marketplace
        </Link>
        

        <Link to="/smallgroups" onClick={toggleMenu} aria-label="Small Groups">
          <FaUsers className="nav-icondev" />
          Small Groups
        </Link>
        
        <Link to="/resources" onClick={toggleMenu} aria-label="Resource Library">
          <FaBook className="nav-icondev" />
          Resource Library
        </Link>
       
        <Link to="/gamification" onClick={toggleMenu} aria-label="Gamification">
          <FaMedal className="nav-icondev" />
          Gamification
        </Link>
        <Link to="/socialmedia" onClick={toggleMenu} aria-label="Social Media Integration">
          <FaShareAlt className="nav-icondev" />
          Social Media
        </Link>
        <Link to="/counseling" onClick={toggleMenu} aria-label="Christian Counseling Services">
          <FaUserMd className="nav-icondev" />
          Counseling
        </Link>
        <Link to="/mentorship" onClick={toggleMenu} aria-label="Mentorship Programs">
          <FaUserTie className="nav-icondev" />
          Mentorship
        </Link>
        <Link to="/volunteer" onClick={toggleMenu} aria-label="Volunteer Opportunities">
          <FaHandsHelping className="nav-icondev" />
          Volunteer
        </Link>
        <Link to="/forums" onClick={toggleMenu} aria-label="Forums and Discussion Boards">
          <FaComments className="nav-icondev" />
          Forums
        </Link>
        <Link to="/prayerwall" onClick={toggleMenu} aria-label="Prayer Wall">
          <FaPray className="nav-icondev" />
          Prayer Wall
        </Link>
        <Link to="/profile/yourUserId" onClick={toggleMenu} aria-label="Your Profile">
          <FaUserCircle className="nav-icondev" />
          Profile
        </Link>
        <Link to="/community" onClick={toggleMenu} aria-label="Community">
          <FaUsers className="nav-icondev" />
          Community
        </Link>
*/}
        {/* Add more links as needed */}
      </nav>
    </>
  );
}

export default HamburgerMenu;

// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import './HamburgerMenu.css'; // Import your CSS file here


// export default function HamburgerMenu() {
//   const [menuOpen, setMenuOpen] = useState(false);

//   const toggleMenu = () => {
//     setMenuOpen(!menuOpen);
//   };

//   return (
//     <div>
//       {/* The hamburger icon (3 bars) */}
//       <button className="hamburger-menu" onClick={toggleMenu}>
//       †
//       </button>

//       {/* The side menu */}
//       <div className={`menu ${menuOpen ? "menu-open" : ""}`}>
//         <ul>
//         <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
//           <li><Link to="/worship" onClick={toggleMenu}>Worship</Link></li>
//           <li><Link to="/bible" onClick={toggleMenu}>Bible</Link></li>
//           <li><Link to="/media" onClick={toggleMenu}>Media</Link></li>

//           {/* <li><Link to="/tools" onClick={toggleMenu}>Tools</Link></li>
//           <li><Link to="/music" onClick={toggleMenu}>Music</Link></li>
//           <li><Link to="/blog" onClick={toggleMenu}>Blog</Link></li>
//           <li><Link to="/podcast" onClick={toggleMenu}>Podcast</Link></li>
//           <li><Link to="/audio" onClick={toggleMenu}>Audio</Link></li>
//           <li><Link to="/community" onClick={toggleMenu}>Community</Link></li>
//           <li><Link to="/events" onClick={toggleMenu}>Events</Link></li> */}
//         </ul>
//       </div>
//     </div>
//   );
// }
