// src/components/Mentorship.jsx
import React from "react";
import { FaUserFriends, FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Mentorship.css";
import SEO from './SEO';


function Mentorship() {
  return (
    <div className="mentorship-container">
      
      {/* Header Section */}
      <section className="mentorship-header">
        <h1 className="mentorship-heading">
          <FaUserFriends className="mentorship-icon" /> Mentorship
        </h1>
        <p className="mentorship-intro">
          Empowering individuals to become Christian leaders through dedicated mentorship and support.
        </p>
      </section>
      
      {/* Information Section */}
      <section className="mentorship-info">
        <h2>How We Can Help You</h2>
        <p>
          Our mentorship program pairs aspiring Christian leaders with experienced mentors who provide guidance, support, and wisdom to foster spiritual growth and leadership skills. Whether you're looking to deepen your faith, enhance your leadership abilities, or navigate personal challenges, our mentors are here to assist you on your journey.
        </p>
      </section>
      
      {/* Mentor Nomination */}
      <section className="mentor-nomination">
        <h2>Become a Mentor</h2>
        <p>
          Are you an experienced Christian leader passionate about guiding others? Nominate yourself to become a mentor and make a lasting impact within our community.
        </p>
        <button className="nominate-button" onClick={() => window.open('https://forms.gle/your-mentor-nomination-form', '_blank')}>
          Nominate as a Mentor
        </button>
      </section>
      
      {/* Mentee Enrollment */}
      <section className="mentee-enrollment">
        <h2>Become a Mentee</h2>
        <p>
          Ready to grow in your faith and leadership? Enroll as a mentee and connect with a dedicated mentor who will support your personal and spiritual development.
        </p>
        <button className="enroll-button" onClick={() => window.open('https://forms.gle/your-mentee-enrollment-form', '_blank')}>
          Enroll as a Mentee
        </button>
      </section>
      
      {/* How It Works */}
      <section className="mentorship-how-it-works">
        <h2>How It Works</h2>
        <ol>
          <li><strong>Sign Up:</strong> Complete the Google Form to enroll as a mentor or mentee.</li>
          <li><strong>Matching:</strong> Our team will match mentees with suitable mentors based on goals and preferences.</li>
          <li><strong>Connect:</strong> Begin your mentorship journey with regular meetings and ongoing support.</li>
          <li><strong>Grow:</strong> Engage in activities and discussions to foster personal and spiritual growth.</li>
        </ol>
      </section>
      
      {/* Navigation Link Back */}
      <section className="navigation-link-back">
        <Link to="/" className="cta-button" aria-label="Back to Home">
          <FaArrowLeft className="back-icon" /> Back to Home
        </Link>
      </section>
      
    </div>
  );
}

export default Mentorship;
