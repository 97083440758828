
import React, { useState, useEffect } from 'react';
import './Karaoke.css';
import { FaMicrophoneAlt , FaMusic} from 'react-icons/fa';
import SEO from './SEO';


export default function Karaoke() {
  const [playlists] = useState([
    {
      title: "Tamil Karaoke",
      id: "PLlfpe0DDwzp02eZ49PyVNvcbMOfaQL_Wk",
      description: "Sing along with Tamil karaoke tracks."
    },
    {
      title: "English Karaoke",
      id: "PLlfpe0DDwzp1JzZ-ipAE45Hufyg_M0BNL",
      description: "Enjoy English karaoke songs."
    },
    {
      title: "Malayalam Karaoke",
      id: "PLlfpe0DDwzp0cMyAwgrWhrXjk-AymbnGP",
      description: "Sing your favorite Malayalam songs."
    }
  ]);

  const [currentPlaylistIndex, setCurrentPlaylistIndex] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);

  useEffect(() => {
    if (currentPlaylistIndex !== null) {
      setVideoUrl(`https://www.youtube.com/embed/videoseries?list=${playlists[currentPlaylistIndex].id}&autoplay=1&mute=0`);
    }
  }, [currentPlaylistIndex, playlists]);

  return (
    <div className="karaoke-container">
      <div className="karaoke-header">
        <h1 className="karaoke-heading">
          <FaMusic className="karaoke-icon" /> Karaoke
        </h1>
        <p className="karaoke-intro">Sing along with your favorite tracks!</p>
      </div>
      {currentPlaylistIndex === null ? (
        <div className="playlist-grid">
          {playlists.map((playlist, index) => (
            <div
              key={index}
              className="playlist-item"
              onClick={() => setCurrentPlaylistIndex(index)}
            >
              <span className="icon"><FaMicrophoneAlt /></span>
              <h3>{playlist.title}</h3>
              <p>{playlist.description}</p>
            </div>
          ))}
        </div>
      ) : (
        <div className="video-container">
          <iframe
            src={videoUrl}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
          ></iframe>
          <button className="back-button" onClick={() => setCurrentPlaylistIndex(null)}>
            Back to Playlists
          </button>
        </div>
      )}
    </div>
  );
}



// import React, { useState, useEffect, useRef } from 'react';

// /**
//  * React component that embeds a YouTube playlist iframe with
//  * keyboard shortcuts, a bottom-center menu button, and a 
//  * dropdown menu for switching playlists.
//  */
// export default function Media() {
//   /****************************************************
//    * 1) STYLES (converted from the original <style> tag)
//    ****************************************************/
//   const containerStyle = {
//     position: 'fixed',
//     top: 0,
//     left: 0,
//     width: '100%',
//     height: '100%',
//     backgroundColor: '#000',
//     overflow: 'hidden',
//     fontFamily: 'Arial, sans-serif',
//     zIndex: 0, // Ensure it's the base layer
//   };
  
//   const playerContainerStyle = {
//     position: 'fixed',
//     top: '5%',
//     left: 0,
//     width: '100%',
//     height: '95%',
//     zIndex: 0, // Behind other UI elements
//   };

//   const playerStyle = {
//     width: '100%',
//     height: '100%',
//   };

//   const menuButtonStyle = {
//     position: 'absolute',
//     bottom: '20px',
//     left: '50%',
//     transform: 'translateX(-50%)',
//     backgroundColor: 'rgba(0, 0, 0, 0.7)',
//     color: '#fff',
//     border: 'none',
//     padding: '10px 15px',
//     cursor: 'pointer',
//     borderRadius: '50%',
//     zIndex: 1001,
//     fontSize: '24px',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//   };

//   const menuStyle = {
//     position: 'absolute',
//     bottom: '70px',
//     left: '50%',
//     transform: 'translateX(-50%)',
//     backgroundColor: 'rgba(0, 0, 0, 0.9)',
//     color: '#fff',
//     padding: '20px',
//     borderRadius: '8px',
//     boxShadow: '0 2px 10px rgba(0,0,0,0.5)',
//     display: 'none', // Hidden by default
//     zIndex: 1000,
//     width: '300px',
//     maxHeight: '400px',
//     overflowY: 'auto',
//   };

//   const searchBarStyle = {
//     width: '100%',
//     padding: '8px 10px',
//     border: 'none',
//     borderRadius: '4px',
//     marginBottom: '15px',
//     fontSize: '16px',
//   };

//   const playlistItemStyle = {
//     padding: '8px 12px',
//     cursor: 'pointer',
//     borderRadius: '4px',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'space-between',
//   };

  

//   /****************************************************
//    * 2) DATA: Hard-coded playlist objects
//    ****************************************************/
//   const [playlists] = useState([
//     {
//       title: "Tamil Songs",
//       id: "PLlfpe0DDwzp02eZ49PyVNvcbMOfaQL_Wk"
//     },
//     {
//       title: "English Songs",
//       id: "PLlfpe0DDwzp1JzZ-ipAE45Hufyg_M0BNL"
//     },
//     {
//       title: "Malayalam Songs",
//       id: "PLlfpe0DDwzp0cMyAwgrWhrXjk-AymbnGP"
//     },
//     // Add more if needed
//   ]);


//   /****************************************************
//    * 3) REFS AND STATE
//    ****************************************************/
//   const [currentPlaylistIndex, setCurrentPlaylistIndex] = useState(0);
//   const [player, setPlayer] = useState(null);

//   const menuRef = useRef(null);
//   const menuButtonRef = useRef(null);
//   const searchBarRef = useRef(null);
//   const playlistListRef = useRef(null);

//   const [menuVisible, setMenuVisible] = useState(false);
//   const [searchQuery, setSearchQuery] = useState('');

//   /****************************************************
//    * 4) YOUTUBE IFRAME API LOADING
//    ****************************************************/
//   useEffect(() => {
//     // Function to initialize the player
//     const initializePlayer = () => {
//       if (window.YT && window.YT.Player) {
//         initPlayer();
//       }
//     };

//     // Check if YT is already loaded
//     if (window.YT && window.YT.Player) {
//       initializePlayer();
//     } else {
//       // Load the YouTube IFrame API
//       const tag = document.createElement('script');
//       tag.src = "https://www.youtube.com/iframe_api";
//       window.document.body.appendChild(tag);

//       // Set the callback for when the API is ready
//       window.onYouTubeIframeAPIReady = initializePlayer;
//     }
//   }, [currentPlaylistIndex, playlists]);

//   // Initialize the Player
//   const initPlayer = () => {
//     const newPlayer = new window.YT.Player('react-youtube-player', {
//       height: '100%',
//       width: '100%',
//       playerVars: {
//         list: playlists[currentPlaylistIndex].id,
//         listType: 'playlist',
//         autoplay: 1,
//         controls: 1,
//         modestbranding: 1,
//         rel: 0,
//         showinfo: 0,
//         fs: 1,
//         iv_load_policy: 3
//       },
//       events: {
//         onReady: onPlayerReady,
//         onStateChange: onPlayerStateChange,
//         onError: onPlayerError
//       }
//     });
//     setPlayer(newPlayer);
//   };

//   function onPlayerReady(event) {
//     console.log('Player Ready');
//      event.target.playVideo(); // optionally auto-play
//   }

//   function onPlayerStateChange(event) {
//     switch(event.data) {
//       case window.YT.PlayerState.PLAYING:
//         console.log('Playing');
//         break;
//       case window.YT.PlayerState.PAUSED:
//         console.log('Paused');
//         break;
//       case window.YT.PlayerState.ENDED:
//         console.log('Video Ended');
//         break;
//       case window.YT.PlayerState.UNSTARTED:
//       case window.YT.PlayerState.CUED:
//       default:
//         console.log('Loading or Unhandled State...', event.data);
//     }
//   }

//   function onPlayerError(event) {
//     console.error('Error occurred in YouTube Player:', event.data);
//   }

//   /****************************************************
//    * 5) MENU HANDLERS
//    ****************************************************/
//   const toggleMenu = () => {
//     setMenuVisible(!menuVisible);
//   };

//   // Populate the playlist list based on search
//   const filteredPlaylists = playlists.filter(playlist => {
//     return playlist.title.toLowerCase().includes(searchQuery.toLowerCase());
//   });

//   // Switch to selected playlist
//   const switchPlaylist = (index) => {
//     if (!player) return;
//     if (index < 0 || index >= playlists.length) return;

//     setCurrentPlaylistIndex(index);
//     player.loadPlaylist({
//       list: playlists[index].id,
//       listType: 'playlist'
//     });
//     console.log(`Switched to "${playlists[index].title}"`);
//     setMenuVisible(false);
//   };

//   // Because we want to close the menu if user clicks outside
//   useEffect(() => {
//     function handleClickOutside(e) {
//       if (
//         menuRef.current &&
//         menuButtonRef.current &&
//         !menuRef.current.contains(e.target) &&
//         !menuButtonRef.current.contains(e.target)
//       ) {
//         setMenuVisible(false);
//       }
//     }

//     document.addEventListener('click', handleClickOutside);
//     return () => document.removeEventListener('click', handleClickOutside);
//   }, []);

//   /****************************************************
//    * 6) KEYBOARD SHORTCUTS
//    ****************************************************/
//   // Attach keydown for space/arrow, etc.
//   useEffect(() => {
//     function handleKeyDown(event) {
//       // If user is in an input, ignore
//       const activeElem = document.activeElement;
//       if (activeElem && (activeElem.tagName === 'INPUT' || activeElem.tagName === 'TEXTAREA')) {
//         return;
//       }

//       // Some keys we want to prevent default
//       const keysToPrevent = [
//         ' ', 'ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown',
//         'F', 'M', 'S', 'PageUp', 'PageDown',
//         '1','2','3','4','5','6','7','8','9'
//       ];

//       if (keysToPrevent.includes(event.key) || keysToPrevent.includes(event.key.toUpperCase())) {
//         event.preventDefault();
//       }

//       // Now let's handle
//       const key = event.key.toLowerCase();
//       switch(key) {
//         case ' ':
//           togglePlayPause();
//           break;
//         case 'arrowright':
//         case 'pagedown':
//           nextVideo();
//           break;
//         case 'arrowleft':
//         case 'pageup':
//           previousVideo();
//           break;
//         case 'arrowup':
//           increaseVolume();
//           break;
//         case 'arrowdown':
//           decreaseVolume();
//           break;
//         case 'f':
//           toggleFullscreen();
//           break;
//         case 'm':
//           toggleMute();
//           break;
//         case 's':
//           // open/close the playlist menu
//           toggleMenu();
//           break;
//         default:
//           // If it's a digit 1-9, see if we can switch playlist
//           if (/^[1-9]$/.test(key)) {
//             const num = parseInt(key);
//             if (num >= 1 && num <= playlists.length) {
//               switchPlaylist(num - 1);
//             }
//           }
//           break;
//       }
//     }

//     window.addEventListener('keydown', handleKeyDown);
//     return () => window.removeEventListener('keydown', handleKeyDown);
//   }, [player, playlists, toggleMenu, switchPlaylist]);

//   // Play/Pause
//   function togglePlayPause() {
//     if (!player) return;
//     const playerState = player.getPlayerState();
//     if (playerState === window.YT.PlayerState.PLAYING) {
//       player.pauseVideo();
//     } else {
//       player.playVideo();
//     }
//   }

//   function nextVideo() {
//     if (!player) return;
//     player.nextVideo();
//   }

//   function previousVideo() {
//     if (!player) return;
//     player.previousVideo();
//   }

//   function increaseVolume() {
//     if (!player) return;
//     let currentVolume = player.getVolume();
//     if (currentVolume < 100) {
//       player.setVolume(Math.min(currentVolume + 10, 100));
//       console.log('Volume:', player.getVolume(), '%');
//     }
//   }

//   function decreaseVolume() {
//     if (!player) return;
//     let currentVolume = player.getVolume();
//     if (currentVolume > 0) {
//       player.setVolume(Math.max(currentVolume - 10, 0));
//       console.log('Volume:', player.getVolume(), '%');
//     }
//   }

//   // Fullscreen
//   function toggleFullscreen() {
//     const elem = document.getElementById('player-container');
//     if (!document.fullscreenElement) {
//       if (elem.requestFullscreen) {
//         elem.requestFullscreen();
//       } else if (elem.mozRequestFullScreen) {
//         elem.mozRequestFullScreen();
//       } else if (elem.webkitRequestFullscreen) {
//         elem.webkitRequestFullscreen();
//       } else if (elem.msRequestFullscreen) {
//         elem.msRequestFullscreen();
//       }
//       console.log('Entered Fullscreen');
//     } else {
//       if (document.exitFullscreen) {
//         document.exitFullscreen();
//       } else if (document.mozCancelFullScreen) {
//         document.mozCancelFullScreen();
//       } else if (document.webkitExitFullscreen) {
//         document.webkitExitFullscreen();
//       } else if (document.msExitFullscreen) {
//         document.msExitFullscreen();
//       }
//       console.log('Exited Fullscreen');
//     }
//   }

//   // Mute/Unmute
//   function toggleMute() {
//     if (!player) return;
//     if (player.isMuted()) {
//       player.unMute();
//       console.log('Unmuted');
//     } else {
//       player.mute();
//       console.log('Muted');
//     }
//   }

//   /****************************************************
//    * 7) RENDER
//    ****************************************************/
//   return (
//     <div style={containerStyle}>
//       {/* The container for the YouTube player */}
//       <div
//         id="player-container"
//         style={playerContainerStyle}
//       >
//         <div
//           id="react-youtube-player"
//           style={playerStyle}
//         />
//       </div>

//       {/* The bottom-center menu button */}
//       <button
//         ref={menuButtonRef}
//         id="menu-button"
//         style={menuButtonStyle}
//         onClick={() => {
//           setMenuVisible(!menuVisible);
//           // Focus on search bar if opening
//           if (!menuVisible && searchBarRef.current) {
//             setTimeout(() => searchBarRef.current.focus(), 0);
//           }
//         }}
//         aria-label="Playlist Menu"
//       >
//         &#9776;
//       </button>

//       {/* The dropdown menu */}
//       <div
//         ref={menuRef}
//         id="menu"
//         style={{
//           ...menuStyle,
//           display: menuVisible ? 'block' : 'none'
//         }}
//       >
//         <input
//           type="text"
//           ref={searchBarRef}
//           id="search-bar"
//           placeholder="Search Playlists..."
//           style={searchBarStyle}
//           value={searchQuery}
//           onChange={(e) => setSearchQuery(e.target.value)}
//         />
//         <ul
//           id="playlist-list"
//           ref={playlistListRef}
//           style={{ listStyle: 'none', marginTop: '10px', paddingLeft: 0 }}
//         >
//           {filteredPlaylists.map((playlist, index) => (
//             <li
//               key={playlist.id}
//               className="playlist-item"
//               style={playlistItemStyle}
//               onClick={() => {
//                 switchPlaylist(getOriginalIndex(playlist.title));
//               }}
//             >
//               <span>
//                 {getOriginalIndex(playlist.title) + 1}. {playlist.title}
//               </span>
//               {/* Show "✔️" for the current playlist */}
//               <span>
//                 {getOriginalIndex(playlist.title) === currentPlaylistIndex ? '✔️' : ''}
//               </span>
//             </li>
//           ))}
//         </ul>
//       </div>
//     </div>
//   );

//   /**
//    * Because 'filteredPlaylists' is a filtered subset, we need a helper
//    * to find the original index in 'playlists'. This ensures the correct
//    * index for switchPlaylist().
//    */
//   function getOriginalIndex(playlistTitle) {
//     return playlists.findIndex(pl => pl.title === playlistTitle);
//   }
// }


// import React, { useState, useEffect, useRef } from 'react';

// /**
//  * React component that embeds a YouTube playlist iframe with
//  * keyboard shortcuts, a bottom-center menu button, and a 
//  * dropdown menu for switching playlists.
//  */
// export default function Karaoke() {
//   /****************************************************
//    * 1) STYLES (converted from the original <style> tag)
//    ****************************************************/
//   const containerStyle = {
//     position: 'fixed',
//     top: 0,
//     left: 0,
//     width: '100%',
//     height: '100%',
//     backgroundColor: '#000',
//     overflow: 'hidden',
//     fontFamily: 'Arial, sans-serif',
//   };
  
//   const playerContainerStyle = {
//     position: 'fixed',
//     top: 0,
//     left: 0,
//     width: '100%',
//     height: '100%',
//   };

//   const playerStyle = {
//     width: '100%',
//     height: '100%',
//   };

//   const menuButtonStyle = {
//     position: 'absolute',
//     bottom: '20px',
//     left: '50%',
//     transform: 'translateX(-50%)',
//     backgroundColor: 'rgba(0, 0, 0, 0.7)',
//     color: '#fff',
//     border: 'none',
//     padding: '10px 15px',
//     cursor: 'pointer',
//     borderRadius: '50%',
//     zIndex: 1001,
//     fontSize: '24px',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//   };

//   const menuStyle = {
//     position: 'absolute',
//     bottom: '70px',
//     left: '50%',
//     transform: 'translateX(-50%)',
//     backgroundColor: 'rgba(0, 0, 0, 0.9)',
//     color: '#fff',
//     padding: '20px',
//     borderRadius: '8px',
//     boxShadow: '0 2px 10px rgba(0,0,0,0.5)',
//     display: 'none', // Hidden by default
//     zIndex: 1000,
//     width: '300px',
//     maxHeight: '400px',
//     overflowY: 'auto',
//   };

//   const searchBarStyle = {
//     width: '100%',
//     padding: '8px 10px',
//     border: 'none',
//     borderRadius: '4px',
//     marginBottom: '15px',
//     fontSize: '16px',
//   };

//   const playlistItemStyle = {
//     padding: '8px 12px',
//     cursor: 'pointer',
//     borderRadius: '4px',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'space-between',
//   };

//   /****************************************************
//    * 2) DATA: Hard-coded playlist objects
//    ****************************************************/
//   const [playlists] = useState([
//     {
//       title: "Tamil Songs",
//       id: "PLlfpe0DDwzp02eZ49PyVNvcbMOfaQL_Wk"
//     },
//     {
//       title: "English Songs",
//       id: "PLxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
//     },
//     {
//       title: "Malayalam Songs",
//       id: "PLyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy"
//     },
//     // Add more if needed
//   ]);

//   /****************************************************
//    * 3) REFS AND STATE
//    ****************************************************/
//   const [currentPlaylistIndex, setCurrentPlaylistIndex] = useState(0);
//   const [player, setPlayer] = useState(null);

//   const menuRef = useRef(null);
//   const menuButtonRef = useRef(null);
//   const searchBarRef = useRef(null);
//   const playlistListRef = useRef(null);

//   const [menuVisible, setMenuVisible] = useState(false);
//   const [searchQuery, setSearchQuery] = useState('');

//   /****************************************************
//    * 4) YOUTUBE IFRAME API LOADING
//    ****************************************************/
//   useEffect(() => {
//     // 1) If we haven't attached the YouTube Iframe script, do so
//     if (!window.YT) {
//       const tag = document.createElement('script');
//       tag.src = "https://www.youtube.com/iframe_api";
//       window.document.body.appendChild(tag);
//     }

//     // 2) onYouTubeIframeAPIReady calls our init function
//     window.onYouTubeIframeAPIReady = () => {
//       initPlayer();
//     };
//   }, []);

//   // Initialize the Player
//   const initPlayer = () => {
//     const newPlayer = new window.YT.Player('react-youtube-player', {
//       height: '100%',
//       width: '100%',
//       playerVars: {
//         list: playlists[currentPlaylistIndex].id,
//         listType: 'playlist',
//         autoplay: 1,
//         controls: 1,
//         modestbranding: 1,
//         rel: 0,
//         showinfo: 0,
//         fs: 1,
//         iv_load_policy: 3
//       },
//       events: {
//         onReady: onPlayerReady,
//         onStateChange: onPlayerStateChange
//       }
//     });
//     setPlayer(newPlayer);
//   };

//   function onPlayerReady(event) {
//     console.log('Player Ready');
//      event.target.playVideo(); // optionally auto-play
//   }

//   function onPlayerStateChange(event) {
//     switch(event.data) {
//       case window.YT.PlayerState.PLAYING:
//         console.log('Playing');
//         break;
//       case window.YT.PlayerState.PAUSED:
//         console.log('Paused');
//         break;
//       case window.YT.PlayerState.ENDED:
//         console.log('Video Ended');
//         break;
//       default:
//         console.log('Loading...');
//     }
//   }

//   /****************************************************
//    * 5) MENU HANDLERS
//    ****************************************************/
//   const toggleMenu = () => {
//     setMenuVisible(!menuVisible);
//   };

//   // Populate the playlist list based on search
//   const filteredPlaylists = playlists.filter(playlist => {
//     return playlist.title.toLowerCase().includes(searchQuery.toLowerCase());
//   });

//   // Switch to selected playlist
//   const switchPlaylist = (index) => {
//     if (!player) return;
//     if (index < 0 || index >= playlists.length) return;

//     setCurrentPlaylistIndex(index);
//     player.loadPlaylist({
//       list: playlists[index].id,
//       listType: 'playlist'
//     });
//     console.log(`Switched to "${playlists[index].title}"`);
//     setMenuVisible(false);
//   };

//   // Because we want to close the menu if user clicks outside
//   useEffect(() => {
//     function handleClickOutside(e) {
//       if (
//         menuRef.current &&
//         menuButtonRef.current &&
//         !menuRef.current.contains(e.target) &&
//         !menuButtonRef.current.contains(e.target)
//       ) {
//         setMenuVisible(false);
//       }
//     }

//     document.addEventListener('click', handleClickOutside);
//     return () => document.removeEventListener('click', handleClickOutside);
//   }, []);

//   /****************************************************
//    * 6) KEYBOARD SHORTCUTS
//    ****************************************************/
//   // Attach keydown for space/arrow, etc.
//   useEffect(() => {
//     function handleKeyDown(event) {
//       // If user is in an input, ignore
//       const activeElem = document.activeElement;
//       if (activeElem && (activeElem.tagName === 'INPUT' || activeElem.tagName === 'TEXTAREA')) {
//         return;
//       }

//       // Some keys we want to prevent default
//       const keysToPrevent = [
//         ' ', 'ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown',
//         'F', 'M', 'S', 'PageUp', 'PageDown',
//         '1','2','3','4','5','6','7','8','9'
//       ];

//       if (keysToPrevent.includes(event.key) || keysToPrevent.includes(event.key.toUpperCase())) {
//         event.preventDefault();
//       }

//       // Now let's handle
//       const key = event.key.toLowerCase();
//       switch(key) {
//         case ' ':
//           togglePlayPause();
//           break;
//         case 'arrowright':
//         case 'pagedown':
//           nextVideo();
//           break;
//         case 'arrowleft':
//         case 'pageup':
//           previousVideo();
//           break;
//         case 'arrowup':
//           increaseVolume();
//           break;
//         case 'arrowdown':
//           decreaseVolume();
//           break;
//         case 'f':
//           toggleFullscreen();
//           break;
//         case 'm':
//           toggleMute();
//           break;
//         case 's':
//           // open/close the playlist menu
//           toggleMenu();
//           break;
//         default:
//           // If it's a digit 1-9, see if we can switch playlist
//           if (/^[1-9]$/.test(key)) {
//             const num = parseInt(key);
//             if (num >= 1 && num <= playlists.length) {
//               switchPlaylist(num - 1);
//             }
//           }
//           break;
//       }
//     }

//     window.addEventListener('keydown', handleKeyDown);
//     return () => window.removeEventListener('keydown', handleKeyDown);
//   }, [player, playlists, toggleMenu, switchPlaylist]);

//   // Play/Pause
//   function togglePlayPause() {
//     if (!player) return;
//     const playerState = player.getPlayerState();
//     if (playerState === window.YT.PlayerState.PLAYING) {
//       player.pauseVideo();
//     } else {
//       player.playVideo();
//     }
//   }

//   function nextVideo() {
//     if (!player) return;
//     player.nextVideo();
//   }

//   function previousVideo() {
//     if (!player) return;
//     player.previousVideo();
//   }

//   function increaseVolume() {
//     if (!player) return;
//     let currentVolume = player.getVolume();
//     if (currentVolume < 100) {
//       player.setVolume(Math.min(currentVolume + 10, 100));
//       console.log('Volume:', player.getVolume(), '%');
//     }
//   }

//   function decreaseVolume() {
//     if (!player) return;
//     let currentVolume = player.getVolume();
//     if (currentVolume > 0) {
//       player.setVolume(Math.max(currentVolume - 10, 0));
//       console.log('Volume:', player.getVolume(), '%');
//     }
//   }

//   // Fullscreen
//   function toggleFullscreen() {
//     const elem = document.getElementById('player-container');
//     if (!document.fullscreenElement) {
//       if (elem.requestFullscreen) {
//         elem.requestFullscreen();
//       } else if (elem.mozRequestFullScreen) {
//         elem.mozRequestFullScreen();
//       } else if (elem.webkitRequestFullscreen) {
//         elem.webkitRequestFullscreen();
//       } else if (elem.msRequestFullscreen) {
//         elem.msRequestFullscreen();
//       }
//       console.log('Entered Fullscreen');
//     } else {
//       if (document.exitFullscreen) {
//         document.exitFullscreen();
//       } else if (document.mozCancelFullScreen) {
//         document.mozCancelFullScreen();
//       } else if (document.webkitExitFullscreen) {
//         document.webkitExitFullscreen();
//       } else if (document.msExitFullscreen) {
//         document.msExitFullscreen();
//       }
//       console.log('Exited Fullscreen');
//     }
//   }

//   // Mute/Unmute
//   function toggleMute() {
//     if (!player) return;
//     if (player.isMuted()) {
//       player.unMute();
//       console.log('Unmuted');
//     } else {
//       player.mute();
//       console.log('Muted');
//     }
//   }

//   /****************************************************
//    * 7) RENDER
//    ****************************************************/
//   return (
//     <div style={containerStyle}>
//       {/* The container for the YouTube player */}
//       <div
//         id="player-container"
//         style={playerContainerStyle}
//       >
//         <div
//           id="react-youtube-player"
//           style={playerStyle}
//         />
//       </div>

//       {/* The bottom-center menu button */}
//       <button
//         ref={menuButtonRef}
//         id="menu-button"
//         style={menuButtonStyle}
//         onClick={() => {
//           setMenuVisible(!menuVisible);
//           // Focus on search bar if opening
//           if (!menuVisible && searchBarRef.current) {
//             setTimeout(() => searchBarRef.current.focus(), 0);
//           }
//         }}
//         aria-label="Playlist Menu"
//       >
//         &#9776;
//       </button>

//       {/* The dropdown menu */}
//       <div
//         ref={menuRef}
//         id="menu"
//         style={{
//           ...menuStyle,
//           display: menuVisible ? 'block' : 'none'
//         }}
//       >
//         <input
//           type="text"
//           ref={searchBarRef}
//           id="search-bar"
//           placeholder="Search Playlists..."
//           style={searchBarStyle}
//           value={searchQuery}
//           onChange={(e) => setSearchQuery(e.target.value)}
//         />
//         <ul
//           id="playlist-list"
//           ref={playlistListRef}
//           style={{ listStyle: 'none', marginTop: '10px', paddingLeft: 0 }}
//         >
//           {filteredPlaylists.map((playlist, index) => (
//             <li
//               key={playlist.id}
//               className="playlist-item"
//               style={playlistItemStyle}
//               onClick={() => {
//                 switchPlaylist(getOriginalIndex(playlist.title));
//               }}
//             >
//               <span>
//                 {getOriginalIndex(playlist.title) + 1}. {playlist.title}
//               </span>
//               {/* If you want to show "✔️" for the current one, for example: */}
//               <span>
//                 {getOriginalIndex(playlist.title) === currentPlaylistIndex ? '✔️' : ''}
//               </span>
//             </li>
//           ))}
//         </ul>
//       </div>
//     </div>
//   );

//   /**
//    * Because 'filteredPlaylists' is a filtered subset, we need a helper
//    * to find the original index in 'playlists'. This ensures the correct
//    * index for switchPlaylist().
//    */
//   function getOriginalIndex(playlistTitle) {
//     return playlists.findIndex(pl => pl.title === playlistTitle);
//   }
// }
