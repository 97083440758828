// src/components/Profile.jsx
import React from "react";
import { FaUserCircle, FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Profile.css";
import SEO from './SEO';


function Profile() {
  return (
    <div className="profile-container">
      
      {/* Header Section */}
      <section className="profile-header">
        <h1 className="profile-heading">
          <FaUserCircle className="profile-icon" /> Your Profile
        </h1>
        <p className="profile-intro">
          Manage your account, view your achievements, and discover the benefits of being a member.
        </p>
      </section>
      
      {/* Profile Information */}
      <section className="profile-info">
        <div className="profile-avatar">
          <FaUserCircle className="avatar-icon" />
        </div>
        <div className="profile-details">
          <h2>John Doe</h2>
          <p>Email: johndoe@example.com</p>
          <p>Membership Status: Active</p>
        </div>
      </section>
      
      {/* Achievements */}
      <section className="profile-achievements">
        <h2>Your Achievements</h2>
        <div className="achievements-grid">
          <div className="achievement-card">
            <FaArrowLeft className="achievement-icon" />
            <span className="achievement-text">Starter Badge</span>
          </div>
          <div className="achievement-card">
            <FaArrowLeft className="achievement-icon" />
            <span className="achievement-text">Achiever Badge</span>
          </div>
          {/* Add more achievements as needed */}
        </div>
      </section>
      
      {/* Benefits of Membership */}
      <section className="profile-benefits">
        <h2>Benefits of Becoming a Member</h2>
        <ul>
          <li>Access to exclusive resources and materials</li>
          <li>Priority access to events and workshops</li>
          <li>Ability to earn points and badges through participation</li>
          <li>Connect with mentors and counselors</li>
          <li>Join small groups and community forums</li>
        </ul>
      </section>
      
      {/* Google Login Button */}
      <section className="profile-login">
        <h2>Manage Your Account</h2>
        <button className="login-button" onClick={() => window.location.href = '/auth/google'}>
          <FaUserCircle className="login-icon" /> Sign in with Google
        </button>
      </section>
      
      {/* Navigation Link Back */}
      <section className="navigation-link-back">
        <Link to="/" className="cta-button" aria-label="Back to Home">
          <FaArrowLeft className="back-icon" /> Back to Home
        </Link>
      </section>
      
    </div>
  );
}

export default Profile;
