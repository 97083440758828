// src/components/Marketplace.jsx
import React, { useEffect } from "react";
import { FaBook, FaMusic, FaTshirt, FaBible, FaGift, FaHeadphones, FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Marketplace.css";
import SEO from './SEO';


function Marketplace() {
  // List of search terms, icons, and descriptions for different Christian product categories
  const christianProductCategories = [
    {
      name: "Books",
      searchTerm: "Christian Books",
      icon: <FaBook />,
      description:
        "Discover a wide range of Christian literature, including Bibles, devotionals, theology, and inspirational stories.",
    },
    {
      name: "Music",
      searchTerm: "Christian Music",
      icon: <FaMusic />,
      description:
        "Explore uplifting Christian music from various genres to inspire your faith journey.",
    },
    {
      name: "Merchandise",
      searchTerm: "Christian Merchandise",
      icon: <FaGift />,
      description:
        "Find faith-based merchandise, including home decor, accessories, and gifts for loved ones.",
    },
    {
      name: "Bibles",
      searchTerm: "Christian Bibles",
      icon: <FaBible />,
      description:
        "Choose from a variety of Bible translations, study Bibles, and personalized editions to deepen your understanding.",
    },
    {
      name: "Apparel",
      searchTerm: "Christian Apparel",
      icon: <FaTshirt />,
      description:
        "Wear your faith with stylish Christian apparel, including t-shirts, hoodies, hats, and more.",
    },
    {
      name: "Accessories",
      searchTerm: "Christian Accessories",
      icon: <FaHeadphones />,
      description:
        "Enhance your daily life with Christian-themed accessories such as jewelry, bags, and tech gadgets.",
    }, // New Category
  ];

  // Function to inject Amazon's widget script
  const loadAmazonScript = () => {
    // Check if the script is already present
    if (!document.getElementById("amazon-native-shopping-ads")) {
      const script = document.createElement("script");
      script.id = "amazon-native-shopping-ads";
      script.type = "text/javascript";
      script.async = true;
      script.src = "https://z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US";
      document.body.appendChild(script);
    }
  };

  useEffect(() => {
    loadAmazonScript();
  }, []);

  return (
    <div className="marketplace-container">
      {/* Header Section */}
      <section className="marketplace-header">
        <h1 className="marketplace-heading">
          <FaBook className="marketplace-icon" /> Marketplace
        </h1>
        <p className="marketplace-intro">
          Explore our curated categories of Christian resources, books, music, merchandise, accessories, and more on Amazon.
        </p>
      </section>

      {/* Categories Grid */}
      <div className="categories-grid">
        {christianProductCategories.map((category) => (
          <div key={category.name} className="category-card">
            {/* Icon */}
            <div className="category-icon" aria-hidden="true">
              {category.icon}
            </div>
            {/* Category Title */}
            <h2 className="category-title">{category.name}</h2>
            {/* Category Description */}
            <p className="category-description">{category.description}</p>
            {/* Amazon Native Shopping Ads */}
            <div
              className="amazon-widget"
              data-aax-plat="0"
              data-aax-pid={process.env.REACT_APP_AMAZON_ASSOCIATE_ID} // Use environment variable
              data-aax-target="_blank"
              data-aax-color="ffffff"
              data-aax-language="en_US"
              data-aax-theme="light"
              data-aax-search={category.searchTerm}
              aria-label={`Amazon Ads for ${category.name}`}
            ></div>
          </div>
        ))}
      </div>

      {/* Navigation Link Back */}
      <section className="navigation-link-back">
        <Link to="/" className="cta-button" aria-label="Back to Home">
          <FaArrowLeft className="back-icon" /> Back to Home
        </Link>
      </section>
    </div>
  );
}

export default Marketplace;
