// src/components/Events.jsx
import React, { useEffect } from "react";
import { FaCalendarAlt, FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Events.css";
import SEO from './SEO';


export default function Events() {
  // Function to inject Amazon's widget script if needed in the future
  const loadAmazonScript = () => {
    if (!document.getElementById("amazon-native-shopping-ads")) {
      const script = document.createElement("script");
      script.id = "amazon-native-shopping-ads";
      script.type = "text/javascript";
      script.async = true;
      script.src = "https://z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US";
      document.body.appendChild(script);
    }
  };

  useEffect(() => {
    loadAmazonScript();
  }, []);

  return (
    <div className="events-container">
      {/* Header Section */}
      <section className="events-header">
        <h1 className="events-heading">
          <FaCalendarAlt className="events-icon" /> Events
        </h1>
        <p className="events-intro">
          Stay updated with our latest Christian events, gatherings, and community activities.
        </p>
      </section>

      {/* Events Calendar */}
      <div className="events-calendar">
        <iframe
          title="GCC Events Calendar"
          src="https://calendar.google.com/calendar/embed?height=100%&wkst=1&ctz=America%2FChicago&title=GCC%20Events&src=ZmJiMmYyZDNiZDEyOWYwY2E4ODc3MDlhODYzMjQ5MjczZGNiYmEyZmE0MDcxMzkwYjczMmE5ZDJiYzA5YTI5Y0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23D81B60"
          className="events-iframe"
          frameBorder="0"
          scrolling="no"
          aria-label="Google Calendar showing upcoming events"
        ></iframe>
      </div>

      {/* Navigation Link Back */}
      <section className="navigation-link-back">
        <Link to="/" className="cta-button" aria-label="Back to Home">
          <FaArrowLeft className="back-icon" /> Back to Home
        </Link>
      </section>
    </div>
  );
}
