// src/components/ResourceLibrary.jsx
import React from "react";
import { FaFolderOpen, FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./ResourceLibrary.css";
import SEO from './SEO';


function ResourceLibrary() {
  return (
    <div className="resource-library-container">
      
      {/* Header Section */}
      <section className="resource-library-header">
        <h1 className="resource-library-heading">
          <FaFolderOpen className="resource-library-icon" /> Resource Library
        </h1>
        <p className="resource-library-intro">
          Access a wide range of Christian resources, including sermons, study guides, and inspirational materials.
        </p>
      </section>
      
      {/* Embedded Google Drive */}
      <section className="google-drive-embed">
        <iframe
          src="https://drive.google.com/embeddedfolderview?id=1g5g3QXok8hikhrjCFbncdUIAKvZEmair#list"
          width="100%"
           frameBorder="0"
          aria-label="Google Drive Resource Library"
        ></iframe>
      </section>
      
      {/* Navigation Link Back */}
      <section className="navigation-link-back">
        <Link to="/" className="cta-button" aria-label="Back to Home">
          <FaArrowLeft className="back-icon" /> Back to Home
        </Link>
      </section>
      
    </div>
  );
}

export default ResourceLibrary;
