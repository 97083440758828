import React, { useState, useEffect } from 'react';
import './Courses.css';
import { FaPlayCircle , FaChalkboardTeacher} from 'react-icons/fa';
import SEO from './SEO';


export default function Courses() {
  const [playlists] = useState([
  
    
     
    {
      title: "Bible Book Highlights - English",
      id: "PLlfpe0DDwzp1jmVFvCvEbpY7LOgQOdMYv",
      description: "Enjoy the Bible Book Highlights - English."
    },
    

    {
      title: "Kids Bible Books Highlights (English)",
      id: "PLlfpe0DDwzp1lPf404tiN5cGQnv_RPwaQ",
      description: "Enjoy the Kids Bible Books Highlights (English)."
    },
    
        
     
    {
      title: "Romans English Bible Message",
      id: "PLlfpe0DDwzp3Rk9X-AWDNF9nbvYYnqsoY",
      description: "Enjoy the English Bible Messages."
    },
    {
      title: "Tamil Bible Message",
      id: "PLlfpe0DDwzp1V1_PA3yB04A-AHAJ55hfn",
      description: "Top trending Tamil Bible Messages."
    }
    ,
    {
      title: "Bible Chapter Summary in 3 Minutes - English",
      id: "PLlfpe0DDwzp2JTpFvINtyMI1307E83Wa1",
      description: "A collection of Bible Chapter Summary in 3 Minutes - English."
    }
    ,
    {
      title: "Kids Bible Chapter Highlights",
      id: "PLlfpe0DDwzp3wxJwEfF6bnH8m2VReVs-y",
      description: "A collection of English  Kids Bible Messages."
    }

    ,
    {
      title: "Holy Reels  - English Bible",
      id: "PLlfpe0DDwzp0qyYeMRgfawc-JSarbF05P",
      description: "A collection of visual Bible highlights."
    }
    ,


    
    {
      title: "Spiritual Guidance & Bible Stories",
      id: "PLlfpe0DDwzp1B7MLw39wOhpBcZzkbum4U",
      description: "A collection of Spiritual Guidance & Bible Stories."
    }

    ,
    {
      title: "Prayers & Spiritual Wisdom",
      id: "PLlfpe0DDwzp0JVlMGCrz1GILAWjl-TQjI",
      description: "A collection of Prayers & Spiritual Wisdom."
    },

    {
      title: "Malayalam Bible Message",
      id: "PLlfpe0DDwzp1-FZLlJ8pt4IOM87D8fBdC",
      description: "A collection of Malayalam Bible Message."
    }
   ]);

  const [currentPlaylistIndex, setCurrentPlaylistIndex] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);

  useEffect(() => {
    if (currentPlaylistIndex !== null) {
      setVideoUrl(`https://www.youtube.com/embed/videoseries?list=${playlists[currentPlaylistIndex].id}&autoplay=1&mute=0`);
    }
  }, [currentPlaylistIndex, playlists]);

  return (
    <div className="media-container">
      <div className="media-header">
        <h1 className="media-heading">
          <FaChalkboardTeacher className="media-icon" /> Messages
        </h1>
        <p className="media-intro">Watch Bible messages!</p>
      </div>
      {currentPlaylistIndex === null ? (
        <div className="playlist-grid">
          {playlists.map((playlist, index) => (
            <div
              key={index}
              className="playlist-item"
              onClick={() => setCurrentPlaylistIndex(index)}
            >
              <span className="icon"><FaPlayCircle /></span>
              <h3>{playlist.title}</h3>
              <p>{playlist.description}</p>
            </div>
          ))}
        </div>
      ) : (
        <div className="video-container">
          <iframe
            src={videoUrl}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
          ></iframe>
          <button className="back-button" onClick={() => setCurrentPlaylistIndex(null)}>
            Back to Playlists
          </button>
        </div>
      )}
    </div>
  );
}