// src/components/UnderDevelopment.jsx
import React from "react";
import { FaTools, FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./UnderDevelopment.css";

function UnderDevelopment({ pageName }) {
  return (
    <div className="under-dev-container">
      
      {/* Main Content */}
      <section className="under-dev-content">
        <FaTools className="under-dev-icon" />
        <h1>{pageName} - Under Development</h1>
        <p>
          We're working hard to bring you this feature. Please check back later!
        </p>
      </section>
      
      {/* Navigation Link Back */}
      <section className="navigation-link-back">
        <Link to="/" className="cta-button" aria-label="Back to Home">
          <FaArrowLeft className="back-icon" /> Back to Home
        </Link>
      </section>
      
    </div>
  );
}

export default UnderDevelopment;
