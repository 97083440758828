import React, { useState } from "react";
import YouTube from "react-youtube";
import SEO from "./SEO";

const YouTubeVocalRemover = () => {
  const [videoId, setVideoId] = useState("");
  const [inputUrl, setInputUrl] = useState("");
  const [audioUrl, setAudioUrl] = useState("");
  const [audioElement, setAudioElement] = useState(null);

  // Extract the YouTube video ID from the URL
  const extractVideoId = (url) => {
    const match = url.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
    );
    return match ? match[1] : "";
  };

  // Mute the YouTube player when ready
  const onPlayerReady = (event) => {
    event.target.mute();
  };

  // Handle video URL submission
  const handleUrlSubmit = async () => {
    const id = extractVideoId(inputUrl);
    if (!id) {
      alert("Invalid YouTube URL");
      return;
    }
    setVideoId(id);
    await fetchAudio(id);
  };

  // Fetch audio HTML via ThingProxy from an alternative endpoint (yt-download.org)
  const fetchAudio = async (id) => {
    try {
      // Use alternative endpoint that provides a download page for MP3
      const apiUrl = `https://www.yt-download.org/api/button/mp3/${id}`;
      // Use ThingProxy to bypass CORS (free proxy)
      const proxyUrl = "https://thingproxy.freeboard.io/fetch/";
      const response = await fetch(proxyUrl + encodeURIComponent(apiUrl));
      if (!response.ok) throw new Error("Failed to fetch audio");
      const html = await response.text();
      console.log("Fetched HTML:", html);

      // Parse the returned HTML to find the first link ending with ".mp3"
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");
      const mp3Anchor = doc.querySelector("a[href$='.mp3']");
      if (mp3Anchor && mp3Anchor.href) {
        setAudioUrl(mp3Anchor.href);
      } else {
        throw new Error("MP3 link not found");
      }
    } catch (error) {
      console.error("Audio fetching failed:", error);
      alert("Error fetching audio. Try another video.");
    }
  };

  // Process the audio to remove vocals using phase cancellation
  const processAudio = () => {
    if (!audioElement) return;

    // Create a new AudioContext on a user gesture (button click)
    const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    if (audioCtx.state === "suspended") {
      audioCtx.resume();
    }
    const source = audioCtx.createMediaElementSource(audioElement);
    const splitter = audioCtx.createChannelSplitter(2);
    const merger = audioCtx.createChannelMerger(2);
    const gainL = audioCtx.createGain();
    const gainR = audioCtx.createGain();

    // Invert the right channel to cancel centered vocals
    gainR.gain.value = -1;

    // Connect nodes: left channel directly and right channel inverted, then merge
    source.connect(splitter);
    splitter.connect(gainL, 0);
    splitter.connect(gainR, 1);
    gainL.connect(merger, 0, 0);
    gainR.connect(merger, 0, 1);
    merger.connect(audioCtx.destination);

    audioElement.play();
  };

  return (
    <div>
      <h2>YouTube Vocal Remover</h2>
      <input
        type="text"
        placeholder="Enter YouTube URL"
        value={inputUrl}
        onChange={(e) => setInputUrl(e.target.value)}
      />
      <button onClick={handleUrlSubmit}>Load Video</button>

      {videoId && (
        <YouTube
          videoId={videoId}
          opts={{ playerVars: { autoplay: 1, controls: 1, mute: 1 } }}
          onReady={onPlayerReady}
        />
      )}

      {audioUrl && (
        <div>
          <audio
            controls
            src={audioUrl}
            onLoadedData={(e) => setAudioElement(e.target)}
          />
          <button onClick={processAudio}>Remove Vocals</button>
        </div>
      )}
    </div>
  );
};

export default YouTubeVocalRemover;
