// src/components/LiveStreaming.jsx
import React from "react";
import { FaYoutube, FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./LiveStreaming.css";
import SEO from './SEO';


function LiveStreaming() {
  return (
    <div className="live-streaming-container">
      
      {/* Header Section */}
      <section className="live-streaming-header">
        <h1 className="live-streaming-heading">
          <FaYoutube className="live-streaming-icon" /> Live Streaming
        </h1>
        <p className="live-streaming-intro">
          Join us live on YouTube for worship sessions, sermons, and community gatherings.
        </p>
      </section>
      
      {/* Embedded YouTube Channel */}
      <section className="youtube-embed">
        <iframe
          title="GCC Worship YouTube Channel"
          width="100%"
          height="500"
          src="https://www.youtube.com/embed/live_stream?channel=gcc-worship"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          aria-label="YouTube live stream of GCC Worship"
        ></iframe>
      </section>
      
      {/* Navigation Link Back */}
      <section className="navigation-link-back">
        <Link to="/" className="cta-button" aria-label="Back to Home">
          <FaArrowLeft className="back-icon" /> Back to Home
        </Link>
      </section>
      
    </div>
  );
}

export default LiveStreaming;
