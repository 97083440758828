// src/components/SmallGroups.jsx
import React, { useState } from "react";
import { FaUsers, FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./SmallGroups.css";
import SEO from './SEO';


function SmallGroups() {
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [region, setRegion] = useState("");

  // Mock data for countries, states, and regions
  const countries = ["United States", "Canada", "United Kingdom"];
  const statesUSA = ["California", "Texas", "New York"];
  const regionsUSA = ["North", "South", "East", "West"];

  const statesCanada = ["Ontario", "Quebec", "British Columbia"];
  const regionsCanada = ["Central", "Eastern", "Western"];

  const statesUK = ["England", "Scotland", "Wales"];
  const regionsUK = ["Northern", "Southern", "Eastern", "Western"];

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    setState("");
    setRegion("");
  };

  const handleStateChange = (e) => {
    setState(e.target.value);
    setRegion("");
  };

  const handleRegionChange = (e) => {
    setRegion(e.target.value);
  };

  const getStates = () => {
    switch (country) {
      case "United States":
        return statesUSA;
      case "Canada":
        return statesCanada;
      case "United Kingdom":
        return statesUK;
      default:
        return [];
    }
  };

  const getRegions = () => {
    switch (country) {
      case "United States":
        return regionsUSA;
      case "Canada":
        return regionsCanada;
      case "United Kingdom":
        return regionsUK;
      default:
        return [];
    }
  };

  const handleSearch = () => {
    // Implement search functionality or redirect to Meetup groups based on selections
    alert(`Searching groups in ${region}, ${state}, ${country}`);
  };

  return (
    <div className="small-groups-container">
      
      {/* Header Section */}
      <section className="small-groups-header">
        <h1 className="small-groups-heading">
          <FaUsers className="small-groups-icon" /> Small Groups
        </h1>
        <p className="small-groups-intro">
          Find and join Christian small groups in your area to grow in faith and community.
        </p>
      </section>
      
      {/* Selection Form */}
      <section className="small-groups-form">
        <div className="form-group">
          {/* Country Selector */}
          <div className="form-field">
            <label htmlFor="country">Country:</label>
            <select id="country" value={country} onChange={handleCountryChange}>
              <option value="">Select Country</option>
              {countries.map((c) => (
                <option key={c} value={c}>{c}</option>
              ))}
            </select>
          </div>
          
          {/* State Selector */}
          <div className="form-field">
            <label htmlFor="state">State/Province:</label>
            <select id="state" value={state} onChange={handleStateChange} disabled={!country}>
              <option value="">Select State/Province</option>
              {getStates().map((s) => (
                <option key={s} value={s}>{s}</option>
              ))}
            </select>
          </div>
          
          {/* Region Selector */}
          <div className="form-field">
            <label htmlFor="region">Region:</label>
            <select id="region" value={region} onChange={handleRegionChange} disabled={!state}>
              <option value="">Select Region</option>
              {getRegions().map((r) => (
                <option key={r} value={r}>{r}</option>
              ))}
            </select>
          </div>
        </div>
        
        {/* Search Button */}
        <button className="search-button" onClick={handleSearch} disabled={!country || !state || !region}>
          Search Groups
        </button>
      </section>
      
      {/* Navigation Link Back */}
      <section className="navigation-link-back">
        <Link to="/" className="cta-button" aria-label="Back to Home">
          <FaArrowLeft className="back-icon" /> Back to Home
        </Link>
      </section>
      
    </div>
  );
}

export default SmallGroups;
