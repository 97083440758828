// src/components/SocialMedia.jsx
import React from "react";
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube, FaShareAlt, FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./SocialMedia.css"; // Import the corresponding CSS
import SEO from './SEO';


function SocialMedia() {
  const shareUrl = encodeURIComponent(window.location.href);
  const shareText = encodeURIComponent("Join our Christian community and stay updated with the latest events and inspirational content!");

  return (
    <div className="social-media-container">
      
      {/* Header Section */}
      <section className="social-media-header">
        <h1 className="social-media-heading">
          <FaShareAlt className="social-media-icon" /> Social Media
        </h1>
        <p className="social-media-intro">
          Join our community on social media to stay updated with the latest events, discussions, and inspirational content.
        </p>
      </section>
      
      {/* Social Media Links */}
      <section className="social-media-links">
        <h2 className="social-links-heading">Follow Us</h2>
        <div className="social-media-grid">
          {/* Facebook */}
          <a href="https://www.facebook.com/gccworship" target="_blank" rel="noopener noreferrer" className="social-tile" aria-label="Facebook">
            <FaFacebookF className="social-icon facebook" />
            <span className="social-text">Facebook</span>
          </a>
          
          {/* Twitter */}
          <a href="https://twitter.com/gccworship" target="_blank" rel="noopener noreferrer" className="social-tile" aria-label="Twitter">
            <FaTwitter className="social-icon twitter" />
            <span className="social-text">Twitter</span>
          </a>
          
          {/* Instagram */}
          <a href="https://www.instagram.com/gccworship" target="_blank" rel="noopener noreferrer" className="social-tile" aria-label="Instagram">
            <FaInstagram className="social-icon instagram" />
            <span className="social-text">Instagram</span>
          </a>
          
          {/* YouTube */}
          <a href="https://www.youtube.com/@gcc-worship" target="_blank" rel="noopener noreferrer" className="social-tile" aria-label="YouTube">
            <FaYoutube className="social-icon youtube" />
            <span className="social-text">YouTube</span>
          </a>
        </div>
      </section>
      
      {/* Share Buttons */}
      <section className="share-buttons">
        <h2 className="share-buttons-heading">Share Our Community</h2>
        <div className="share-buttons-container">
          <button className="share-button facebook-share" onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`, '_blank')} aria-label="Share on Facebook">
            <FaFacebookF className="share-icon" />
            Share on Facebook
          </button>
          <button className="share-button twitter-share" onClick={() => window.open(`https://twitter.com/intent/tweet?url=${shareUrl}&text=${shareText}`, '_blank')} aria-label="Share on Twitter">
            <FaTwitter className="share-icon" />
            Share on Twitter
          </button>
          <button className="share-button instagram-share" onClick={() => alert('Please share manually on Instagram.')} aria-label="Share on Instagram">
            <FaInstagram className="share-icon" />
            Share on Instagram
          </button>
          <button className="share-button youtube-share" onClick={() => window.open(`https://www.youtube.com/share?url=${shareUrl}`, '_blank')} aria-label="Share on YouTube">
            <FaYoutube className="share-icon" />
            Share on YouTube
          </button>
        </div>
      </section>
      
      {/* Navigation Link Back */}
      <section className="navigation-link-back">
        <Link to="/" className="cta-button" aria-label="Back to Home">
          <FaArrowLeft className="back-icon" /> Back to Home
        </Link>
      </section>
      
    </div>
  );
}

export default SocialMedia;
