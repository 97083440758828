// src/components/Volunteer.jsx
import React from "react";
import { FaHandsHelping, FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Volunteer.css";
import SEO from './SEO';


function Volunteer() {
  return (
    <div className="volunteer-container">
      
      {/* Header Section */}
      <section className="volunteer-header">
        <h1 className="volunteer-heading">
          <FaHandsHelping className="volunteer-icon" /> Volunteer
        </h1>
        <p className="volunteer-intro">
          Join us in serving our global Christian community. Discover various volunteer roles and make a meaningful impact.
        </p>
      </section>
      
      {/* Volunteer Roles */}
      <section className="volunteer-roles">
        <h2>Available Volunteer Roles</h2>
        <div className="roles-grid">
          <div className="role-card">
            <h3>Event Coordinator</h3>
            <p>
              Assist in organizing and managing community events, ensuring smooth execution and participant satisfaction.
            </p>
          </div>
          <div className="role-card">
            <h3>Content Creator</h3>
            <p>
              Create engaging content for our social media platforms, website, and newsletters to inspire and inform our community.
            </p>
          </div>
          <div className="role-card">
            <h3>Technical Support</h3>
            <p>
              Provide technical assistance for live streams, website maintenance, and other IT-related tasks to keep our services running smoothly.
            </p>
          </div>
          <div className="role-card">
            <h3>Community Outreach</h3>
            <p>
              Engage with local communities, build relationships, and promote our mission through outreach programs and initiatives.
            </p>
          </div>
        </div>
      </section>
      
      {/* Nominate Yourself */}
      <section className="volunteer-nominate">
        <h2>Nominate Yourself to Volunteer</h2>
        <p>
          Ready to make a difference? Nominate yourself for a volunteer role by filling out our online form.
        </p>
        <button className="nominate-button" onClick={() => window.open('https://forms.gle/your-volunteer-form-link', '_blank')}>
          Nominate as a Volunteer
        </button>
      </section>
      
      {/* How We Support Volunteers */}
      <section className="volunteer-support">
        <h2>How We Support Our Volunteers</h2>
        <ul>
          <li>Comprehensive training and resources</li>
          <li>Ongoing support from experienced mentors</li>
          <li>Opportunities for personal and spiritual growth</li>
          <li>Recognition and appreciation for your contributions</li>
        </ul>
      </section>
      
      {/* Navigation Link Back */}
      <section className="navigation-link-back">
        <Link to="/" className="cta-button" aria-label="Back to Home">
          <FaArrowLeft className="back-icon" /> Back to Home
        </Link>
      </section>
      
    </div>
  );
}

export default Volunteer;
