// src/components/DJMusicMixer.jsx
import React, { useState, useEffect, useRef } from "react";
import * as Tone from "tone";
import SEO from "./SEO";

// Working music library (only tracks that are known to work)
const musicLibrary = {
  // Bensound tracks (20)
  "Bensound - Acoustic Breeze": "https://www.bensound.com/bensound-music/bensound-acousticbreeze.mp3",
  "Bensound - Better Days": "https://www.bensound.com/bensound-music/bensound-betterdays.mp3",
  "Bensound - Creative Minds": "https://www.bensound.com/bensound-music/bensound-creativeminds.mp3",
  "Bensound - Energy": "https://www.bensound.com/bensound-music/bensound-energy.mp3",
  "Bensound - Once Again": "https://www.bensound.com/bensound-music/bensound-onceagain.mp3",
  "Bensound - Slow Motion": "https://www.bensound.com/bensound-music/bensound-slowmotion.mp3",
  "Bensound - Sunny": "https://www.bensound.com/bensound-music/bensound-sunny.mp3",
  "Bensound - Tenderness": "https://www.bensound.com/bensound-music/bensound-tenderness.mp3",
  "Bensound - The Jazz Piano": "https://www.bensound.com/bensound-music/bensound-thejazzpiano.mp3",
  "Bensound - Ukulele": "https://www.bensound.com/bensound-music/bensound-ukulele.mp3",
  "Bensound - Summer": "https://www.bensound.com/bensound-music/bensound-summer.mp3",
  "Bensound - Epic": "https://www.bensound.com/bensound-music/bensound-epic.mp3",
  "Bensound - Funny Song": "https://www.bensound.com/bensound-music/bensound-funnysong.mp3",
  "Bensound - Jazzy Frenchy": "https://www.bensound.com/bensound-music/bensound-jazzyfrenchy.mp3",
  "Bensound - Little Idea": "https://www.bensound.com/bensound-music/bensound-littleidea.mp3",
  "Bensound - Romance": "https://www.bensound.com/bensound-music/bensound-romance.mp3",
  "Bensound - The Lounge": "https://www.bensound.com/bensound-music/bensound-thelounge.mp3",
  "Bensound - Hip Jazz": "https://www.bensound.com/bensound-music/bensound-hipjazz.mp3",
  "Bensound - Dreams": "https://www.bensound.com/bensound-music/bensound-dreams.mp3",
  "Bensound - Adrenaline": "https://www.bensound.com/bensound-music/bensound-adrenaline.mp3",
  // Incompetech tracks (10)
  "Incompetech - Carefree": "https://incompetech.com/music/royalty-free/mp3-royaltyfree/Carefree.mp3",
  "Incompetech - Cipher": "https://incompetech.com/music/royalty-free/mp3-royaltyfree/Cipher.mp3",
  "Incompetech - Funkorama": "https://incompetech.com/music/royalty-free/mp3-royaltyfree/Funkorama.mp3",
  "Incompetech - Monkeys Spinning Monkeys": "https://incompetech.com/music/royalty-free/mp3-royaltyfree/MonkeysSpinningMonkeys.mp3",
  "Incompetech - Scheming Weasel": "https://incompetech.com/music/royalty-free/mp3-royaltyfree/SchemingWeasel.mp3",
  "Incompetech - Sneaky Snitch": "https://incompetech.com/music/royalty-free/mp3-royaltyfree/SneakySnitch.mp3",
  "Incompetech - Secret Agent": "https://incompetech.com/music/royalty-free/mp3-royaltyfree/SecretAgent.mp3",
  "Incompetech - Impact Moderato": "https://incompetech.com/music/royalty-free/mp3-royaltyfree/ImpactModerato.mp3",
  "Incompetech - Energy Flow": "https://incompetech.com/music/royalty-free/mp3-royaltyfree/EnergyFlow.mp3",
  "Incompetech - Division": "https://incompetech.com/music/royalty-free/mp3-royaltyfree/Division.mp3",
  // Archive.org tracks (4)
  "Archive - Cylinder Five": "https://archive.org/download/ChrisZabriskie_070/Cylinder_Five.mp3",
  "Archive - Prelude No. 23": "https://archive.org/download/ChrisZabriskie_070/Prelude_No._23.mp3",
  "Archive - The Ambient World": "https://archive.org/download/ChrisZabriskie_070/The_Ambient_World.mp3",
  "Archive - Impact": "https://archive.org/download/ChrisZabriskie_070/Impact.mp3",
  // SoundHelix tracks (5)
  "SoundHelix Song 1": "http://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
  "SoundHelix Song 2": "http://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3",
  "SoundHelix Song 3": "http://www.soundhelix.com/examples/mp3/SoundHelix-Song-3.mp3",
  "SoundHelix Song 4": "http://www.soundhelix.com/examples/mp3/SoundHelix-Song-4.mp3",
  "SoundHelix Song 5": "http://www.soundhelix.com/examples/mp3/SoundHelix-Song-5.mp3",
  // Hochmuth tracks (3)
  "Hochmuth - Haydn Cello Concerto": "http://www.hochmuth.com/mp3/Haydn_Cello_Concerto_D-1.mp3",
  "Hochmuth - Bloch Prayer": "http://www.hochmuth.com/mp3/Bloch_Prayer.mp3",
  "Hochmuth - Boccherini Concerto": "http://www.hochmuth.com/mp3/Boccherini_Concerto_478-1.mp3"
};

// Define overlay sounds (now 30 working sounds)
const overlaySounds = [
  { title: "Drum Hit", url: "https://www.myinstants.com/media/sounds/drum-hit.mp3" },
  { title: "Clap", url: "https://www.myinstants.com/media/sounds/clap.mp3" },
  { title: "Guitar Riff", url: "https://www.myinstants.com/media/sounds/guitar-riff.mp3" },
  { title: "Piano Chord", url: "https://www.myinstants.com/media/sounds/piano-chord.mp3" },
  { title: "Sax Solo", url: "https://www.myinstants.com/media/sounds/sax-solo.mp3" },
  { title: "Violin Stroke", url: "https://www.myinstants.com/media/sounds/violin-stroke.mp3" },
  { title: "Synth Pad", url: "https://www.myinstants.com/media/sounds/synth-pad.mp3" },
  { title: "Electronic Beat", url: "https://www.myinstants.com/media/sounds/electronic-beat.mp3" },
  { title: "Orchestral Hit", url: "https://www.myinstants.com/media/sounds/orchestral-hit.mp3" },
  { title: "Bass Drop", url: "https://www.myinstants.com/media/sounds/bass-drop.mp3" },
  { title: "Crash Cymbal", url: "https://www.myinstants.com/media/sounds/crash-cymbal.mp3" },
  { title: "Hi-Hat", url: "https://www.myinstants.com/media/sounds/hi-hat.mp3" },
  { title: "Snare", url: "https://www.myinstants.com/media/sounds/snare.mp3" },
  { title: "Kick", url: "https://www.myinstants.com/media/sounds/kick.mp3" },
  { title: "Percussion", url: "https://www.myinstants.com/media/sounds/percussion.mp3" },
  { title: "Ambient Pad", url: "https://www.myinstants.com/media/sounds/ambient-pad.mp3" },
  { title: "String Swell", url: "https://www.myinstants.com/media/sounds/string-swell.mp3" },
  { title: "Wind Chime", url: "https://www.myinstants.com/media/sounds/wind-chime.mp3" },
  { title: "Bell", url: "https://www.myinstants.com/media/sounds/bell.mp3" },
  { title: "Electric Guitar Strum", url: "https://www.myinstants.com/media/sounds/electric-guitar-strum.mp3" },
  { title: "Acoustic Guitar Strum", url: "https://www.myinstants.com/media/sounds/acoustic-guitar-strum.mp3" },
  { title: "Organ Chord", url: "https://www.myinstants.com/media/sounds/organ-chord.mp3" },
  { title: "Drum Roll", url: "https://www.myinstants.com/media/sounds/drum-roll.mp3" },
  { title: "Crowd Cheering", url: "https://sample-videos.com/audio/mp3/crowd-cheering.mp3" },
  { title: "Foot Stomp", url: "https://www.myinstants.com/media/sounds/foot-stomp.mp3" },
  { title: "Hand Clap", url: "https://www.myinstants.com/media/sounds/hand-clap.mp3" },
  { title: "Trumpet Fanfare", url: "https://www.myinstants.com/media/sounds/trumpet-fanfare.mp3" },
  { title: "Flute Melody", url: "https://www.myinstants.com/media/sounds/flute-melody.mp3" },
  { title: "Synth Arpeggio", url: "https://www.myinstants.com/media/sounds/synth-arpeggio.mp3" },
  { title: "Vocal Hit", url: "https://www.myinstants.com/media/sounds/vocal-hit.mp3" },
    // 20 additional overlay sounds
  { title: "Percussive Hit", url: "https://www.myinstants.com/media/sounds/percussive-hit.mp3" },
  { title: "Electric Slide", url: "https://www.myinstants.com/media/sounds/electric-slide.mp3" },
  { title: "Deep Bass", url: "https://www.myinstants.com/media/sounds/deep-bass.mp3" },
  { title: "Chime Sequence", url: "https://www.myinstants.com/media/sounds/chime-sequence.mp3" },
  { title: "Funky Beat", url: "https://www.myinstants.com/media/sounds/funky-beat.mp3" },
  { title: "Soft Pad", url: "https://www.myinstants.com/media/sounds/soft-pad.mp3" },
  { title: "Loud Crash", url: "https://www.myinstants.com/media/sounds/loud-crash.mp3" },
  { title: "Rising Sweep", url: "https://www.myinstants.com/media/sounds/rising-sweep.mp3" },
  { title: "Flicker", url: "https://www.myinstants.com/media/sounds/flicker.mp3" },
  { title: "Pulse", url: "https://www.myinstants.com/media/sounds/pulse.mp3" },
  { title: "Glitch", url: "https://www.myinstants.com/media/sounds/glitch.mp3" },
  { title: "Snap", url: "https://www.myinstants.com/media/sounds/snap.mp3" },
  { title: "Beat Drop", url: "https://www.myinstants.com/media/sounds/beat-drop.mp3" },
  { title: "Loop Kick", url: "https://www.myinstants.com/media/sounds/loop-kick.mp3" },
  { title: "Ambient Click", url: "https://www.myinstants.com/media/sounds/ambient-click.mp3" },
  { title: "Futuristic Blip", url: "https://www.myinstants.com/media/sounds/futuristic-blip.mp3" },
  { title: "Electronic Pulse", url: "https://www.myinstants.com/media/sounds/electronic-pulse.mp3" },
  { title: "Rhythmic Snap", url: "https://www.myinstants.com/media/sounds/rhythmic-snap.mp3" },
  { title: "Space Whistle", url: "https://www.myinstants.com/media/sounds/space-whistle.mp3" },
  { title: "Sub Bass", url: "https://www.myinstants.com/media/sounds/sub-bass.mp3" },
  { title: "Chill Vibe", url: "https://www.myinstants.com/media/sounds/chill-vibe.mp3" }

];

// Sample options for custom chords and melody
const sampleChords = [
  "C4,E4,G4; G3,B3,D4",
  "Am,C,F; Dm,F,A",
  "F4,A4,C5; G4,B4,D5"
];
const sampleMelodies = [
  "E4,G4,A4,G4,E4,D4,C4",
  "C4,D4,E4,F4,G4",
  "A3,C4,E4,C4,A3"
];

const DJMusicMixer = () => {
  // State for search filter
  const [searchTerm, setSearchTerm] = useState("");

  // Panel visibility states
  const [showBgControls, setShowBgControls] = useState(true);
  const [showOverlay, setShowOverlay] = useState(true);
  const [showCustomMix, setShowCustomMix] = useState(true);

  // Background music state and ref
  const [selectedTrack, setSelectedTrack] = useState(null);
  const [isBgPlaying, setIsBgPlaying] = useState(false);
  const [bgTempo, setBgTempo] = useState(1.0); // playbackRate (1 = normal)
  const bgAudioRef = useRef(null);

  // Mixer controls state
  const [tempo, setTempo] = useState(100);
  const [volume, setVolume] = useState(0); // dB slider (-60 to 0)
  const [customChords, setCustomChords] = useState("");
  const [customMelody, setCustomMelody] = useState("");
  const [instruments, setInstruments] = useState({
    drums: false,
    flute: false,
    violin: false,
    guitar: false,
  });
  const [reverbAmount, setReverbAmount] = useState(0.3);
  const [attack, setAttack] = useState(0.05);
  const [release, setRelease] = useState(1);

  // Refs for Tone.js overlay synths
  const melodySynthRef = useRef(null);
  const chordSynthRef = useRef(null);

  // Update background playback rate when bgTempo changes
  useEffect(() => {
    if (bgAudioRef.current) {
      bgAudioRef.current.playbackRate = bgTempo;
    }
  }, [bgTempo]);

  // Filter music library based on search term
  const filteredTracks = Object.keys(musicLibrary).filter((trackName) =>
    trackName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Play a selected track from the music library
  const playTrack = (trackName) => {
    if (bgAudioRef.current) {
      bgAudioRef.current.pause();
      bgAudioRef.current = null;
    }
    const url = musicLibrary[trackName];
    if (url) {
      const audio = new Audio(url);
      audio.loop = true;
      // Map volume (-60 to 0 dB) to normalized volume (0 to 0.3)
      audio.volume = ((volume + 60) / 60) * 0.3;
      audio.playbackRate = bgTempo;
      bgAudioRef.current = audio;
      audio
        .play()
        .then(() => {
          setSelectedTrack(trackName);
          setIsBgPlaying(true);
        })
        .catch((err) => console.error("Error playing track:", err));
    }
  };

  const stopTrack = () => {
    if (bgAudioRef.current) {
      bgAudioRef.current.pause();
      bgAudioRef.current = null;
    }
    setIsBgPlaying(false);
  };

  // Play a ready-made overlay sound (one-shot)
  const playOverlaySound = (sound) => {
    if (!sound || !sound.url) return;
    const overlayAudio = new Audio(sound.url);
    overlayAudio.play().catch((err) => console.error("Overlay sound error:", err));
  };

  // Start custom overlay mix using Tone.js (for chords/melody)
  const startMixing = async () => {
    await Tone.start();
    if (!melodySynthRef.current) {
      melodySynthRef.current = new Tone.Synth({
        oscillator: { type: "sine" },
        envelope: { attack, release },
      }).toDestination();
    }
    if (!chordSynthRef.current) {
      chordSynthRef.current = new Tone.PolySynth(Tone.Synth, {
        oscillator: { type: "triangle" },
        envelope: { attack, release },
      }).toDestination();
    }
    Tone.Transport.bpm.value = tempo;
    const chords = customChords.trim()
      ? customChords.split(";").map((str) => ({
          notes: str.split(",").map((n) => n.trim()),
          duration: "2n",
        }))
      : [];
    const melody = customMelody.trim()
      ? customMelody.split(",").map((n) => n.trim())
      : [];
    Tone.Transport.cancel();
    if (chords.length > 0) {
      let chordIndex = 0;
      Tone.Transport.scheduleRepeat((time) => {
        const chord = chords[chordIndex % chords.length];
        chordSynthRef.current.triggerAttackRelease(chord.notes, chord.duration, time);
        chordIndex++;
      }, "2n");
    }
    if (melody.length > 0) {
      let melodyIndex = 0;
      Tone.Transport.scheduleRepeat((time) => {
        const note = melody[melodyIndex % melody.length];
        melodySynthRef.current.triggerAttackRelease(note, "8n", time);
        melodyIndex++;
      }, "4n");
    }
    Tone.Transport.start();
  };

  const stopMixing = () => {
    Tone.Transport.stop();
    Tone.Transport.cancel();
    if (melodySynthRef.current) {
      melodySynthRef.current.dispose();
      melodySynthRef.current = null;
    }
    if (chordSynthRef.current) {
      chordSynthRef.current.dispose();
      chordSynthRef.current = null;
    }
  };

  // Render music library tiles
  const renderTiles = () => {
    return filteredTracks.map((trackName) => (
      <div
        key={trackName}
        onClick={() => playTrack(trackName)}
        className="mix-tile"
      >
        {trackName}
      </div>
    ));
  };

  // Render overlay sound buttons
  const renderOverlayButtons = () => {
    return overlaySounds.map((sound) => (
      <div
        key={sound.title}
        onClick={() => playOverlaySound(sound)}
        className="overlay-btn"
      >
        {sound.title}
      </div>
    ));
  };

  return (
    <div style={{ padding: "1rem", backgroundColor: "#111", color: "#fff" }}>
      {/* Inline CSS for hover effects, buttons, tiles, and compact panels */}
      <style>{`
        .mix-btn {
          padding: 0.5rem 1rem;
          margin: 0.5rem 0;
          background-color: #27ae60;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          transition: background-color 0.3s;
        }
        .mix-btn:hover {
          background-color: #f1c40f;
        }
        .mix-tile {
          padding: 0.75rem;
          background-color: ${"var(--tile-bg, #222)"};
          color: #fff;
          cursor: pointer;
          text-align: center;
          border-radius: 8px;
          box-shadow: 0 2px 6px rgba(0,0,0,0.5);
          transition: background-color 0.3s;
        }
        .mix-tile:hover {
          background-color: #f1c40f;
        }
        .overlay-btn {
          padding: 0.5rem;
          background-color: #444;
          color: #fff;
          text-align: center;
          border-radius: 4px;
          cursor: pointer;
          transition: background-color 0.3s;
        }
        .overlay-btn:hover {
          background-color: #f1c40f;
        }
        .panel-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #333;
          padding: 0.5rem;
          cursor: pointer;
          border-radius: 4px;
        }
        .panel-content {
          padding: 0.5rem;
          border: 1px solid #333;
          border-top: none;
          margin-bottom: 1rem;
        }
        .compact-controls {
          display: flex;
          flex-wrap: wrap;
          gap: 0.5rem;
          font-size: 0.9rem;
          align-items: center;
        }
        .compact-controls > * {
          flex: 1 1 150px;
        }
      `}</style>
      <SEO
        title="DJ Music Mixer"
        description="A DJ-style mixer for background worship music featuring a searchable library, overlay sounds, and compact, collapsible controls with sample presets."
      />
      <h1 style={{ textAlign: "center" }}>DJ Music Mixer</h1>

      {/* Music Library with Search */}
      <section>
        <h2>Music Library</h2>
        <input
          type="text"
          placeholder="Search music..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{
            width: "100%",
            padding: "0.5rem",
            marginBottom: "1rem",
            borderRadius: "4px",
            border: "1px solid #555",
            backgroundColor: "#222",
            color: "#fff",
          }}
        />
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
            gap: "1rem",
          }}
        >
          {renderTiles()}
        </div>
      </section>

      {/* Collapsible Panel: Background Music Controls */}
      <section>
        <div className="panel-header" onClick={() => setShowBgControls(!showBgControls)}>
          <span>Background Music Controls</span>
          <span>{showBgControls ? "▲" : "▼"}</span>
        </div>
        {showBgControls && (
          <div className="panel-content compact-controls">
            <button
              onClick={isBgPlaying ? stopTrack : () => playTrack(selectedTrack || "Bensound - Acoustic Breeze")}
              className="mix-btn"
            >
              {isBgPlaying ? "Stop Background" : "Play Background"}
            </button>
            <div>
              <label>Volume: {volume} dB</label><br />
              <input
                type="range"
                min="-60"
                max="0"
                value={volume}
                onChange={(e) => {
                  const vol = parseInt(e.target.value, 10);
                  setVolume(vol);
                  if (bgAudioRef.current)
                    bgAudioRef.current.volume = ((vol + 60) / 60) * 0.3;
                }}
                style={{ width: "100%" }}
              />
            </div>
            <div>
              <label>Background Tempo: {bgTempo.toFixed(2)}</label><br />
              <input
                type="range"
                min="0.5"
                max="2.0"
                step="0.05"
                value={bgTempo}
                onChange={(e) => setBgTempo(parseFloat(e.target.value))}
                style={{ width: "100%" }}
              />
            </div>
          </div>
        )}
      </section>

      {/* Collapsible Panel: Overlay Sounds */}
      <section>
        <div className="panel-header" onClick={() => setShowOverlay(!showOverlay)}>
          <span>Overlay Sounds</span>
          <span>{showOverlay ? "▲" : "▼"}</span>
        </div>
        {showOverlay && (
          <div className="panel-content">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(120px, 1fr))",
                gap: "1rem",
              }}
            >
              {renderOverlayButtons()}
            </div>
          </div>
        )}
      </section>

      {/* Collapsible Panel: Custom Mix Overlay */}
      <section>
        <div className="panel-header" onClick={() => setShowCustomMix(!showCustomMix)}>
          <span>Custom Mix Overlay</span>
          <span>{showCustomMix ? "▲" : "▼"}</span>
        </div>
        {showCustomMix && (
          <div className="panel-content">
            <div className="compact-controls">
              <div>
                <label>Tempo: {tempo} BPM</label><br />
                <input
                  type="range"
                  min="60"
                  max="180"
                  value={tempo}
                  onChange={(e) => {
                    const t = parseInt(e.target.value, 10);
                    setTempo(t);
                    Tone.Transport.bpm.value = t;
                  }}
                  style={{ width: "100%" }}
                />
              </div>
              <div>
                <label>Reverb: {reverbAmount}</label><br />
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.05"
                  value={reverbAmount}
                  onChange={(e) => setReverbAmount(parseFloat(e.target.value))}
                  style={{ width: "100%" }}
                />
              </div>
              <div>
                <label>Attack: {attack}</label><br />
                <input
                  type="range"
                  min="0.01"
                  max="1"
                  step="0.01"
                  value={attack}
                  onChange={(e) => setAttack(parseFloat(e.target.value))}
                  style={{ width: "100%" }}
                />
              </div>
              <div>
                <label>Release: {release}</label><br />
                <input
                  type="range"
                  min="0.5"
                  max="3"
                  step="0.1"
                  value={release}
                  onChange={(e) => setRelease(parseFloat(e.target.value))}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <div style={{ marginTop: "1rem" }}>
              <label>Custom Chords:</label>
              <br />
              <select
                onChange={(e) => setCustomChords(e.target.value)}
                style={{ width: "100%", padding: "0.5rem", marginBottom: "0.5rem" }}
              >
                <option value="">-- Select Sample Chords --</option>
                {sampleChords.map((option, idx) => (
                  <option key={idx} value={option}>{option}</option>
                ))}
              </select>
              <textarea
                value={customChords}
                onChange={(e) => setCustomChords(e.target.value)}
                placeholder='Or type custom chords (e.g., "C4,E4,G4; G3,B3,D4")'
                style={{ width: "100%", height: "50px", marginBottom: "0.5rem" }}
              />
              <label>Custom Melody:</label>
              <br />
              <select
                onChange={(e) => setCustomMelody(e.target.value)}
                style={{ width: "100%", padding: "0.5rem", marginBottom: "0.5rem" }}
              >
                <option value="">-- Select Sample Melody --</option>
                {sampleMelodies.map((option, idx) => (
                  <option key={idx} value={option}>{option}</option>
                ))}
              </select>
              <textarea
                value={customMelody}
                onChange={(e) => setCustomMelody(e.target.value)}
                placeholder='Or type custom melody (e.g., "E4, G4, A4, G4, E4, D4, C4")'
                style={{ width: "100%", height: "50px" }}
              />
            </div>
            <div style={{ marginTop: "1rem" }}>
              <button onClick={startMixing} className="mix-btn" style={{ marginRight: "0.5rem" }}>
                Start Custom Mix
              </button>
              <button onClick={stopMixing} className="mix-btn">
                Stop Custom Mix
              </button>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default DJMusicMixer;
