// src/components/BibleStudyTools.jsx
import React, { useState, useEffect } from "react";
import { FaSearch, FaBook, FaBookmark, FaTrash , FaChalkboardTeacher} from "react-icons/fa";
import { Link } from "react-router-dom";
import "./BibleStudyTools.css"; // Import the corresponding CSS
import SEO from './SEO';


function BibleStudyTools() {
  const [passage, setPassage] = useState("");
  const [version, setVersion] = useState("kjv"); // Default to King James Version
  const [bibleText, setBibleText] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [keywords, setKeywords] = useState(""); // Optional: For keyword highlighting
  const [bookmarks, setBookmarks] = useState(() => {
    // Retrieve bookmarks from localStorage if available
    const savedBookmarks = localStorage.getItem("bookmarks");
    return savedBookmarks ? JSON.parse(savedBookmarks) : [];
  });

  // Load saved preferences from localStorage
  useEffect(() => {
    const savedVersion = localStorage.getItem("preferredVersion");
    const savedKeywords = localStorage.getItem("highlightKeywords");
    if (savedVersion) setVersion(savedVersion);
    if (savedKeywords) setKeywords(savedKeywords);
  }, []);

  // Save preferences to localStorage
  useEffect(() => {
    localStorage.setItem("preferredVersion", version);
  }, [version]);

  useEffect(() => {
    localStorage.setItem("highlightKeywords", keywords);
  }, [keywords]);

  const fetchBiblePassage = async (query, selectedVersion = "kjv") => {
    setLoading(true);
    setError(null);
    setBibleText([]);

    try {
      const response = await fetch(
        `https://bible-api.com/${encodeURIComponent(query)}?translation=${selectedVersion}`
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();

      if (data.error) {
        throw new Error(data.error);
      }

      // Optional: Highlight keywords
      let formattedText = data.verses.map((verse) => {
        let verseText = verse.text;
        if (keywords.trim() !== "") {
          keywords.split(",").forEach((keyword) => {
            const regex = new RegExp(`\\b(${keyword.trim()})\\b`, "gi");
            verseText = verseText.replace(
              regex,
              `<span class="highlight">$1</span>`
            );
          });
        }
        return {
          id: `${verse.book_name} ${verse.chapter}:${verse.verse}`,
          text: verseText,
          verse: verse.verse,
          book: verse.book_name,
          chapter: verse.chapter,
        };
      });

      setBibleText(formattedText);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (passage.trim() !== "") {
      fetchBiblePassage(passage, version);
    }
  };

  const toggleBookmark = (passageRef) => {
    let updatedBookmarks;
    if (bookmarks.includes(passageRef)) {
      updatedBookmarks = bookmarks.filter((item) => item !== passageRef);
    } else {
      updatedBookmarks = [...bookmarks, passageRef];
    }
    setBookmarks(updatedBookmarks);
    localStorage.setItem("bookmarks", JSON.stringify(updatedBookmarks));
  };

  return (
    <div className="bible-study-container">
      {/* Header Section */}
      <section className="bible-study-header">
        <h1 className="bible-study-heading">
          <FaBook className="bible-study-icon" /> Bible Study
        </h1>
        <p className="bible-study-intro">
          Search and explore Bible passages to deepen your understanding and faith.
        </p>
      </section>

      {/* Search Bar */}
      <section className="bible-study-search">
        <form onSubmit={handleSearch} className="search-form" aria-label="Search Bible Passages">
          <div className="form-group">
            <input
              type="text"
              placeholder="Enter Bible passage (e.g., John 3:16)"
              value={passage}
              onChange={(e) => setPassage(e.target.value)}
              className="search-input"
              aria-label="Bible Passage Input"
            />
            <select
              value={version}
              onChange={(e) => setVersion(e.target.value)}
              className="version-select"
              aria-label="Select Bible Version"
            >
              <option value="kjv">KJV</option>
              <option value="niv">NIV</option>
              <option value="esv">ESV</option>
              <option value="nasb">NASB</option>
              {/* Add more versions as needed */}
            </select>
            {/* Optional: Keywords Input for Highlighting */}
            <input
              type="text"
              placeholder="Highlight keywords (comma separated)"
              value={keywords}
              onChange={(e) => setKeywords(e.target.value)}
              className="keywords-input"
              aria-label="Highlight Keywords Input"
            />
          </div>
          <button type="submit" className="cta-button" aria-label="Search">
            <FaSearch /> Search
          </button>
        </form>
      </section>

      {/* Loading Indicator */}
      {loading && (
        <section className="bible-study-status loading">
          <p>Loading passage...</p>
        </section>
      )}

      {/* Error Message */}
      {error && (
        <section className="bible-study-status error">
          <p>{error}</p>
        </section>
      )}

      {/* Bible Passage Display */}
      {!loading && !error && bibleText.length > 0 && (
        <section className="bible-study-display">
          <div className="bible-text">
            {bibleText.map((verse) => (
              <p key={verse.id}>
                <span className="verse">{verse.verse}</span>{" "}
                <span dangerouslySetInnerHTML={{ __html: verse.text }} />
                <button
                  className="copy-button"
                  onClick={() =>
                    navigator.clipboard.writeText(
                      `${verse.book} ${verse.chapter}:${verse.verse}`
                    )
                  }
                  aria-label={`Copy ${verse.book} ${verse.chapter}:${verse.verse}`}
                >
                  📋
                </button>
                <button
                  className="bookmark-button"
                  onClick={() =>
                    toggleBookmark(`${verse.book} ${verse.chapter}:${verse.verse}`)
                  }
                  aria-label={
                    bookmarks.includes(`${verse.book} ${verse.chapter}:${verse.verse}`)
                      ? `Remove bookmark for ${verse.book} ${verse.chapter}:${verse.verse}`
                      : `Add bookmark for ${verse.book} ${verse.chapter}:${verse.verse}`
                  }
                >
                  {bookmarks.includes(`${verse.book} ${verse.chapter}:${verse.verse}`) ? <FaBookmark /> : <FaChalkboardTeacher />}
                </button>
              </p>
            ))}
          </div>
        </section>
      )}

      {/* Bookmarks Section */}
      <section className="bookmarks-section">
        <h2>Bookmarked Passages</h2>
        {bookmarks.length === 0 ? (
          <p>No bookmarks yet.</p>
        ) : (
          <ul className="bookmarks-list">
            {bookmarks.map((bm, index) => (
              <li key={index}>
                <button
                  className="bookmark-link"
                  onClick={() => fetchBiblePassage(bm, version)}
                  aria-label={`View ${bm}`}
                >
                  {bm}
                </button>
                <button
                  className="remove-bookmark"
                  onClick={() => toggleBookmark(bm)}
                  aria-label={`Remove bookmark for ${bm}`}
                >
                  <FaTrash />
                </button>
              </li>
            ))}
          </ul>
        )}
      </section>

      {/* Navigation Link Back */}
      <section className="navigation-link-back">
        <Link to="/" className="cta-button" aria-label="Back to Home">
          <FaBook /> Back to Home
        </Link>
      </section>
    </div>
  );
}

export default BibleStudyTools;
