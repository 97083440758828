import React, { useState, useEffect, useRef } from "react";
import * as Tone from "tone";
import SEO from './SEO';

// List of styles
const styles = [
  "Contemporary Worship",
  "Traditional Hymnal",
  "Uplifting Gospel",
  "Reflective Praise",
  "Energetic Celebration",
  "Soulful R&B",
  "Ambient Spiritual",
  "Acoustic Ballad",
  "Jazz Worship",
  "Rock Praise",
  "Folk Hymn",
  "Classical Hymn",
  "We Wish You a Merry Christmas",
];

// Main chords & melody for each style
const styleData = {
  "Contemporary Worship": {
    chords: [
      { notes: ["C4", "E4", "G4"], duration: "2n" },
      { notes: ["G3", "B3", "D4"], duration: "2n" },
      { notes: ["A3", "C4", "E4"], duration: "2n" },
      { notes: ["F3", "A3", "C4"], duration: "2n" },
    ],
    melody: ["E4", "G4", "A4", "G4", "E4", "D4", "C4"],
  },
  "Traditional Hymnal": {
    chords: [
      { notes: ["F4", "A4", "C5"], duration: "2n" },
      { notes: ["C4", "E4", "G4"], duration: "2n" },
      { notes: ["D4", "F4", "A4"], duration: "2n" },
      { notes: ["Bb3", "D4", "F4"], duration: "2n" },
    ],
    melody: ["A4", "C5", "D5", "C5", "A4", "G4", "F4"],
  },
  "Uplifting Gospel": {
    chords: [
      { notes: ["D4", "F#4", "A4"], duration: "2n" },
      { notes: ["A3", "C#4", "E4"], duration: "2n" },
      { notes: ["Bm3", "D4", "F#4"], duration: "2n" },
      { notes: ["G3", "B3", "D4"], duration: "2n" },
    ],
    melody: ["F#4", "A4", "B4", "A4", "F#4", "E4", "D4"],
  },
  "Reflective Praise": {
    chords: [
      { notes: ["Am3", "C4", "E4"], duration: "2n" },
      { notes: ["F3", "A3", "C4"], duration: "2n" },
      { notes: ["G3", "B3", "D4"], duration: "2n" },
      { notes: ["Em3", "G3", "B3"], duration: "2n" },
    ],
    melody: ["C4", "E4", "F4", "E4", "C4", "B3", "A3"],
  },
  "Energetic Celebration": {
    chords: [
      { notes: ["G3", "B3", "D4"], duration: "2n" },
      { notes: ["D3", "F#3", "A3"], duration: "2n" },
      { notes: ["Em3", "G3", "B3"], duration: "2n" },
      { notes: ["C3", "E3", "G3"], duration: "2n" },
    ],
    melody: ["B3", "D4", "E4", "D4", "B3", "A3", "G3"],
  },
  "Soulful R&B": {
    chords: [
      { notes: ["Dm3", "F3", "A3"], duration: "2n" },
      { notes: ["G3", "Bb3", "D4"], duration: "2n" },
      { notes: ["Em3", "G3", "B3"], duration: "2n" },
      { notes: ["Am3", "C4", "E4"], duration: "2n" },
    ],
    melody: ["F3", "A3", "Bb3", "A3", "F3", "E3", "D3"],
  },
  "Ambient Spiritual": {
    chords: [
      { notes: ["C3", "E3", "G3"], duration: "1n" },
      { notes: ["E3", "G3", "B3"], duration: "1n" },
    ],
    melody: ["G3", "B3", "C4", "B3"],
  },
  "Acoustic Ballad": {
    chords: [
      { notes: ["G3", "C4", "E4"], duration: "2n" },
      { notes: ["A3", "D4", "F4"], duration: "2n" },
      { notes: ["B3", "E4", "G4"], duration: "2n" },
      { notes: ["C4", "F4", "A4"], duration: "2n" },
    ],
    melody: ["E4", "G4", "A4", "G4", "E4", "D4", "C4"],
  },
  "Jazz Worship": {
    chords: [
      { notes: ["C4", "Eb4", "G4", "Bb4"], duration: "2n" },
      { notes: ["F3", "A3", "C4", "E4"], duration: "2n" },
      { notes: ["D3", "F3", "A3", "C4"], duration: "2n" },
      { notes: ["G3", "Bb3", "D4", "F4"], duration: "2n" },
    ],
    melody: ["Eb4", "G4", "Bb4", "G4"],
  },
  "Rock Praise": {
    chords: [
      { notes: ["E3", "G#3", "B3"], duration: "2n" },
      { notes: ["A3", "C#4", "E4"], duration: "2n" },
      { notes: ["B3", "D#4", "F#4"], duration: "2n" },
      { notes: ["A3", "C#4", "E4"], duration: "2n" },
    ],
    melody: ["G#3", "B3", "C#4", "B3", "G#3"],
  },
  "Folk Hymn": {
    chords: [
      { notes: ["D3", "F3", "A3"], duration: "2n" },
      { notes: ["G3", "B3", "D4"], duration: "2n" },
      { notes: ["A3", "C4", "E4"], duration: "2n" },
      { notes: ["D3", "F3", "A3"], duration: "2n" },
    ],
    melody: ["F3", "A3", "B3", "A3", "F3"],
  },
  "Classical Hymn": {
    chords: [
      { notes: ["F3", "A3", "C4"], duration: "2n" },
      { notes: ["G3", "B3", "D4"], duration: "2n" },
      { notes: ["Am3", "C4", "E4"], duration: "2n" },
      { notes: ["Bb3", "D4", "F4"], duration: "2n" },
    ],
    melody: ["A3", "C4", "D4", "C4", "A3"],
  },
  "We Wish You a Merry Christmas": {
    chords: [
      { notes: ["C"], duration: "2n" },
      { notes: ["D"], duration: "2n" },
      { notes: ["Bm", "Em", "C", "D", "G"], duration: "2n" },
    ],
    melody: [],
  },
};

// Additional instrument patterns for each style
const styleInstrumentData = {
  "Contemporary Worship": {
    drums: "C2, D2",
    flute: "E5, G5, A5, G5",
    violin: "G4, A4, B4, A4",
    guitar: "C4, E4, G4",
    tabla: "D2, F2, D2, F2",
    sitar: "E3, G3, B3, G3",
  },
  "Traditional Hymnal": {
    drums: "D2, C2",
    flute: "F5, A5, G5, F5",
    violin: "F4, G4, A4, G4",
    guitar: "F4, A4, C5",
    tabla: "C2, Eb2, C2, Eb2",
    sitar: "D3, F3, A3, F3",
  },
  "Uplifting Gospel": {
    drums: "C2, D2",
    flute: "E5, G5, A5, G5",
    violin: "G4, A4, B4, A4",
    guitar: "C4, E4, G4",
    tabla: "D2, F2, D2, F2",
    sitar: "E3, G3, B3, G3",
  },
  "Reflective Praise": {
    drums: "E2, G2",
    flute: "A5, C6, B5, A5",
    violin: "A4, C5, E5, C5",
    guitar: "A3, C4, E4",
    tabla: "D2, F2, D2, F2",
    sitar: "E3, G3, B3, G3",
  },
  "Energetic Celebration": {
    drums: "G2, A2",
    flute: "B5, D6, C6, B5",
    violin: "G4, B4, D5, B4",
    guitar: "G3, B3, D4",
    tabla: "F2, A2, F2, A2",
    sitar: "C3, E3, G3, E3",
  },
  "Soulful R&B": {
    drums: "D2, F2",
    flute: "G5, Bb5, A5, G5",
    violin: "D4, F4, A4, F4",
    guitar: "D3, F3, A3",
    tabla: "C2, Eb2, C2, Eb2",
    sitar: "F3, A3, C4, A3",
  },
  "Ambient Spiritual": {
    drums: "C2, E2",
    flute: "C6, E6, G6, E6",
    violin: "C4, E4, G4, E4",
    guitar: "C4, E4, G4",
    tabla: "G2, Bb2, G2, Bb2",
    sitar: "A3, C4, E4, C4",
  },
  "Acoustic Ballad": {
    drums: "G2, C3",
    flute: "D5, F5, G5, F5",
    violin: "G3, B3, D4, B3",
    guitar: "G3, C4, E4",
    tabla: "E2, G2, E2, G2",
    sitar: "B3, D4, G4, D4",
  },
  "Jazz Worship": {
    drums: "F2, G2",
    flute: "C6, Bb5, A5, G5",
    violin: "C4, E4, G4, E4",
    guitar: "C4, E4, G4",
    tabla: "D2, F2, D2, F2",
    sitar: "E3, G3, B3, G3",
  },
  "Rock Praise": {
    drums: "G2, A2",
    flute: "D6, C6, B5, A5",
    violin: "E4, G#4, B4, G#4",
    guitar: "E4, G#4, B4",
    tabla: "D2, F2, D2, F2",
    sitar: "E3, G3, B3, G3",
  },
  "Folk Hymn": {
    drums: "D2, F2",
    flute: "A5, G5, F5, G5",
    violin: "D4, F4, A4, F4",
    guitar: "D3, F3, A3",
    tabla: "C2, Eb2, C2, Eb2",
    sitar: "D3, F3, A3, F3",
  },
  "Classical Hymn": {
    drums: "F2, G2",
    flute: "C6, D6, E6, D6",
    violin: "F4, A4, C5, A4",
    guitar: "F3, A3, C4",
    tabla: "G2, Bb2, G2, Bb2",
    sitar: "E3, G3, B3, G3",
  },
  "We Wish You a Merry Christmas": {
    drums: "G2, A2",
    flute: "C6, B5, A5, G5",
    violin: "Bm, Em, C, D, G",
    guitar: "C, D, Bm, Em, C, D, G",
    tabla: "D2, F2, D2, F2",
    sitar: "E3, G3, B3, G3",
  },
};

// Fallback for missing data
styles.forEach((style) => {
  if (!styleData[style]) {
    styleData[style] = {
      chords: [
        { notes: ["C4", "E4", "G4"], duration: "2n" },
        { notes: ["G3", "B3", "D4"], duration: "2n" },
      ],
      melody: ["E4", "G4", "C5"],
    };
  }
  if (!styleInstrumentData[style]) {
    styleInstrumentData[style] = {
      drums: "C2, D2",
      flute: "E5, G5, A5, G5",
      violin: "G4, A4, B4, A4",
      guitar: "C4, E4, G4",
      tabla: "D2, F2, D2, F2",
      sitar: "E3, G3, B3, G3",
    };
  }
});

// Default instrument oscillator types and enabled settings
const defaultInstrumentOsc = {
  drums: "sine",
  flute: "sine",
  violin: "sawtooth",
  guitar: "square",
  tabla: "triangle",
  sitar: "sine",
};

const defaultInstrumentEnabled = {
  drums: false,
  flute: false,
  violin: false,
  guitar: false,
  tabla: false,
  sitar: false,
};

// White keys for a basic keyboard display
const keys = [
  "C3", "D3", "E3", "F3", "G3", "A3", "B3",
  "C4", "D4", "E4", "F4", "G4", "A4", "B4",
];

const KeyboardDisplay = ({ activeNotes }) => (
  <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
    {keys.map((key) => (
      <div
        key={key}
        style={{
          width: "30px",
          height: "120px",
          margin: "2px",
          background: activeNotes.includes(key) ? "#f39c12" : "#333",
          border: "1px solid #555",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "center",
          fontSize: "10px",
          color: "#fff",
        }}
      >
        {key}
      </div>
    ))}
  </div>
);

const SingleStyleAllInstruments = () => {
  // Music & instrument states
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedStyle, setSelectedStyle] = useState("Contemporary Worship");
  const [customChords, setCustomChords] = useState("");
  const [customMelody, setCustomMelody] = useState("");
  const [tempo, setTempo] = useState(100);
  const [volume, setVolume] = useState(0);
  const [attack, setAttack] = useState(0.05);
  const [release, setRelease] = useState(1);
  const [useReverb, setUseReverb] = useState(true);
  const [reverbTime, setReverbTime] = useState(2);
  const [reverbWet, setReverbWet] = useState(0.3);
  const [melodyOsc, setMelodyOsc] = useState("sine");
  const [chordOsc, setChordOsc] = useState("triangle");
  const [instrumentEnabled, setInstrumentEnabled] = useState({ ...defaultInstrumentEnabled });
  const [instrumentPattern, setInstrumentPattern] = useState({});
  const [instrumentOsc, setInstrumentOsc] = useState({ ...defaultInstrumentOsc });
  const [activeMelodyNote, setActiveMelodyNote] = useState("");
  const [activeChordNotes, setActiveChordNotes] = useState([]);
  
  // New state for background music
  const [backgroundMusic, setBackgroundMusic] = useState("None");

  // Refs for Tone.js synths and events
  const chordEventIdRef = useRef(null);
  const melodyEventIdRef = useRef(null);
  const drumEventIdRef = useRef(null);
  const fluteEventIdRef = useRef(null);
  const violinEventIdRef = useRef(null);
  const guitarEventIdRef = useRef(null);
  const tablaEventIdRef = useRef(null);
  const sitarEventIdRef = useRef(null);
  const melodySynthRef = useRef(null);
  const chordSynthRef = useRef(null);
  const drumSynthRef = useRef(null);
  const fluteSynthRef = useRef(null);
  const violinSynthRef = useRef(null);
  const guitarSynthRef = useRef(null);
  const tablaSynthRef = useRef(null);
  const sitarSynthRef = useRef(null);
  const reverbRef = useRef(null);
  const masterVolRef = useRef(null);

  // Ref for background music audio element
  const bgMusicRef = useRef(null);

  // Music library URLs (borrowed from BibleReader)
  const musicURLs = {
    "Calm": "https://www.bensound.com/bensound-music/bensound-slowmotion.mp3",
    "Uplifting": "https://www.bensound.com/bensound-music/bensound-sunny.mp3",
    "Ambient": "https://www.bensound.com/bensound-music/bensound-relaxing.mp3",
    "Peaceful": "https://www.bensound.com/bensound-music/bensound-tenderness.mp3",
    "Serene": "https://www.bensound.com/bensound-music/bensound-slowmotion.mp3",
    "Chill": "https://www.bensound.com/bensound-music/bensound-slowmotion.mp3",
    "Focus": "https://www.bensound.com/bensound-music/bensound-dubstep.mp3",
    "Relax": "https://www.bensound.com/bensound-music/bensound-relaxing.mp3",
    "Meditative": "https://www.bensound.com/bensound-music/bensound-tenderness.mp3",
    "Dreamy": "https://www.bensound.com/bensound-music/bensound-dreams.mp3",
    "Acoustic": "https://www.bensound.com/bensound-music/bensound-acousticbreeze.mp3",
    "Jazz": "https://www.bensound.com/bensound-music/bensound-thejazzpiano.mp3",
    "Nature": "https://www.bensound.com/bensound-music/bensound-betterdays.mp3",
    "Piano": "https://www.bensound.com/bensound-music/bensound-sweet.mp3",
    "Lo-Fi": "https://www.bensound.com/bensound-music/bensound-slowmotion.mp3",
    "Electronic": "https://www.bensound.com/bensound-music/bensound-dubstep.mp3",
    "Upbeat": "https://www.bensound.com/bensound-music/bensound-ukulele.mp3",
    "Epic": "https://www.bensound.com/bensound-music/bensound-epic.mp3",
    "Cinematic": "https://www.bensound.com/bensound-music/bensound-cinematic.mp3",
    "Smooth": "https://www.bensound.com/bensound-music/bensound-smoothjazz.mp3",
    "Soft": "https://www.bensound.com/bensound-music/bensound-slowmotion.mp3"
  };

  // Load style data when selected style changes
  useEffect(() => {
    const mainData = styleData[selectedStyle];
    setCustomChords(mainData.chords.map((ch) => ch.notes.join(", ")).join("; "));
    setCustomMelody(mainData.melody.join(", "));
    const instData = styleInstrumentData[selectedStyle];
    setInstrumentPattern({
      drums: instData.drums,
      flute: instData.flute,
      violin: instData.violin,
      guitar: instData.guitar,
      tabla: instData.tabla,
      sitar: instData.sitar,
    });
  }, [selectedStyle]);

  useEffect(() => {
    if (isPlaying) scheduleEvents();
  }, [customChords, customMelody, instrumentPattern]);

  useEffect(() => {
    Tone.Transport.bpm.value = tempo;
  }, [tempo]);

  useEffect(() => {
    if (masterVolRef.current) {
      masterVolRef.current.volume.value = volume;
    }
  }, [volume]);

  const getMainData = () => {
    const data = styleData[selectedStyle];
    const chords = customChords.trim() !== ""
      ? customChords.split(";").map(str => ({
          notes: str.split(",").map(n => n.trim()),
          duration: "2n",
        }))
      : data.chords;
    const melody = customMelody.trim() !== ""
      ? customMelody.split(",").map(n => n.trim())
      : data.melody;
    return { chords, melody };
  };

  // Initialize instruments and audio effects
  const initInstruments = () => {
    [
      melodySynthRef,
      chordSynthRef,
      drumSynthRef,
      fluteSynthRef,
      violinSynthRef,
      guitarSynthRef,
      tablaSynthRef,
      sitarSynthRef,
    ].forEach(ref => {
      if (ref.current) {
        ref.current.dispose();
        ref.current = null;
      }
    });
    if (reverbRef.current) {
      reverbRef.current.dispose();
      reverbRef.current = null;
    }
    if (masterVolRef.current) {
      masterVolRef.current.dispose();
      masterVolRef.current = null;
    }

    masterVolRef.current = new Tone.Volume(volume).toDestination();
    if (useReverb) {
      reverbRef.current = new Tone.Reverb({ decay: reverbTime, wet: reverbWet })
        .connect(masterVolRef.current);
    }

    melodySynthRef.current = new Tone.Synth({
      oscillator: { type: melodyOsc },
      envelope: { attack, release },
    });
    chordSynthRef.current = new Tone.PolySynth(Tone.Synth, {
      oscillator: { type: chordOsc },
      envelope: { attack, release },
    });

    if (useReverb && reverbRef.current) {
      melodySynthRef.current.connect(reverbRef.current);
      chordSynthRef.current.connect(reverbRef.current);
    } else {
      melodySynthRef.current.connect(masterVolRef.current);
      chordSynthRef.current.connect(masterVolRef.current);
    }

    if (instrumentEnabled.drums) {
      drumSynthRef.current = new Tone.MembraneSynth({ oscillator: { type: instrumentOsc.drums } });
      (reverbRef.current ? drumSynthRef.current.connect(reverbRef.current) : drumSynthRef.current.connect(masterVolRef.current));
    }
    if (instrumentEnabled.flute) {
      fluteSynthRef.current = new Tone.Synth({ oscillator: { type: instrumentOsc.flute }, envelope: { attack: 0.2, release: 1.5 } });
      (reverbRef.current ? fluteSynthRef.current.connect(reverbRef.current) : fluteSynthRef.current.connect(masterVolRef.current));
    }
    if (instrumentEnabled.violin) {
      violinSynthRef.current = new Tone.Synth({ oscillator: { type: instrumentOsc.violin }, envelope: { attack: 0.1, release: 1 } });
      (reverbRef.current ? violinSynthRef.current.connect(reverbRef.current) : violinSynthRef.current.connect(masterVolRef.current));
    }
    if (instrumentEnabled.guitar) {
      guitarSynthRef.current = new Tone.Synth({ oscillator: { type: instrumentOsc.guitar }, envelope: { attack: 0.01, decay: 0.2, sustain: 0, release: 0.2 } });
      (reverbRef.current ? guitarSynthRef.current.connect(reverbRef.current) : guitarSynthRef.current.connect(masterVolRef.current));
    }
    if (instrumentEnabled.tabla) {
      tablaSynthRef.current = new Tone.Synth({ oscillator: { type: instrumentOsc.tabla }, envelope: { attack: 0.01, decay: 0.3, sustain: 0, release: 0.3 } });
      (reverbRef.current ? tablaSynthRef.current.connect(reverbRef.current) : tablaSynthRef.current.connect(masterVolRef.current));
    }
    if (instrumentEnabled.sitar) {
      sitarSynthRef.current = new Tone.Synth({ oscillator: { type: instrumentOsc.sitar }, envelope: { attack: 0.05, release: 1 } });
      (reverbRef.current ? sitarSynthRef.current.connect(reverbRef.current) : sitarSynthRef.current.connect(masterVolRef.current));
    }
  };

  // Schedule musical events
  const scheduleEvents = () => {
    Tone.Transport.clear(chordEventIdRef.current);
    Tone.Transport.clear(melodyEventIdRef.current);
    Tone.Transport.clear(drumEventIdRef.current);
    Tone.Transport.clear(fluteEventIdRef.current);
    Tone.Transport.clear(violinEventIdRef.current);
    Tone.Transport.clear(guitarEventIdRef.current);
    Tone.Transport.clear(tablaEventIdRef.current);
    Tone.Transport.clear(sitarEventIdRef.current);

    const { chords, melody } = getMainData();
    let chordIndex = 0;
    chordEventIdRef.current = Tone.Transport.scheduleRepeat((time) => {
      const c = chords[chordIndex % chords.length];
      chordSynthRef.current.triggerAttackRelease(c.notes, c.duration, time);
      setActiveChordNotes(c.notes);
      setTimeout(() => setActiveChordNotes([]), 500);
      chordIndex++;
    }, "2n");

    let melodyIndex = 0;
    melodyEventIdRef.current = Tone.Transport.scheduleRepeat((time) => {
      const note = melody[melodyIndex % melody.length];
      melodySynthRef.current.triggerAttackRelease(note, "8n", time);
      setActiveMelodyNote(note);
      setTimeout(() => setActiveMelodyNote(""), 300);
      melodyIndex++;
    }, "4n");

    if (instrumentEnabled.drums && drumSynthRef.current) {
      const arr = instrumentPattern.drums?.split(",").map(n => n.trim()) || [];
      let idx = 0;
      drumEventIdRef.current = Tone.Transport.scheduleRepeat((time) => {
        const note = arr[idx % arr.length];
        drumSynthRef.current.triggerAttackRelease(note, "8n", time);
        idx++;
      }, "4n");
    }
    if (instrumentEnabled.flute && fluteSynthRef.current) {
      const arr = instrumentPattern.flute?.split(",").map(n => n.trim()) || [];
      let idx = 0;
      fluteEventIdRef.current = Tone.Transport.scheduleRepeat((time) => {
        const note = arr[idx % arr.length];
        fluteSynthRef.current.triggerAttackRelease(note, "8n", time);
        idx++;
      }, "8n");
    }
    if (instrumentEnabled.violin && violinSynthRef.current) {
      const arr = instrumentPattern.violin?.split(",").map(n => n.trim()) || [];
      let idx = 0;
      violinEventIdRef.current = Tone.Transport.scheduleRepeat((time) => {
        const note = arr[idx % arr.length];
        violinSynthRef.current.triggerAttackRelease(note, "8n", time);
        idx++;
      }, "4n");
    }
    if (instrumentEnabled.guitar && guitarSynthRef.current) {
      const arr = instrumentPattern.guitar?.split(",").map(n => n.trim()) || [];
      let idx = 0;
      guitarEventIdRef.current = Tone.Transport.scheduleRepeat((time) => {
        const note = arr[idx % arr.length];
        guitarSynthRef.current.triggerAttackRelease(note, "8n", time);
        idx++;
      }, "4n");
    }
    if (instrumentEnabled.tabla && tablaSynthRef.current) {
      const arr = instrumentPattern.tabla?.split(",").map(n => n.trim()) || [];
      let idx = 0;
      tablaEventIdRef.current = Tone.Transport.scheduleRepeat((time) => {
        const note = arr[idx % arr.length];
        tablaSynthRef.current.triggerAttackRelease(note, "8n", time);
        idx++;
      }, "4n");
    }
    if (instrumentEnabled.sitar && sitarSynthRef.current) {
      const arr = instrumentPattern.sitar?.split(",").map(n => n.trim()) || [];
      let idx = 0;
      sitarEventIdRef.current = Tone.Transport.scheduleRepeat((time) => {
        const note = arr[idx % arr.length];
        sitarSynthRef.current.triggerAttackRelease(note, "8n", time);
        idx++;
      }, "4n");
    }
  };

  // Play background music if selected
  const playBackgroundMusic = () => {
    if (backgroundMusic === "None") return;
    const url = musicURLs[backgroundMusic];
    if (url) {
      const audio = new Audio(url);
      audio.loop = true;
      audio.volume = 0.3;
      bgMusicRef.current = audio;
      audio.play().catch(err => console.error("BG music play error:", err));
    }
  };

  // Stop background music if playing
  const stopBackgroundMusic = () => {
    if (bgMusicRef.current) {
      bgMusicRef.current.pause();
      bgMusicRef.current = null;
    }
  };

  // Start music – including background music if chosen
  const startMusic = async () => {
    await Tone.start();
    Tone.Transport.bpm.value = tempo;
    if (!isPlaying) {
      initInstruments();
      scheduleEvents();
      Tone.Transport.start();
      setIsPlaying(true);
      if (backgroundMusic !== "None") playBackgroundMusic();
    } else {
      scheduleEvents();
    }
  };

  // Stop all music and background audio
  const stopMusic = () => {
    if (isPlaying) {
      Tone.Transport.stop();
      Tone.Transport.cancel();
      if (melodySynthRef.current?.triggerRelease)
        melodySynthRef.current.triggerRelease();
      if (chordSynthRef.current?.releaseAll) chordSynthRef.current.releaseAll();
      if (drumSynthRef.current?.triggerRelease)
        drumSynthRef.current.triggerRelease();
      if (fluteSynthRef.current?.triggerRelease)
        fluteSynthRef.current.triggerRelease();
      if (violinSynthRef.current?.triggerRelease)
        violinSynthRef.current.triggerRelease();
      if (guitarSynthRef.current?.triggerRelease)
        guitarSynthRef.current.triggerRelease();
      if (tablaSynthRef.current?.triggerRelease)
        tablaSynthRef.current.triggerRelease();
      if (sitarSynthRef.current?.triggerRelease)
        sitarSynthRef.current.triggerRelease();

      [
        melodySynthRef,
        chordSynthRef,
        drumSynthRef,
        fluteSynthRef,
        violinSynthRef,
        guitarSynthRef,
        tablaSynthRef,
        sitarSynthRef,
      ].forEach(ref => {
        if (ref.current) {
          ref.current.dispose();
          ref.current = null;
        }
      });

      stopBackgroundMusic();

      setIsPlaying(false);
      setActiveMelodyNote("");
      setActiveChordNotes([]);
    }
  };

  // UI theme styles
  const pageStyle = {
    backgroundColor: "#000",
    color: "#fff",
    minHeight: "100vh",
    fontFamily: "Arial, sans-serif",
    padding: "1rem",
  };
  const sectionStyle = {
    marginBottom: "1rem",
    padding: "1rem",
    border: "1px solid #444",
    borderRadius: "4px",
  };
  const selectStyle = {
    padding: "0.5rem",
    backgroundColor: "#222",
    color: "#fff",
    border: "1px solid #555",
  };
  const textAreaStyle = {
    width: "90%",
    height: "80px",
    fontSize: "1rem",
    padding: "0.5rem",
    backgroundColor: "#222",
    color: "#fff",
    border: "1px solid #555",
  };

  return (
    <div style={pageStyle}>
      <h1 style={{ textAlign: "center" }}>Single‑Style for All Instruments</h1>

      {/* Style Selection */}
      <div style={{ ...sectionStyle }}>
        <label>Style: </label>
        <select
          value={selectedStyle}
          onChange={(e) => setSelectedStyle(e.target.value)}
          style={selectStyle}
        >
          {styles.map(s => (
            <option key={s} value={s}>{s}</option>
          ))}
        </select>
      </div>

      {/* Main Chords & Melody */}
      <div style={{ ...sectionStyle }}>
        <h3>Main Chords & Melody</h3>
        <div style={{ marginBottom: "1rem" }}>
          <label>Custom Chords: </label>
          <br />
          <textarea
            value={customChords}
            onChange={(e) => setCustomChords(e.target.value)}
            style={textAreaStyle}
            placeholder='e.g. "C4,E4,G4; G3,B3,D4; ..."'
          />
        </div>
        <div>
          <label>Custom Melody: </label>
          <br />
          <textarea
            value={customMelody}
            onChange={(e) => setCustomMelody(e.target.value)}
            style={textAreaStyle}
            placeholder='e.g. "E4, G4, A4, G4, E4, D4, C4"'
          />
        </div>
      </div>

      {/* Main Instrument Oscillators */}
      <div style={{ ...sectionStyle, display: "flex", justifyContent: "space-around" }}>
        <div>
          <label>Melody Osc: </label>
          <select
            value={melodyOsc}
            onChange={(e) => setMelodyOsc(e.target.value)}
            style={selectStyle}
          >
            <option value="sine">Sine</option>
            <option value="square">Square</option>
            <option value="triangle">Triangle</option>
            <option value="sawtooth">Sawtooth</option>
          </select>
        </div>
        <div>
          <label>Chord Osc: </label>
          <select
            value={chordOsc}
            onChange={(e) => setChordOsc(e.target.value)}
            style={selectStyle}
          >
            <option value="sine">Sine</option>
            <option value="square">Square</option>
            <option value="triangle">Triangle</option>
            <option value="sawtooth">Sawtooth</option>
          </select>
        </div>
      </div>

      {/* Tempo, Volume, Envelope */}
      <div style={{ ...sectionStyle, display: "flex", justifyContent: "space-around" }}>
        <div>
          <label>Tempo: {tempo} BPM</label>
          <br />
          <input
            type="range"
            min="60"
            max="180"
            value={tempo}
            onChange={(e) => setTempo(parseInt(e.target.value, 10))}
            style={{ width: "120px" }}
          />
        </div>
        <div>
          <label>Volume: {volume} dB</label>
          <br />
          <input
            type="range"
            min="-60"
            max="0"
            value={volume}
            onChange={(e) => setVolume(parseInt(e.target.value, 10))}
            style={{ width: "120px" }}
          />
        </div>
        <div>
          <label>Attack: {attack.toFixed(2)}</label>
          <br />
          <input
            type="range"
            min="0.01"
            max="1"
            step="0.01"
            value={attack}
            onChange={(e) => setAttack(parseFloat(e.target.value))}
            style={{ width: "120px" }}
          />
        </div>
        <div>
          <label>Release: {release.toFixed(2)}</label>
          <br />
          <input
            type="range"
            min="0.5"
            max="3"
            step="0.1"
            value={release}
            onChange={(e) => setRelease(parseFloat(e.target.value))}
            style={{ width: "120px" }}
          />
        </div>
      </div>

      {/* Reverb Controls */}
      <div style={{ ...sectionStyle }}>
        <label>
          <input
            type="checkbox"
            checked={useReverb}
            onChange={(e) => setUseReverb(e.target.checked)}
            style={{ marginRight: "0.5rem" }}
          />
          Use Reverb
        </label>
        {useReverb && (
          <div style={{ display: "flex", justifyContent: "center", marginTop: "0.5rem" }}>
            <div style={{ margin: "0 1rem" }}>
              <label>Reverb Time: {reverbTime.toFixed(1)} s</label>
              <br />
              <input
                type="range"
                min="0.5"
                max="5"
                step="0.1"
                value={reverbTime}
                onChange={(e) => setReverbTime(parseFloat(e.target.value))}
                style={{ width: "120px" }}
              />
            </div>
            <div style={{ margin: "0 1rem" }}>
              <label>Reverb Wet: {(reverbWet * 100).toFixed(0)}%</label>
              <br />
              <input
                type="range"
                min="0"
                max="1"
                step="0.05"
                value={reverbWet}
                onChange={(e) => setReverbWet(parseFloat(e.target.value))}
                style={{ width: "120px" }}
              />
            </div>
          </div>
        )}
      </div>

      {/* Additional Instruments */}
      <div style={sectionStyle}>
        <h3>Additional Instruments</h3>
        {/* Drums */}
        <label>
          <input
            type="checkbox"
            checked={instrumentEnabled.drums}
            onChange={(e) =>
              setInstrumentEnabled({ ...instrumentEnabled, drums: e.target.checked })
            }
          /> Drums
        </label>
        {instrumentEnabled.drums && (
          <div style={{ marginLeft: "2rem", marginTop: "0.5rem" }}>
            <label>Pattern: </label>
            <input
              type="text"
              value={instrumentPattern.drums || ""}
              onChange={(e) =>
                setInstrumentPattern({ ...instrumentPattern, drums: e.target.value })
              }
              style={{ width: "200px", backgroundColor: "#222", color: "#fff", border: "1px solid #555" }}
            />
            <br />
            <label>Osc: </label>
            <select
              value={instrumentOsc.drums}
              onChange={(e) =>
                setInstrumentOsc({ ...instrumentOsc, drums: e.target.value })
              }
              style={{ backgroundColor: "#222", color: "#fff", border: "1px solid #555" }}
            >
              <option value="sine">Sine</option>
              <option value="square">Square</option>
              <option value="triangle">Triangle</option>
              <option value="sawtooth">Sawtooth</option>
            </select>
          </div>
        )}

        {/* (Repeat similar blocks for Flute, Violin, Guitar, Tabla, Sitar as needed) */}
      </div>

      {/* Background Music Selector */}
      <div style={{ ...sectionStyle }}>
        <label>Background Music: </label>
        <select
          value={backgroundMusic}
          onChange={(e) => setBackgroundMusic(e.target.value)}
          style={selectStyle}
        >
          <option value="None">None</option>
          <option value="Calm">Calm</option>
          <option value="Uplifting">Uplifting</option>
          <option value="Ambient">Ambient</option>
          <option value="Peaceful">Peaceful</option>
          <option value="Serene">Serene</option>
          <option value="Chill">Chill</option>
          <option value="Focus">Focus</option>
          <option value="Relax">Relax</option>
          <option value="Meditative">Meditative</option>
          <option value="Dreamy">Dreamy</option>
          <option value="Acoustic">Acoustic</option>
          <option value="Jazz">Jazz</option>
          <option value="Nature">Nature</option>
          <option value="Piano">Piano</option>
          <option value="Lo-Fi">Lo-Fi</option>
          <option value="Electronic">Electronic</option>
          <option value="Upbeat">Upbeat</option>
          <option value="Epic">Epic</option>
          <option value="Cinematic">Cinematic</option>
          <option value="Smooth">Smooth</option>
          <option value="Soft">Soft</option>
        </select>
      </div>

      {/* Play / Stop Button */}
      <div style={{ ...sectionStyle, textAlign: "center" }}>
        <button
          onClick={isPlaying ? stopMusic : startMusic}
          style={{
            padding: "1rem 2rem",
            fontSize: "1.2rem",
            borderRadius: "8px",
            border: "none",
            cursor: "pointer",
            backgroundColor: isPlaying ? "#c0392b" : "#27ae60",
            color: "#fff",
          }}
        >
          {isPlaying ? "Stop Music" : "Play Music"}
        </button>
      </div>

      {/* Keyboard Visual */}
      <KeyboardDisplay activeNotes={[...activeChordNotes, activeMelodyNote].filter(Boolean)} />
    </div>
  );
};

export default SingleStyleAllInstruments;
