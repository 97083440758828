// src/components/Counseling.jsx
import React from "react";
import { FaHandsHelping, FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Counseling.css";
import SEO from './SEO';


function Counseling() {
  const counselingTypes = [
    {
      title: "Individual Counseling",
      description: "One-on-one sessions focused on personal growth, overcoming challenges, and spiritual guidance.",
    },
    {
      title: "Couples Counseling",
      description: "Support for couples to strengthen their relationships, improve communication, and resolve conflicts.",
    },
    {
      title: "Family Counseling",
      description: "Sessions aimed at improving family dynamics, fostering understanding, and building a supportive home environment.",
    },
    {
      title: "Group Counseling",
      description: "Facilitated group sessions that provide peer support, shared experiences, and collective growth.",
    },
  ];

  return (
    <div className="counseling-container">
      
      {/* Header Section */}
      <section className="counseling-header">
        <h1 className="counseling-heading">
          <FaHandsHelping className="counseling-icon" /> Counseling
        </h1>
        <p className="counseling-intro">
          We offer a range of counseling services to support your spiritual and personal well-being. Connect with our experienced counselors today.
        </p>
      </section>
      
      {/* Counseling Types */}
      <section className="counseling-types">
        <h2>Our Counseling Services</h2>
        <div className="counseling-grid">
          {counselingTypes.map((type, index) => (
            <div key={index} className="counseling-card">
              <h3 className="counseling-title">{type.title}</h3>
              <p className="counseling-description">{type.description}</p>
            </div>
          ))}
        </div>
      </section>
      
      {/* How We Can Help */}
      <section className="counseling-how-we-help">
        <h2>How We Can Help You</h2>
        <p>
          Our counselors are dedicated to providing compassionate support and guidance to help you navigate life's challenges. Whether you're seeking personal growth, relationship improvement, or spiritual guidance, we're here to assist you every step of the way.
        </p>
        <button className="connect-button" onClick={() => window.open('https://forms.gle/your-google-form-link', '_blank')}>
          Connect with a Counselor
        </button>
      </section>
      
      {/* Navigation Link Back */}
      <section className="navigation-link-back">
        <Link to="/" className="cta-button" aria-label="Back to Home">
          <FaArrowLeft className="back-icon" /> Back to Home
        </Link>
      </section>
      
    </div>
  );
}

export default Counseling;
