// src/App.jsx
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";

// Import Components
import HamburgerMenu from "./HamburgerMenu";
import Home from "./Home";
import Worship from "./Worship";
import WorshipRecord from "./WorshipRecord";

import Worship2 from "./components/Worship2";

import BibleReader from "./BibleReader";
import BibleReader2 from "./components/BibleReader2";
import Media from "./Media";
import Podcasts from "./components/Podcasts";
import Blog from "./components/Blog";
import Marketplace from "./components/Marketplace";
import Courses from "./components/Courses";
import BibleStudyTools from "./components/BibleStudyTools";
import Karaoke from "./components/Karaoke";
import Events from "./components/Events";
import SocialMedia from "./components/SocialMedia";

import LiveStreaming from "./components/LiveStreaming";
import SmallGroups from "./components/SmallGroups";
import ResourceLibrary from "./components/ResourceLibrary";
import Gamification from "./components/Gamification";
import Counseling from "./components/Counseling";
import Mentorship from "./components/Mentorship";
import Volunteer from "./components/Volunteer";
import Forums from "./components/Forums";
import PrayerWall from "./components/PrayerWall";
import Profile from "./components/Profile";
import Community from "./components/Community";
import Help from "./components/Help";
import ChristianMusicPlayer from "./components/Music";
import DJMusicMixer from "./components/DJMusicMixer";
import UnderDevelopment from "./components/UnderDevelopment";

import YouTubeVocalRemover from "./components/VocalRemover";

import ChatBubble from './components/ChatBubble';

// Import CSS
import "./App.css";

function Header() {
  const location = useLocation();
  const [isTextVisible, setIsTextVisible] = useState(true);

  return (
    <header className={`header ${location.pathname === "/" ? "home" : "other"}`}>
      <div className="logo">
        <span className={isTextVisible ? "" : "hidden-text"}>Global Christian Community</span>
      </div>
    </header>
  );
}

function App() {
  return (
    <Router>
      {/* Hamburger Menu */}
      <HamburgerMenu />
      
      {/* Dynamic Header */}
      <Header />
      
      {/* The route definitions */}
      <Routes>
           {/* <Route path="/worship" element={<Worship />} /> 
        
        <Route path="/worship2" element={<Worship2 />} />
        */}

        <Route path="/bible" element={<BibleReader />} />
        <Route path="/bible2" element={<BibleReader2 />} />
        <Route path="/media" element={<Media />} />

        <Route path="/karaoke" element={<Karaoke />} />
        <Route path="/events" element={<Events />} />
        <Route path="/courses" element={<Courses />} />

        <Route path="/socialmedia" element={<SocialMedia />} />

        <Route path="/podcasts" element={<Podcasts />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/marketplace" element={<Marketplace />} />
        <Route path="/biblestudy" element={<BibleStudyTools />} />

        <Route path="/livestreaming" element={<LiveStreaming />} />
        <Route path="/smallgroups" element={<SmallGroups />} />
        <Route path="/resources" element={<ResourceLibrary />} />
        <Route path="/gamification" element={<Gamification />} />
        <Route path="/counseling" element={<Counseling />} />
        <Route path="/mentorship" element={<Mentorship />} />
        <Route path="/volunteer" element={<Volunteer />} />
        <Route path="/forums" element={<Forums />} />
        <Route path="/prayerwall" element={<PrayerWall />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/community" element={<Community />} />
        <Route path="/music" element={<ChristianMusicPlayer />} />
        <Route path="/djmusic" element={<DJMusicMixer />} />
        <Route path="/vocal" element={<YouTubeVocalRemover />} />

        <Route path="/help" element={<Help />} />
        {/* Placeholder for pages under development */}
        <Route path="/:pageName" element={<UnderDevelopment />} />
        <Route path="/record" element={<WorshipRecord />} />


        
        <Route path="/" element={<Home />} />
        <Route path="*" element={<div className="not-found">Page Under Development, Please Visit Later</div>} />
      </Routes>
      {/* <ChatBubble /> ChatBubble appears on every page */}
    </Router>
  );
}

export default App;
