// src/components/Blog.jsx
import React from "react";
import "./Blog.css";
import { FaBlog } from "react-icons/fa";
import SEO from './SEO';


export default function Blog() {
  return (
    <div className="blog-container">
      {/* Header Section */}
      <section className="blog-header">
        <h1 className="blog-heading">
          <FaBlog className="blog-icon" /> Blog
        </h1>
        <p className="blog-intro">
          Dive into our latest thoughts, insights, and stories. Stay connected with our community through our blog.
        </p>
      </section>

      {/* Blog Embed */}
      <div className="blog-embed">
        <iframe
          title="GCC Blog"
          src="https://gcc-blogs.blogspot.com/?view=classic"
          className="blog-iframe"
          frameBorder="0"
          allowFullScreen
          aria-label="Embedded Google Blog"
        ></iframe>
      </div>
    </div>
  );
}
